import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-index-balances',
  template: `
    <div class="row">
      <div class="col-sm-11 offset-sm-1 col-md-11 offset-md-1 col-lg-8 offset-lg-4 col-xl-8 offset-xl-3 mt-5">
        <div class="row mb-2">
          <div class="col-md-5">
            <h1>Saldos</h1>
            <p>Consulta los Saldos.</p>
          </div>
          <xng-breadcrumb ></xng-breadcrumb>
        </div>
        
        
          <div class="row mt-5">
            <div class="col-md-3">
              <!-- h2 class="mb-0">Habitacionales</!-->
              <select class="form-select form-select-lg rounded-5 bg-transparent border border-2" (change)="selectSettlement($event)">
                <option selected [selected]="true">Condominio</option>
                <option *ngFor="let settlement of settlements" [value]="settlement.id" >{{settlement.name}}</option>
              </select>
            </div>
            <div class="col-md-2">
              <select class="form-select form-select-lg rounded-5 bg-transparent border border-2" (change)="selectBuilding($event)">
                <option selected [selected]="true" >Edificio</option>
                <option *ngFor="let building of buildings" [value]="building.id" >{{building.name}}</option>
              </select>
            </div>
            <div class="col-md-2">
              <select class="form-select form-select-lg rounded-5 bg-transparent border border-2" (change)="selectFloor($event)">
                <option selected [selected]="true" >Piso</option>
                <option *ngFor="let floor of floors" [value]="floor.id" >{{floor.name}}</option>
              </select>
            </div>
            <div class="col-md-2">
              <select class="form-select form-select-lg rounded-5 bg-transparent border border-2" (change)="selectUnit($event)">
                <option selected [selected]="true" >Depto.</option>
                <option *ngFor="let unit of units" [value]="unit.id" >{{unit.name}}</option>
              </select>
            </div>
            <!-- div class="col-md-3" *ngIf="!searchActive">
              <form [formGroup]="searchBox" (ngSubmit)="searchBalances($event)">
                <input class="form-control form-control-lg" placeholder="Buscar..." formControlName="search">
              </form>
            </!-->
            <div class="col-md-3 text-end" *ngIf="searchActive">
              <button class="btn btn-link text-danger mt-2" (click)="clearFilter()">
                <fa-icon [icon]="icons.faTimes" size="lg" [spin]="loading" class="me-3"></fa-icon>
                Limpiar filtros
              </button>
            </div>
          </div>
          

        <div class="row">
        <div class="col-md-12 mt-5 pb-3">
          <div class="list-group rounded-4 shadow" *ngIf="balances.length > 0">
            <div class="list-group-item bg-white px-3 pt-3" *ngFor="let balance of balances | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
              <app-item-balance [balance]="balance"></app-item-balance>
            </div>
          </div>
          <div class="list-group rounded-4 shadow text-center" *ngIf="balances.length==0">
            <div *ngIf="loading" class="list-group-item bg-white py-5">
              <fa-icon [icon]="icons.faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
              <p class="text-muted">Cargando...</p>
            </div>
            <div class="list-group-item text-center py-5" *ngIf="!loading">
            <img src="/assets/images/empty-state.png" width="240px">
              <p class="text-muted">No se encontraron registros</p>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-end mt-3 mb-5" *ngIf="meta.total_pages > 1">
        <pagination-controls
            (pageChange)="p = $event"
            (pageChange)="pageChanged($event)"
            previousLabel="Prev."
            nextLabel="Sig."
            screenReaderPaginationLabel="Pagination"
            screenReaderPageLabel="page"                  
          ></pagination-controls>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class IndexBalancesComponent implements OnInit {
  loading: boolean = false;
  balances: any[] = [];
  meta: any = {};
  profile: any = {};
  permissions: any = {};
  searchBox: FormGroup = new FormGroup({});
  payload: any = {
    page: 1,
    per_page: 30,
    tipo: 0
  };

  searchActive: boolean = false;
  p: number = 1;

  settlements: any = [];
  buildings: any = [];
  floors: any = [];
  units: any = [];

  constructor(
    private store: SettlementsService,
    private route: Router,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    public icons: IconsService
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.utility_id = this.profile ? this.profile.utility_id : 0;
  }

  ngOnInit(): void {
    this.loading = true;
    this.loadBalances(this.payload);
    this.loadSettlements();
  }

  initForm() {
    this.searchBox = this.fb.group({
      search: ['']
    });
  }

  loadBalances(payload: any) {
    this.store.getBalances(payload).subscribe({
      next: (balances: any) => {
        this.balances = balances.data;
        this.meta = balances.meta;
        this.loading = false; // .set(false);
      },
      error: (err: any) => {
        console.log(err);
        this.loading = false; // .set(false);
      }
    })
  }

  searchBalances(event: any) {
    this.payload.page = 1;
    this.payload.name = this.searchBox.value.search;
    this.loadBalances(this.payload);
  }

  checkRouteUrl() {}

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadBalances(this.payload);
  }

  loadSettlements() {
    let settlementPayload: any = {
      page: 1,
      per_page: 300,
      utility_id: this.profile.utility_id
    };
    this.store.getSettlements(settlementPayload).subscribe((data: any) => {
      this.settlements = data.data;
    });
  }

  selectSettlement(event: any) {
    let buildingPayload: any = {
      page: 1,
      per_page: 50,
      settlement_id: event.target.value
    };
    
    this.store.getBuildings(buildingPayload).subscribe((data: any) => {
      this.buildings = data.data;
      this.searchActive = true;
    });

    this.store.getBalancesBySettlement(event.target.value, { status: false, state: 0 }).subscribe((data: any) => {
      console.log("WHATEVER => ", data);
      this.balances = data; // .filter((el: any) => el.status == false);
    });
  }

  selectBuilding(event: any) {
    let unitPayload: any = {
      page: 1,
      per_page: 50,
      building_id: event.target.value
    };
    this.store.getFloors(unitPayload).subscribe((data: any) => {
      this.floors = data.data;
    });

    this.store.getBalancesByBuilding(event.target.value, {  }).subscribe((data: any) => {
      console.log("WHATEVER => ", data);
      this.balances = data.filter((el: any) => el.status == false);
    });
  }

  selectFloor(event: any) {
    let unitPayload: any = {
      page: 1,
      per_page: 50,
      floor_id: event.target.value
    };
    this.store.getUnits(unitPayload).subscribe((data: any) => {
      this.units = data.data;
      console.log("UNITS => ", data);
    });

    this.store.getBalancesByFloor(event.target.value, { }).subscribe((data: any) => {
      console.log("WHATEVER => ", data);
      this.balances = data.filter((el: any) => el.status == false);
    });
  }

  selectUnit(event: any) {
    this.store.getBalancesByUnit(event.target.value, { }).subscribe((data: any) => {
      this.balances = data;
    });
  }

  clearFilter() {
    this.loadBalances(this.payload);
    this.settlements = [];
    this.loadSettlements();
    this.units = [];
    this.floors = [];
    this.buildings = [];
    this.searchActive = false;
  }

}
