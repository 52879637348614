import { Component, OnInit } from '@angular/core';
import { faEdit, faPlus, faTrashAlt, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { SettlementsService } from 'src/app/services/settlements.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-index-zone',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
        <div class="row mb-4">
          <div class="col-md-5">
            <h1>Zonas</h1>
            <p>Configura las zonas.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
          <button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" routerLink="create">
              <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
              Agregar zona
            </button>
          </div>
        </div>
        <div class="card border-0 rounded-4 shadow mb-3">
          <div class="card-body py-4 px-5">
            <div class="row">
              <div class="col-md-7">
                <!-- h2 class="mb-0">Habitacionales</!-->
                
              </div>
              <div class="col-md-12 mt-5">
                <div class="list-group rounded-4 shadow">
                  <div class="list-group-item bg-light" *ngFor="let zone of zonas | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries}">
                    <div class="row">
                      <div class="col-md-8">
                        <h5 class="mb-0">{{zone.name}}</h5>
                        <p class="mb-0">{{zone.description}}</p>
                      </div>
                      <div class="col-md-4 text-end">
                        <button type="button" class="btn btn-outline-primary" routerLink="{{zone.id}}">
                        <fa-icon [icon]="faEdit" size="1x"></fa-icon>
                          Editar
                        </button>
                      </div>                      
                    </div>
                  </div>
                </div>
                <div class="list-group rounded-4 shadow" *ngIf="zonas.length==0">
                  <div class="list-group-item text-center py-5" *ngIf="loading">
                    <fa-icon [icon]="faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                    <p class="text-muted">Cargando...</p>
                  </div>
                  <div class="list-group-item text-center py-5" *ngIf="!loading">
                    <img src="/assets/images/empty-state.png" width="240px">
                    <h5 class="text-muted">No se encontraron registros</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class IndexZoneComponent implements OnInit {

  faPlus = faPlus;
  faEdit = faEdit;
  faCircleNotch = faCircleNotch;

  payload: any = {
    page: 1,
    per_page: 10,
    role_id: 3
  };

  p: number = 1;

  loading: boolean = false;
  zonas: any[] = [];
  meta: any = {};
  searchBox: FormGroup = new FormGroup({});

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {    
    this.loadUsers(this.payload);
    this.initForm(); 
  }

  loadUsers(payload: any) {
    this.loading = true;
    this.store.getZones(payload).subscribe({
      next: (zonas: any) => {
        console.log(zonas);
        this.zonas = zonas.data;
        this.meta = zonas.meta;
        this.loading = false;
      },
      error: (err: any) => {
        console.log(err);
        this.zonas = [];
        this.loading = false;
      }
    });
  }

  initForm() {
    this.searchBox = this.fb.group({
      search: ['']
    })
  }
}
