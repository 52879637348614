import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-amenity',
  template: `
  <div class="row">
    <div class="col-sm-12 offset-sm-0 col-lg-6 offset-lg-3 col-xl-6 offset-xl-3 mt-4">
    <xng-breadcrumb></xng-breadcrumb>
      <div class="row mb-4 mt-4">
        <div class="col-md-8">
          <h1>Amenidad</h1>
          <p>Configura las amenidades que están disponibles para los departamentos.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-7">
          <div class="card border-0 rounded-4 shadow">
            <div class="card-body p-4">
              <div class="row">
                <div class="col-md-12">
                  <h4>Editar amenidad</h4>
                  <p>Configura la amenidad con los siguientes campos.</p>
                  <app-form-amenity (amenityData)="updateAmenity($event)" [amenity]="amenity" [loading]="loading"></app-form-amenity>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  `,
  styleUrls: []
})
export class AmenityComponent implements OnInit {
  amenity: any = null;
  loading: boolean = false;

  constructor(
    private store: SettlementsService,
      private toastr: ToastrService,
      private router: Router,
      private activateRoute: ActivatedRoute,
      private breadcrumbService: BreadcrumbService,
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.set('@amenidad', 'Amenidades');
    this.loading = true;
    this.activateRoute.params.subscribe(params => {
      this.store.getAmenity(params['id']).subscribe({
        next: (res: any) => {
          this.loading = false;
          this.amenity = res;
          this.breadcrumbService.set('@editarAmenidad', this.amenity.name);
        },
        error: (err: any) => {
          console.log(err);
          this.loading = false;
          this.toastr.error('Ocurrió un error al obtener la amenidad.', 'Error');
        }
      });
    });
  }

  updateAmenity(event: any) {
    console.log("updateAmenity ", event);

    this.store.updateAmenity(this.amenity.id, event).subscribe({
      next: (res: any) => {
        this.toastr.success('La amenidad se actualizó correctamente.', 'Actualizado');
        this.router.navigate(['/amenities']);
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al actualizar la amenidad.', 'Error');
      }
    });
  }


}
