import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-profile-edit',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 col-xl-8 offset-xl-3 mt-4">
      <xng-breadcrumb></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-8">
            <h1>Perfil</h1>
            <p>Configura el pefil.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h4>Editar perfil</h4>
                    <p>Configura la perfil con los siguientes campos.</p>
                    <app-form-profile (profileData)="updateProfile($event)" [profile]="profile" [loading]="loading"></app-form-profile>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-header pt-4 ps-3">
                <ul class="nav nav-tabs" #tabs>
                  <li class="nav-item">
                    <a #menu1 class="nav-link active" data-toggle="tab" href="#registro" (click)="tab($event)" >Registro público</a>
                  </li>
                  <li class="nav-item" *ngIf="public_record !== null">
                    <a class="nav-link" data-toggle="tab" href="#representante" (click)="tab($event)">Representante legal</a>
                  </li>
                </ul>                
              </div>
              <div class="card-body p-4">
              <div #tabContent class="tab-content">                
                <div id="registro" class="tab-pane registro active ps-3 pe-3" *ngIf="public_record">
                  <h5 class="card-title">Registro público</h5>
                  <p>Configura el registro público con los siguientes campos.</p>
                  <app-form-public-record [publicRecord]="public_record" (publicRecordData)="createPublicRecord($event)"></app-form-public-record>
                </div>
                <div id="representante" class="tab-pane representante ps-3 pe-3" *ngIf="public_record">
                  <h5 class="card-title">Representante legal</h5>
                  <app-form-public-record [publicRecord]="legal_rep" [isLegal]="true" [related]="public_record.id" (publicRecordData)="createLegalRep($event)"></app-form-public-record>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ProfileEditComponent implements OnInit {
  @ViewChild('tabs') tabs!: ElementRef | undefined;
  @ViewChild('tabContent') tabContent!: ElementRef | undefined;
  
  profile: any = null;
  public_record: any = null;
  legal_rep: any = null;
  loading: boolean = false;

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.set('@profiles', 'Perfiles');
    this.loading = true;
    this.activateRoute.params.subscribe(params => {
      this.store.getProfile(params['id']).subscribe({
        next: (res: any) => {
          this.loading = false;
          this.profile = res;
          if (this.profile.public_record_id) {
            this.store.getPublicRecord(this.profile.public_record_id).subscribe((data:any) => {
              this.public_record = data;
            });
          }
          this.breadcrumbService.set('@profileEdit', this.profile.name);
        },
        error: (err: any) => {
          console.log(err);
          this.loading = false;
          this.toastr.error('Ocurrió un error al obtener el perfil.', 'Error');
        }
      });
    });
  }

  tab(e: any) {
    e.preventDefault();
    //
    this.tabs?.nativeElement.querySelectorAll('.nav-link').forEach((tab: any) => {
      tab.classList.remove('active');
    });
    this.tabContent?.nativeElement.querySelectorAll('.tab-pane').forEach((tabPane: any) => {
      tabPane.classList.remove('active');
    });
    e.target.classList.add('active');
    this.tabContent?.nativeElement.querySelector(`.${e.target.href.split('#')[1]}`)?.classList.add('active');
  }

  updateProfile(event: any) {
    console.log("update ", event);

    this.store.updateProfile(this.profile.id, event).subscribe({
      next: (res: any) => {
        this.toastr.success('La perfil se actualizó correctamente.', 'Actualizado');
        this.router.navigate(['/perfiles']);
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al actualizar el perfil.', 'Error');
      }
    });
  }

  createLegalRep(event: any) {
    const payload: any = event;
    payload.profile_id = this.profile.id;

    if (event.id) {
      this.store.updatePublicRecord(event.id, event).subscribe({
        next: (res: any) => {
          console.log('updatePublicRecord res', res);
          this.toastr.success('El registro público se actualizó correctamente.', 'Actualizado');
          // this.store.updateProfile(this.profile.id, { public_record_id: res.id }).subscribe((data: any) => {
          // this.router.navigate(['/perfiles']);
          // });
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error('Ocurrió un error al actualizar el registro público.', 'Error');
        }
      });
    } else {
      this.store.createPublicRecord(event).subscribe({
        next: (res: any) => {
          console.log('createPublicRecord res', res);
          this.toastr.success('El registro público se creó correctamente.', 'Creado');
          // this.store.updateProfile(this.profile.id, { public_record_id: res.id }).subscribe((data: any) => {
            // this.router.navigate(['/perfiles']);
          // });
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error('Ocurrió un error al crear el registro público.', 'Error');
        }
      });
    }
  }

  createPublicRecord(event: any) {
    console.log("create ", event);

    const payload: any = event;
    payload.profile_id = this.profile.id;

    if (event.id) {
      this.store.updatePublicRecord(event.id, event).subscribe({
        next: (res: any) => {
          console.log('updatePublicRecord res', res);
          this.toastr.success('El registro público se actualizó correctamente.', 'Actualizado');
          this.store.updateProfile(this.profile.id, { public_record_id: res.id }).subscribe((data: any) => {
          // this.router.navigate(['/perfiles']);
          });
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error('Ocurrió un error al actualizar el registro público.', 'Error');
        }
      });
    } else {
    this.store.createPublicRecord(event).subscribe({
      next: (res: any) => {
        console.log('createPublicRecord res', res);
        this.toastr.success('El registro público se creó correctamente.', 'Creado');
        this.store.updateProfile(this.profile.id, { public_record_id: res.id }).subscribe((data: any) => {
          // this.router.navigate(['/perfiles']);
        });
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al crear el registro público.', 'Error');
      }
    });
  }
  }
}
