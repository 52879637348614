import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-provider-create',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
      <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-9">
            <h1>Proveedor</h1>
            <!-- p>Configura las propiedades de la unidad y los inquilinos asociados.</!-->
          </div>
          <div class="col-md-3 text-end mt-4">
            
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h4>Alta de proveedor</h4>
                    <p>Configura el proveedor con los siguientes campos.</p>
                    <app-form-providers (providerData)="createProvider($event)"></app-form-providers>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ProviderCreateComponent {

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  createProvider(event: any) {

    console.log(event);
    // return;
    this.store.createProvider(event).subscribe({
      next: (data: any) => {
        console.log(data);
        this.toastr.success('Servicio creado correctamente.');
        this.router.navigate(['/providers']);
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al crear el servicio.');
      }
    });
  }

}
