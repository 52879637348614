import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbDatepickerModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-modal-options-pay',
  template: `
    <div #modalOptionsPay id="modalOptionsPay" class="modal fade" tabindex="-1" aria-labelledby="modalOptionsPay" aria-hidden="true">
      <div class="modal-dialog modal-dialog-top rounded-5">        
        <div class="modal-content">
        <div class="modal-header border-0">
          <h5 class="modal-title" id="modalOptionsPay">Opciones de pago</h5>
          <fa-icon [icon]="icons.faTimes" class="text-danger" (click)="activeModal.dismissAll('SAVE')" ></fa-icon>
          <!-- data-bs-dismiss="modal" aria-label="Close" -->
        </div>
        <form [formGroup]="payForm">
          <div class="row p-4">
            <div class="col-md-12 text-center">

              <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                <!-- input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked (click)="selectPayMethod('CASH')">
                <label class="btn btn-outline-primary pt-3" for="btnradio1">
                  <fa-icon [icon]="icons.faCashRegister"></fa-icon><br>
                  <p>Pago en efectivo</p>
                </label -->

                <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" checked (click)="selectPayMethod('STP')">
                <label class="btn btn-outline-primary pt-3" for="btnradio2">
                  <fa-icon [icon]="icons.faCashRegister"></fa-icon><br>
                  <p>Pago vía STP</p>
                </label>

                <!-- input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" routerLink="/tenant/bill/{{bill.id}}">
                <label class="btn btn-outline-primary pt-3" for="btnradio3">
                  <fa-icon [icon]="icons.faCreditCard"></fa-icon><br>
                  <p>Tarjeta de crédito</p>
                </label -->
              </div>
              <fieldset class="form-group mt-2">
                <div class="form-group text-start mt-5 ms-3 me-3">
                  <label for="transaction_id" class="form-label">Folio</label>
                  <input type="text" class="form-control form-control-lg" placeholder="Ingresar folio del pago" formControlName="transaction_id">
                  <div *ngIf="ufc['transaction_id'].touched && ufc['transaction_id'].invalid">
                    <div *ngIf="ufc['transaction_id'].errors?.['required']" class="text-danger text-sm">El folio es requerido.</div>
                  </div>
                </div>
                <div class="form-group text-start mt-3 ms-3 me-2">
                  <label for="payment_date" class="form-label">Fecha de pago</label>
                  <input type="date" class="form-control form-control-lg" formControlName="payment_date">
                </div>
                <div class="form-group text-start mt-3 ms-3 me-3">
                  <label for="amount" class="form-label">Monto pagado</label>
                  <input type="text" class="form-control form-control-lg" placeholder="Ingresar monto del pago" formControlName="amount">
                  <div *ngIf="ufc['amount'].touched && ufc['amount'].invalid">
                    <div *ngIf="ufc['amount'].errors?.['required']" class="text-danger text-sm">La cantidad es requerida.</div>
                  </div>
                </div>
              </fieldset>
            </div>            
            <div class="col-md-12 text-end mt-4 mb-3 me-3">
              <button type="button" class="btn btn-primary btn-lg rounded-5" (click)="registerPay(bill)" [disabled]="loading">Registrar pago</button>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ModalOptionsPayComponent implements OnInit {
  @Input() bill: any;
  @Input() loading: any;
  @Output() billData: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("modalOptionsPay", { read: ElementRef, static: false }) modalOptionsPay !: ElementRef;

  payForm: FormGroup = new FormGroup({});

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    public icons: IconsService,
    private toastr: ToastrService,
    public activeModal: NgbModal
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.payForm = this.fb.group({
      transaction_id: ['', Validators.required],
      amount: ['', Validators.required],      
      currency: ['MXN', Validators.required],
      status_code: ['3', Validators.required],
      error_code: ['00', Validators.required],
      error_message: ['Approved', Validators.required],
      payment_method_bin: ['0', Validators.required],
      payment_method_type: ['CASH', Validators.required],
      payment_method_brand: ['CASH', Validators.required],
      bill_id: [this.bill.id, Validators.required],
      payment_date: [new Date(), Validators.required],
    });
  
  }

  openModal() {
    // this.modalOptionsPay.nativeElement.click();
  }

  get ufc() {
    return this.payForm.controls;
  }

  selectPayMethod(method: string) {
    this.payForm.patchValue({
      amount: '',
      payment_date: new Date(),
      transaction_id: '',
    });
    this.payForm.get('payment_method_type')?.setValue(method);
    this.payForm.get('payment_method_brand')?.setValue(method);
  }

  dismissModal() {
    
  }

  registerPay(bill: any){
    // console.log('Pagar', bill);
    this.loading = true;
    this.payForm.patchValue({ bill_id: bill.id });
    console.log('Form', this.payForm.value);

    if (this.payForm.invalid) {
      this.toastr.error('Favor de llenar todos los campos', 'Error');
      return;
    }

    this.billData.emit(this.payForm.value);
  }
}
