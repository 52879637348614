import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-provider-edit',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
      <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-8">
            <h1 class="mt-4">Proveedor</h1>
            <p>Configura a los proveedores que están disponibles para los departamentos.</p>
          </div>
        </div>    
        <div class="row">
          <div class="col-md-8 col-lg-6">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h4>Editar proveedor</h4>
                    <p>Configura el servicio con los siguientes campos.</p>
                    <app-form-providers (providerData)="updateProvider($event)" [provider]="provider"></app-form-providers> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ProviderEditComponent {
  provider: any = null;
    
  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    private router: Router,
    private activateRoute: ActivatedRoute,
  ) {
    const ctl = this;
    this.activateRoute.params.subscribe(params => {
      this.store.getProvider(params['id']).subscribe({
        next: (res: any) => {
          ctl.provider = res;
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error('Ocurrió un error al obtener el proveedor.', 'Error');
        }
      });
    });
    }

  updateProvider(event: any) {

    this.store.updateProvider(this.provider.id, event).subscribe({
      next: (res: any) => {
        this.toastr.success('El provedor se actualizó correctamente.', 'Actualizado');
        this.router.navigate(['/providers']);
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al actualizar el proveedor.', 'Error');
      }
    });
  }
}
