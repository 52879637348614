import { Component, OnInit } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { faCircleNotch, faEdit } from '@fortawesome/free-solid-svg-icons';
import { faBuilding, faPhoneAlt, faMailBulk, faLink, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-generate',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-md-11 offset-md-1 col-lg-7 offset-lg-4 col-xl-7 offset-xl-4 col-xxl-8 offset-xxl-3 mt-3">
        <div class="row mb-4">
          <div class="col-md-5">
            <h1>Generar pago</h1>
            <p>Genera un pago sin recibo.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <form [formGroup]="paymentForm" (ngSubmit)="createPayment()">
                      <div class="form-group mb-4 mt-3">
                        <label class="form-label ms-2">Condominios</label>
                        <select class="form-select form-select-lg" (change)="selectSettlement($event)">
                          <option selected [selected]="true" >Condominio</option>
                          <option *ngFor="let settlement of settlements" [value]="settlement.id" >{{settlement.name}}</option>
                        </select>
                      </div>

                      <div class="form-group mb-4 mt-3">
                        <label class="form-label ms-2">Edificio</label>
                        <select class="form-select form-select-lg" (change)="selectBuilding($event)" formControlName="building">
                          <option selected [selected]="true" >Edificio</option>
                          <option *ngFor="let building of buildings" [value]="building.id" >{{building.name}}</option>
                        </select>
                      </div>
                      <div class="form-group mb-4 mt-3">
                        <label class="form-label ms-2">Piso</label>
                        <select class="form-select form-select-lg" (change)="selectFloor($event)">
                          <option selected [selected]="true" >Piso</option>
                          <option *ngFor="let floor of floors" [value]="floor.id" >{{floor.name}}</option>
                        </select>
                      </div>
                      <div class="form-group mb-4 mt-3">
                        <label class="form-label ms-2">Unidad</label>
                        <select class="form-select form-select-lg" (change)="selectUnit($event)">
                          <option selected [selected]="true" >Depto.</option>
                          <option *ngFor="let unit of units" [value]="unit.id" >{{unit.name}}</option>
                        </select>
                      </div>

                      <div class="form-group mb-4 mt-3">
                        <label class="form-label ms-2">Método de pago</label>
                        <input type="">
                      </div>

                      <div class="form-group mb-4 mt-3 text-end">
                      <button type="button" class="btn btn-link me-2" routerLink="/pagos/recibos">Cancelar</button>
                        <button type="submit" class="btn btn-success btn-lg rounded-5 w-25 p-3">Generar pago</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class GenerateComponent implements OnInit {
  settlements: any = [];
  buildings: any = [];
  floors: any = [];
  units: any = [];
  profile: any = {};
  payload: any = {
    page: 1,
    per_page: 100,
    tipo: 1
  };

  paymentForm: FormGroup = new FormGroup({});

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    private toastr: ToastrService,
  ) {
    this.profile = JSON.parse(localStorage.getItem('profile')!);
    this.payload.utility_id = this.profile.profile ? this.profile.profile.utility_id : 0;
  }

  ngOnInit(): void {
    this.buildForm();
    this.loadSettlements();
  }

  buildForm() {
    this.paymentForm = this.fb.group({
      settlement: [''],
      building: [''],
      floor: [''],
      unit: [''],
    });
  }

  loadSettlements() {
    let settlementPayload: any = {
      page: 1,
      per_page: 300,
      tipo: 0,
      utility_id: this.payload.utility_id
    };
    this.store.getSettlements(settlementPayload).subscribe((data: any) => {
      this.settlements = data.data;
    });
  }

  selectSettlement(event: any) {
    let buildingPayload: any = {
      page: 1,
      per_page: 50,
      settlement_id: event.target.value
    };
    
    this.store.getBuildings(buildingPayload).subscribe((data: any) => {
      this.buildings = data.data;
      // this.searchActive = true;
    });
  }

  selectBuilding(event: any) {
    let unitPayload: any = {
      page: 1,
      per_page: 50,
      building_id: event.target.value
    };
    this.store.getFloors(unitPayload).subscribe((data: any) => {
      this.floors = data.data;
    });
  }

  selectFloor(event: any) {
    let unitPayload: any = {
      page: 1,
      per_page: 50,
      floor_id: event.target.value
    };
    this.store.getUnits(unitPayload).subscribe((data: any) => {
      this.units = data.data;
      console.log("UNITS => ", data);
    });

  }

  selectUnit(event: any) {
    // this.store.getBillByUnit(event.target.value, { status: false, state: 1 }).subscribe((data: any) => {
      // this.bills = data;
    // });
  }

  createPayment() {}

}

