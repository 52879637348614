import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TasksService } from 'src/app/services/tasks.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import {DndDropEvent} from 'ngx-drag-drop';

@Component({
  selector: 'app-builder-edit',
  template: `
     <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
      <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-9">
            <h1>Constructor</h1>
            <p>Configura las amenidades que están disponibles para los departamentos.</p>
          </div>
          <div class="col-md-3 text-end mt-4">
            
          </div>
        </div>
        <app-form-builder [builder]="builder" (builderData)="saveBuilder($event)"></app-form-builder>
      </div>
    </div>
  `,
  styleUrls: []
})
export class BuilderEditComponent {
  loading: boolean = false;
  activities: any[] = [];
  checkins: any[] = [];
  checkouts: any[] = [];
  setActivities: any[] = [];

  builder: any = null;

  draggable = {
    // note that data is handled with JSON.stringify/JSON.parse
    // only set simple data or POJO's as methods will be lost
    data: "myDragData",
    effectAllowed: "all",
    disable: false,
    handle: false
  };


  constructor(
    private router: Router,
    private tasksService: TasksService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private breadcrumbService: BreadcrumbService,
    private ativatedRoute: ActivatedRoute
  ) { 
    
  }

  

  ngOnInit(): void {
    this.breadcrumbService.set('@builder', 'Constructor');    
    this.loadActivities();

    this.ativatedRoute.params.subscribe((params) => {
      if (params['id']) {
        console.log('Loading builder => ', params['id']);
        this.tasksService.getBuilder(params['id']).subscribe({
          next: (builder: any) => {
            this.builder = builder;
          },
          error: (err: any) => {
            this.toastr.error('Ocurrió un error al cargar el constructor.');
          },
        });
      }
    });
  }

  loadActivities() {
    this.tasksService.getActivities({}).subscribe({
      next: (activities: any) => {
        this.checkins = activities.data.filter((activity: any) => activity.activity_type_id === 1);
        this.checkouts = activities.data.filter((activity: any) => activity.activity_type_id === 2);
        this.activities = activities.data.filter((activity: any) => activity.activity_type_id === 3);

        console.log("this.checkins ==> ", this.checkins);
      },
      error: (err: any) => {},
    });
  }
  
  loadCheckins() {
    this.tasksService.getActivities({}).subscribe({
      next: (checkins: any) => {
        this.checkins = checkins.data;
      },
      error: (err: any) => {},
    });
  }

  loadCheckouts() {}

  saveBuilder(payload: any) {
    this.tasksService.updateBuilder(this.builder.id, payload).subscribe({
      next: (builder: any) => {
        this.toastr.success('Constructor creado correctamente.');
        this.router.navigateByUrl('/maintenances/builders');
      },
      error: (err: any) => {
        this.toastr.error('Ocurrió un error al crear el constructor.');
      },
    });
  }

  onDragStart(event: DragEvent) {

    console.log("drag started", JSON.stringify(event, null, 2));
  }

  onDragEnd(event: DragEvent) {
    this.setActivities.push(event);
    console.log("drag ended", JSON.stringify(event, null, 2));
  }

  onDraggableCopied(event: DragEvent) {

    console.log("draggable copied", JSON.stringify(event, null, 2));
  }

  onDraggableLinked(event: DragEvent) {

    console.log("draggable linked", JSON.stringify(event, null, 2));
  }

  onDraggableMoved(event: DragEvent) {

    console.log("draggable moved", JSON.stringify(event, null, 2));
  }

  onDragCanceled(event: DragEvent) {

    console.log("drag cancelled", JSON.stringify(event, null, 2));
  }

  onDragover(event: DragEvent) {

    console.log("dragover", JSON.stringify(event, null, 2));
  }

  onDrop(event: DndDropEvent) {
    
    console.log("dropped", JSON.stringify(event, null, 2));
  }
}
