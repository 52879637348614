import { Component, ElementRef, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SettlementsService } from 'src/app/services/settlements.service';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import { SummaryService } from 'src/app/services/summary.service';
import { ngxCsv } from 'ngx-csv';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-tanque-gauge',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-4">
        <div class="row mb-4">
          <div class="col-md-6">
            <xng-breadcrumb ></xng-breadcrumb>
            <h1 class="mt-2">Consumo del tanque</h1>
            <p>Consulta el estado del tanque.</p>
            
            <h3>Condominio</h3>
            <p class="mb-0">{{settlement.name}}</p>
            <p>Edificio: {{building.name}}</p>
            <hr>
            <div class="row">
              <div class="col-10">
                <app-filter-advanced [toDate]="toDate" [fromDate]="fromDate" (dateRange)="updateDate($event)"></app-filter-advanced>
              </div>
              <div class="col-2 text-end">
                <button class="btn btn-outline-primary shadow-sm" (click)="search()">
                  <fa-icon [icon]="icons.faMagnifyingGlass"></fa-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6 text-end">
            <div class="card rounded-4 shadow">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-5 text-start">
                    <h5>Tanque</h5>
                    <h2>{{tanque.name}}</h2>
                    <ngx-emoji emoji="alembic" [style]="{ bottom: '10px', right: '2px', marginTop: '14px', float: 'left' }"></ngx-emoji>                    
                    <h3 style="margin-left: 12px; font-size: 44px; margin-start: 10px;"><span [countUp]="curr_read?.value"></span></h3>
                    <p class="mt-3 mb-0">Litros estimados:</p>
                    <h3 *ngIf="refuels">{{curr_read?.lts_estimated}} Lts.</h3>
                    <p class="mb-0 ">Fecha de última lectura:</p>
                    <h3 *ngIf="refuels">{{curr_read?.created_at | date: 'dd/MM/yy' }}</h3>
                  </div>
                  <div class="col-md-7" *ngIf="current_read">
                    <h5 class="mb-3">Nivel de gas (Lts.)</h5>
                    <!-- app-gauge-chart ></!-->
                    <!-- [percent]="consumptions.usage" [totalPercent]="consumptions.capacity" [barWidth]="40" -->
                    <ngx-charts-gauge
                      [view]="[view[0], view[1]]"
                      [results]="current_read"
                      [legendTitle]="'Product Sale Chart'"
                      [legend]="false"
                      [min]="0"
                      [max]="tanque.capacidad"
                      [smallSegments]="0"
                      [bigSegments]="5"
                      [startAngle]="-120"
                      [showText]="true"
                      style="position: relative; left: -160px;"
                    >
                    </ngx-charts-gauge>
                  </div>                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mb-2">
            <div class="card rounded-4 border-0 shadow mb-3" id="anovaLineChart" #anovaLineChart>
              <div class="card-body p-4" *ngIf="anova_asset_readings.length>0">
                <h5>ANOVA {{anova_asset_readings.length}}</h5>
                <app-anova-line-chart-consumptions
                  [data]="anova_asset_readings"
                  [start_date]="fromDate"
                  [end_date]="toDate"
                  [tanque]="tanque"
                ></app-anova-line-chart-consumptions>
              </div>
            </div>
          </div>
          <div class="col-md-12 mb-2">
            <div class="card rounded-4 border-0 shadow mb-3" id="indexChart" #indexChart>
              <div class="card-body p-4">
                <h5>Predicción de consumo</h5>
                <app-index-chart
                  [data]="data"
                  [start_date]="fromDate"
                  [end_date]="toDate"
                  [tanque]="tanque"
                ></app-index-chart>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card rounded-5 border-0 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-7">
                    <h5 class="mt-2">Lecturas</h5>
                  </div>
                  <div class="col-5 text-end">
                    <button type="button" class="btn btn-outline-info btn-sm rounded-5" (click)="reportReadings()">
                      <fa-icon [icon]="icons.faDownload" *ngIf="!creatingReport"></fa-icon>
                      <fa-icon [icon]="icons.faCircleNotch" [spin]="true" *ngIf="creatingReport"></fa-icon>
                      Reporte
                    </button>
                  </div>
                 <div class="col-md-12">
                  <table class="table table-sm table-striped" *ngIf="readings">
                      <thead>
                        <tr>
                          <th>Folio</th>
                          <th>Lectura</th>
                          <th>Fecha</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let reading of readings">
                          <td>{{reading.id}}</td>
                          <td>{{reading.value}} %</td>
                          <td>{{reading.created_at | date: 'dd/MM/yyyy' }}</td>
                        </tr>
                        <tr *ngIf="readings.length==0" class="text-center">
                          <td colspan="3">No hay lecturas</td>
                        </tr>
                      </tbody>
                    </table>
                 </div>
                </div>
              </div>
            </div>

            <div class="card rounded-5 border-0 shadow mt-4">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-7">
                    <h5 class="mt-2">Recargas</h5>
                  </div>
                  <div class="col-5 text-end">
                    <button type="button" class="btn btn-outline-info btn-sm rounded-5" (click)="reportRefuels()">
                      <fa-icon [icon]="icons.faDownload" *ngIf="!creatingReport"></fa-icon>
                      <fa-icon [icon]="icons.faCircleNotch" [spin]="true" *ngIf="creatingReport"></fa-icon>  
                      Reporte
                    </button>
                  </div>
                 <div class="col-md-12">
                    <table class="table table-sm table-striped" *ngIf="readings">
                      <thead>
                        <tr>
                          <th>Folio</th>
                          <th>Lectura</th>
                          <th>Fecha</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let refuel of refuels">
                          <td>{{refuel.folio}}</td>
                          <td>{{refuel.post_charge}} %</td>
                          <td>{{refuel.updated_at | date: 'dd/MM/yy' }}</td>
                        </tr>
                        <tr *ngIf="refuels.length==0" class="text-center">
                          <td colspan="2">No hay lecturas</td>
                        </tr>
                      </tbody>
                    </table>
                 </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card rounded-5 border-0 shadow">
              <div class="card-body p-4">
                <div class="row mt-0">
                  <div class="col-7">
                    <h5 class="mt-2">Consumos</h5>
                  </div>
                  <div class="col-5 text-end">
                    <button type="button" class="btn btn-outline-info btn-sm rounded-5" (click)="reportConsumptions()">
                      <fa-icon [icon]="icons.faDownload" *ngIf="!creatingReport"></fa-icon>
                      <fa-icon [icon]="icons.faCircleNotch" [spin]="true" *ngIf="creatingReport"></fa-icon>  
                      Reporte
                    </button>
                  </div>
                  <div class="col-md-12 mt-2">
                    <table class="table table-sm table-striped">
                      <thead>
                        <tr>
                          <th>Recibo</th>
                          <th scope="col">Litros</th>
                          <th scope="col" class="text-end">Fecha</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="consumptions">
                        <tr *ngFor="let consumption of consumptions">
                          <th>{{consumption.bill_id}}</th>
                          <th scope="row">{{consumption.liters | number: '1.2-2'}}</th>
                          <td class="text-end">{{consumption.created_at | date: 'dd/MM/yy' }}</td>
                        </tr>
                        <tr *ngIf="consumptions.length==0">
                          <td colspan="2" class="text-center">No existen consumos en el periodo</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="col-md-4">
            <div class="card rounded-5 border-0 shadow">
              <div class="card-body p-4" id="nivel" #nivel>
                <div class="row mt-0">
                  <div class="col-12">
                    <h5>Consumo vs. Lecturas</h5>
                  </div>
                  <div class="col-md-12" *ngIf="consumption_data.readings?.length>0">
                    <app-bar-chart-consumptions [data]="consumption_data"></app-bar-chart-consumptions>
                  </div>
                  <div class="col-md-12">
                    <div class="alert alert-warning shadow-sm rounded-4" *ngIf="lowWarning">
                      <p class="mb-0">El nivel del tanque se encuentra bajo, requiere de una recarga próximamente.</p>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <!-- ngx-charts-number-card
                      [view]="[300,300]"
                      [results]="consumption_data.readings"
                      [animations]="true"
                      [cardColor]="cardColor"
                    ></!-->
                  </div>
                  <!-- div class="col-md-12" *ngIf="consumption_data.readings?.length>0">
                    <h5 class="mt-2">Porcentaje de carga</h5>
                    <ngx-charts-linear-gauge
                      [view]="[view[0], view[1]]"
                      [scheme]="colorScheme"
                      [animations]="true"
                      [min]="0"
                      [max]="100"
                      [value]="consumption_data.readings[consumption_data.readings.length-1].value"
                      [previousValue]="16"
                      [units]="units"
                      >
                    </ngx-charts-linear-gauge>
                  </!-->
                </div>
              </div>
            </div>
            <div class="card rounded-5 border-0 shadow mt-4" *ngIf="tanque_children.length>0">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-7">
                    <h5 class="mt-2">Tanques en arreglo</h5>
                  </div>
                  <div class="col-5 text-end">
                    <button type="button" class="btn btn-outline-info btn-sm rounded-5" (click)="reportChildReadings()">
                      <fa-icon [icon]="icons.faDownload" *ngIf="!creatingReport"></fa-icon>
                      <fa-icon [icon]="icons.faCircleNotch" [spin]="true" *ngIf="creatingReport"></fa-icon>  
                      Reporte
                    </button>
                  </div>
                  <div class="col-12">
                    <table class="table table-stripped table-sm">
                      <thead>
                        <tr>
                          <th>Nombre</th>
                          <th>Lectura</th>
                          <th>Fecha</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let child of tanque_children">
                          <td>{{child.tanque.name}} </td>
                          <td>{{child.readings.value}}</td>
                          <td>{{child.readings.created_at | date: 'dd/MM/yy'}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>    
  `,
  styles: [
		`
			.dp-hidden {
				width: 0;
				margin: 0;
				border: none;
				padding: 0;
			}
			.custom-day {
				text-align: center;
				padding: 0.185rem 0.25rem;
				display: inline-block;
				height: 2rem;
				width: 2rem;
			}
			.custom-day.focused {
				background-color: #e6e6e6;
			}
			.custom-day.range,
			.custom-day:hover {
				background-color: rgb(2, 117, 216);
				color: white;
			}
			.custom-day.faded {
				background-color: rgba(2, 117, 216, 0.5);
			}
		`,
	],
})
export class TanqueGaugeComponent implements OnInit {
  @ViewChild('nivel') nivel: ElementRef | undefined;

  tanqueId: any = null;
  tanque: any = {};
  consumptions: any = [];
  view = [180, 100];
  units: string = '% Restante';
  deptos: any = [];
  refuels: any = [];
  readings: any = [];

  creatingReport: boolean = false;

  consumption_data: any = [];
  consumption_consumptions: any = [];
  consumption_tanques: any = [];

  anova_asset_readings: any = [];

  current_read: any = null;

  a = new Date();
  fromDate; // : any = "2023/12/01"; // Date = new Date();
  toDate; // : any = "2024/02/02"; // Date = new Date();

  cardColor: string = '#232837';

  curr_read: any = null;

  lowWarning: boolean = false;

  settlement: any = {};
  building: any = {};
  prediction: any = [];
  tanque_children: any = [];

  // options
  legend: boolean = false;
  showLabels: boolean = false;
  animations: boolean = true;
  timeline: boolean = true;

  multi: any = [];
  data: any = [];

  colorScheme: Color = { domain: ['#99CCE5', '#FF7F7F'], group: ScaleType.Ordinal, selectable: true, name: 'Customer Usage', };

  views: any[] = [200, 200];
  
  constructor(
    private store: SettlementsService,
    private summ: SummaryService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    public icons: IconsService
  ) {
    let today = new Date().getDate();
    let past = today - 10;
    let pastM = 0;
    let futureM = 2;

    console.log('today => ', today, past);
    if (past <= 0) {
      pastM = 1;
      futureM = 1;
      past = 15;
    }
    console.log('past => ', past, pastM, futureM)
    this.fromDate = new NgbDate(this.a.getFullYear(), this.a.getMonth()-pastM, past); // new Date(this.a.getFullYear(), this.a.getMonth()+1, 0);
    // this.fromDate = new NgbDate(this.a.getFullYear(), 1, 1);
    this.toDate = new NgbDate(this.a.getFullYear(), this.a.getMonth()+futureM, 29);
  }
  
    ngOnInit(): void {
      this.loadData();
      this.breadcrumbService.set('@tanque', 'Tanques');     

      // nivel
      let level = this.nivel?.nativeElement;
      console.log('level => ', level);
      this.view = [Number(level?.offsetWidth),250];
      console.log("===========> ", level?.offsetWidth);
    }

    ngOnChanges(changes: SimpleChange) {
      console.log('changes => ', changes);
    }

    search() {
      this.reloadData();
    }

    updateDate(event: any) {
      this.fromDate = event.from;
      this.toDate = event.to;
    }

    onDateSelection(event: any) {}

    isRange(date: any) {}
    
		isHovered(date: any) {
      return false;
    }
    isInside(date: any) {
      return false;
    }

		hoveredDate: any;

    onSelect(data: any): void {
      console.log('Item clicked', JSON.parse(JSON.stringify(data)));
    }
  
    onActivate(data: any): void {
      console.log('Activate', JSON.parse(JSON.stringify(data)));
    }
  
    onDeactivate(data: any): void {
      console.log('Deactivate', JSON.parse(JSON.stringify(data)));
    }

    async loadData() {
      this.route.params.subscribe(async (params: any) => {
        this.tanqueId = params['id'];

        this.store.getUnitByTanque(this.tanqueId).subscribe((data: any) => {
          console.log('getUnitByTanque data => ', data);
          this.deptos = data;
        });

        this.store.getTanque(this.tanqueId).subscribe((data: any) => {
          console.log('getTanque data => ', data);
          this.tanque = data;
          this.breadcrumbService.set('@consumoTanque', data.name);
          
          this.building = data.building;
          this.store.getSettlement(data.building.settlement_id).subscribe((settlement: any) => {
            this.settlement = settlement;
          });
        });

        this.store.getChildrenTanks(this.tanqueId).subscribe((data: any) => {
          console.log('getChildrenTanks data => ', data);
          this.tanque_children = data;
        });

        this.store.getTanqueEstimation(this.tanqueId, {
          fromDate: `${this.fromDate['year']}-${this.fromDate['month']}-${this.fromDate['day']}`,
          toDate: `${this.toDate['year']}-${this.toDate['month']}-${this.toDate['day']}`
        }).subscribe((data: any) => {
          console.log('getTanqueEstimation data => ', data);
          this.multi = data;
          this.data = data;
          this.readings = data.readings;
          this.refuels = data.refuels;
          this.consumptions = data.consumptions;
          this.prediction = data.prediction;
          this.current_read = [{ value: data.readings[data.readings.length-1].lts_estimated, name: 'Lectura actual' }];
          this.curr_read = data.readings[data.readings.length-1];
          // this.lowWarning = data.readings[data.readings.length-1].lts_estimated < this.tanque.limite_inferior;
        });

        this.store.getTanqueAnova(this.tanqueId, {
          fromDate: `${this.fromDate['year']}-${this.fromDate['month']}-${this.fromDate['day']}`,
          toDate: `${this.toDate['year']}-${this.toDate['month']}-${this.toDate['day']}`
        }).subscribe((data: any) => {
          // console.log('ANOVA data => ', data);
          this.anova_asset_readings = JSON.parse(data);
        });
      });

      this.summ.getTanqueSummary(this.tanqueId).subscribe((data: any) => {
        console.log('getTanqueSummary data => ', data);
        this.consumption_data = data; // .consumptions;
        this.consumption_consumptions = data.consumptions;
        this.consumption_tanques = data.readings;
      });

    }

    reloadData() {
      this.store.getTanqueEstimation(this.tanqueId, {
        fromDate: `${this.fromDate['year']}-${this.fromDate['month']}-${this.fromDate['day']}`,
        toDate: `${this.toDate['year']}-${this.toDate['month']}-${this.toDate['day']}`
      }).subscribe((data: any) => {
        console.log('getTanqueEstimation data => ', data);
        this.data = data;
        this.readings = data.readings;
        this.refuels = data.refuels;
        this.consumptions = data.consumptions;
      });

      this.store.getTanqueAnova(this.tanqueId, {
        fromDate: `${this.fromDate['year']}-${this.fromDate['month']}-${this.fromDate['day']}`,
        toDate: `${this.toDate['year']}-${this.toDate['month']}-${this.toDate['day']}`
      }).subscribe((data: any) => {
        // console.log('ANOVA data => ', data);
        this.anova_asset_readings = JSON.parse(data);
      });
    }

    reportReadings() {
      this.creatingReport = true;
      let filter: any[] = [];
      this.readings.map((reading: any) => {
        filter.push({
          folio: reading.id,
          carga: reading.value,
          tipo_lectura: reading.read_type == 1 ? 'Lectura' : 'Recarga',
          litros_estimados: reading.lts_estimated,
          fecha: reading.created_at,
        });
      });
      
      let options = {
        title: 'Reporte de lecturas',
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        useBom: true,
        headers: [
          "Folio",
          "Carga",
          "Tipo de lectura",
          "Litros estimados",
          "Fecha"
        ]
      };

      console.log('filter => ', filter);

      new ngxCsv(filter, `reporte_lecturas_tanque-${this.tanque.name}`, options);
      this.creatingReport = false;
    }

    reportRefuels() {}

    reportConsumptions() {
      this.creatingReport = true;
      let filter: any[] = [];
      this.consumptions.map((reading: any) => {
        filter.push({
          folio: reading.bill_id,
          litros: reading.liters,
          fecha: reading.created_at,
        });
      });
      
      let options = {
        title: 'Reporte de consumos',
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        useBom: true,
        headers: [
          "Folio",
          "Litros",
          "Fecha"
        ]
      };

      console.log('filter => ', filter);

      new ngxCsv(filter, `reporte_consumos_tanque-${this.tanque.name}`, options);
      this.creatingReport = false;
    }

    reportChildReadings() {
      this.creatingReport = true;
      let filter: any[] = [];

      this.data.children.map((child: any) => {
        console.log('child ====> ', child);
        child.map((reading: any) => {
          console.log('reading ====> ', reading);
          filter.push({
            nombre: reading.tanque_name,
            lectura: reading.value,
            estimado: reading.lts_estimated,
            fecha: reading.created_at,
          });
        });
      });

      // this.tanque_children.map((reading: any) => {
        // filter.push({
          // nombre: reading.tanque.name,
          // lectura: reading.readings.value,
          // fecha: reading.readings.created_at,
        // });
      // });
      
      let options = {
        title: 'Reporte de lecturas de tanques arreglo',
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        useBom: true,
        headers: [
          "Nombre",
          "Lectura",
          "Estimado",
          "Fecha"
        ]
      };

      console.log('filter => ', filter);

      new ngxCsv(filter, `reporte_lecturas_tanques_arreglo-${this.tanque.name}`, options);
      this.creatingReport = false;
    }

}
