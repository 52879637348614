import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TasksService } from 'src/app/services/tasks.service';

@Component({
  selector: 'app-modal-vehicles',
  template: `
    <div #modalVehicle id="modalVehicle" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalVehicle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-top">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Vehículos</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <!-- span class="fa fa-times"></!-->
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mt-3 mb-3" *ngIf="vehicles.length>0">
                <label class="form-control-label" for="vehicle">Vehículo</label>
                <select class="form-select form-select-lg" (change)="selectVehicle($event)">
                  <option selected disabled>Selecciona un vehículo</option>
                  <option *ngFor="let vehicle of vehicles" [value]="vehicle.id">
                    {{vehicle.name}} (placas: {{vehicle.plates}})
                  </option>
                </select>
              </div>
              <div class="col-md-12 text-end" *ngIf="vehicles.length==0">              
                <p class="mb-0 text-center text-muted" style="font-size: 13px;">No hay vehículos disponibles</p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" (click)="saveVehicle()">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ModalVehiclesComponent implements OnInit {
  @Input() technician: any;
  @Input() profile: any;
  @Output() vehicleData = new EventEmitter<any>();

  vehicles: any = [];
  selectedVehicle: number = 0;

  constructor(
    private tasks: TasksService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.loadVehicles();
  }

  loadVehicles() {
    this.tasks.getVehicles({ profile_id: this.profile.profile.id }).subscribe({
      next: (resp: any) => {
        this.vehicles = resp.data;
      },
      error: (err: any) => {
        this.toastr.error(err.error.message, 'Error');
      }
    });
  }

  selectVehicle(vehicle: any) {
    console.log(vehicle.target.value);
    this.selectedVehicle = vehicle.target.value;
  }

  saveVehicle() {
    if (this.selectedVehicle == 0) {
      this.toastr.error('Selecciona un vehículo', 'Error');
      return;
    }

    let payload: any = {
      vehicle_id: this.selectedVehicle,
      assignee_id: this.technician.id
    }

    this.vehicleData.emit(payload);
  }
}
