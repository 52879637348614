import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { faEdit, faPlus, faTrashAlt, faCircleNotch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { TasksService } from 'src/app/services/tasks.service';

@Component({
  selector: 'app-modal-work-schedule',
  template: `
    <div #modalWorkSchedule id="modalWorkSchedule" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalWorkSchedule" aria-hidden="true">
      <div class="modal-dialog modal-dialog-top">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Horario laboral</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" class="btn btn-link text-dark">
              <fa-icon [icon]="faTimes"></fa-icon>
            </button>
          </div>
          <div class="modal-body p-4">
            <form [formGroup]="scheduleForm" (ngSubmit)="updateSchedule()">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <div class="row mt-3">
                      <div class="col-4">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" checked id="monday" formControlName="monday" (change)="toggleWork($event)">
                          <label class="form-check-label" for="monday">Lunes</label>
                        </div>
                      </div>
                      <div class="col-4">
                        <select class="form-select form-select-sm" formControlName="monday_start">
                          <option *ngFor="let horario of horarios" [value]="horario.id">{{horario.name}}</option>
                        </select>
                      </div>
                      <div class="col-4">
                        <select class="form-select form-select-sm" formControlName="monday_end">
                          <option *ngFor="let horario of horarios" [value]="horario.id">{{horario.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-4">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="tuesday" formControlName="tuesday" (change)="toggleWork($event)" >
                          <label class="form-check-label" for="monday">Martes</label>
                        </div>
                      </div>
                      <div class="col-4">
                        <select class="form-select form-select-sm" formControlName="tuesday_start">
                          <option *ngFor="let horario of horarios" [value]="horario.id">{{horario.name}}</option>
                        </select>
                      </div>
                      <div class="col-4">
                        <select class="form-select form-select-sm" formControlName="tuesday_end">
                          <option *ngFor="let horario of horarios" [value]="horario.id">{{horario.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-4">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="wednesday" formControlName="wednesday" (change)="toggleWork($event)" >
                          <label class="form-check-label" for="monday">Miércoles</label>
                        </div>
                      </div>
                      <div class="col-4">
                        <select class="form-select form-select-sm" formControlName="wednesday_start">
                          <option *ngFor="let horario of horarios" [value]="horario.id">{{horario.name}}</option>
                        </select>
                      </div>
                      <div class="col-4">
                        <select class="form-select form-select-sm" formControlName="wednesday_end">
                          <option *ngFor="let horario of horarios" [value]="horario.id">{{horario.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-4">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="thursday" formControlName="thursday" (change)="toggleWork($event)">
                          <label class="form-check-label" for="monday">Jueves</label>
                        </div>
                      </div>
                      <div class="col-4">
                        <select class="form-select form-select-sm" formControlName="thursday_start">
                          <option *ngFor="let horario of horarios" [value]="horario.id">{{horario.name}}</option>
                        </select>
                      </div>
                      <div class="col-4">
                        <select class="form-select form-select-sm" formControlName="thursday_end">
                          <option *ngFor="let horario of horarios" [value]="horario.id">{{horario.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-4">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="friday" formControlName="friday" (change)="toggleWork($event)" >
                          <label class="form-check-label" for="monday">Viernes</label>
                        </div>
                      </div>
                      <div class="col-4">
                        <select class="form-select form-select-sm" formControlName="friday_start">
                          <option *ngFor="let horario of horarios" [value]="horario.id">{{horario.name}}</option>
                        </select>
                      </div>
                      <div class="col-4">
                        <select class="form-select form-select-sm" formControlName="friday_end">
                          <option *ngFor="let horario of horarios" [value]="horario.id">{{horario.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-4">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="saturday" formControlName="saturday" (change)="toggleWork($event)">
                          <label class="form-check-label" for="monday">Sábado</label>
                        </div>
                      </div>
                      <div class="col-4">
                        <select class="form-select form-select-sm" formControlName="saturday_start">
                          <option *ngFor="let horario of horarios" [value]="horario.id">{{horario.name}}</option>
                        </select>
                      </div>
                      <div class="col-4">
                        <select class="form-select form-select-sm" formControlName="saturday_end">
                          <option *ngFor="let horario of horarios" [value]="horario.id">{{horario.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-4">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="sunday" formControlName="sunday" (change)="toggleWork($event)">
                          <label class="form-check-label" for="monday">Domingo</label>
                        </div>
                      </div>
                      <div class="col-4">
                        <select class="form-select form-select-sm" formControlName="sunday_start">
                          <option *ngFor="let horario of horarios" [value]="horario.id">{{horario.name}}</option>
                        </select>
                      </div>
                      <div class="col-4">
                        <select class="form-select form-select-sm" formControlName="sunday_end">
                          <option *ngFor="let horario of horarios" [value]="horario.id">{{horario.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-4 mb-2">
                      <div class="col-12 text-end">
                        <button type="submit" class="btn btn-success rounded-4">Guardar</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ModalWorkScheduleComponent implements OnInit {
  @Input() technician: any;
  @Input() work_schedule: any;
  @Input() profile: any;

  loading: boolean = false;
  dailyData: any = {};
  dailyForm: FormGroup = new FormGroup({});
  isNew: boolean = true;

  scheduleForm: FormGroup = new FormGroup({});

  horarios: any = [
    { id: 1, name: '1:00' },
    { id: 2, name: '2:00' },
    { id: 3, name: '3:00' },
    { id: 4, name: '4:00' },
    { id: 5, name: '5:00' },
    { id: 6, name: '6:00' },
    { id: 7, name: '7:00' },
    { id: 8, name: '8:00' },
    { id: 9, name: '9:00' },
    { id: 10, name: '10:00' },
    { id: 11, name: '11:00' },
    { id: 12, name: '12:00' },
    { id: 13, name: '13:00' },
    { id: 14, name: '14:00' },
    { id: 15, name: '15:00' },
    { id: 16, name: '16:00' },
    { id: 17, name: '17:00' },
    { id: 18, name: '18:00' },
    { id: 19, name: '19:00' },
    { id: 20, name: '20:00' },
    { id: 21, name: '21:00' },
    { id: 22, name: '22:00' },
    { id: 23, name: '23:00' },
    { id: 24, name: '00:00' },
  ]

  schedule: any = [];

  faTimes = faTimes;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private store: SettlementsService,
    private tasks: TasksService,
  ) { }

  ngOnInit(): void {    
    this.buildDailyForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("work_schedule ==> ", changes['work_schedule']);
    if (changes['work_schedule'] && changes['work_schedule'].currentValue) {
      if (changes['work_schedule'].currentValue.id) {
        this.scheduleForm.patchValue(changes['work_schedule'].currentValue);
        this.isNew = false;
      }
    }
    if (changes['technician'] && changes['technician'].currentValue) {
      this.scheduleForm.patchValue({
        user_id: changes['technician'].currentValue.id
      });
    }
  }

  buildDailyForm() {
    this.scheduleForm = this.fb.group({
      id: [''],
      monday: [true],
      monday_start: [9],
      monday_end: [18],
      tuesday: [true],
      tuesday_start: [9],
      tuesday_end: [18],
      wednesday: [true],
      wednesday_start: [9],
      wednesday_end: [18],
      thursday: [true],
      thursday_start: [9],
      thursday_end: [18],
      friday: [true],
      friday_start: [9],
      friday_end: [18],
      saturday: [true],
      saturday_start: [9],
      saturday_end: [18],
      sunday: [false],
      sunday_start: [''],
      sunday_end: [''],
      user_id: ['', Validators.required]
    });

  }

  ufc() {
    return this.scheduleForm.controls;
  }

  preFill() {
    if (this.technician) {
      

      this.tasks.getWorkHour(this.technician.id).subscribe({
        next: (res: any) => {
          this.schedule = res;
          this.scheduleForm.patchValue(res);
        },
        error: (err: any) => {
          console.log(err);
        }
      });
    }
  }

  updateSchedule() {
    console.log("scheduleForm ==> ", this.scheduleForm.value);
    // return;

    if (this.scheduleForm.invalid) {
      this.scheduleForm.markAllAsTouched();
      this.toastr.error('Revisa los campos en rojo.', 'Error');
      return;
    }

    if (this.isNew) {
      this.tasks.createWorkHour(this.scheduleForm.value).subscribe({
        next: (res: any) => {
          this.toastr.success('Horario creado con éxito.', 'Éxito');
          this.scheduleForm.patchValue(res);
          window.location.reload();
        }, error: (err: any) => {
          console.log(err);
          this.toastr.error('Ocurrió un error al crear el horario.', 'Error');
        }
      });
    } else {
      this.tasks.updateWorkHour(this.scheduleForm.value.id, this.scheduleForm.value).subscribe({
        next: (res: any) => {
          this.toastr.success('Horario actualizado con éxito.', 'Éxito');
          this.scheduleForm.patchValue(res);
          window.location.reload();
        }, error: (err: any) => {
          console.log(err);
          this.toastr.error('Ocurrió un error al actualizar el horario.', 'Error');
        }
      });
    }
  }
  
  toggleWork(event: any) {
    console.log("event ==> ", event);
    if (event.target.checked) {
      this.scheduleForm.get(event.target.id + '_start')!.setValue(9);
      this.scheduleForm.get(event.target.id + '_end')!.setValue(18);
    } else {
      this.scheduleForm.get(event.target.id + '_start')!.setValue('');
      this.scheduleForm.get(event.target.id + '_end')!.setValue('');
    }
  }


}
