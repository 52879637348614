import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { TasksService } from 'src/app/services/tasks.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { environment } from 'src/environments/environment';
import { CatalogsService } from 'src/app/shared/services/catalogs.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-task-review',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
        <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-6">
            <h1>Resúmen de tarea</h1>
            <p>Consulta los resultados de la tarea con los siguientes campos.</p>
            <hr>
            <div class="row">
              <div class="col-md-3">
                <div class="avatar-container text-center" style="left: 0 !important;" *ngIf="technician.id">
                  <div class="image-container border border-4" style="width: 100px; height: 100px;" *ngIf="techAvatar!=''">
                    <img [src]="techAvatar" width="100%">
                  </div>
                </div>
              </div>
              <div class="col-md-9 mt-3">
                <h5 class="mb-0">{{technician.name}} {{technician.last_name}}</h5>
                <p class="text-muted" style="font-size: 14;">{{technician.email}}</p>
              </div>
            </div>
            
          </div>
          <div class="col-md-6 text-end mt-4 mb-3">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  
                
                <div class="col-md-6 text-center">
                  <table class="table">
                    <tr>
                      <td>Programada:</td>
                      <td>{{task.due_date | date: 'dd/MM/yy'}} {{task.due_time | date: 'HH:mm'}}</td>
                    </tr>
                    <tr>
                      <td>Completada:</td>
                      <td>{{task.completed_at | date: 'dd/MM/yy HH:mm'}}</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6 text-end">
                  <div *ngIf="task.status" class="badge badge-pill badge-lg rounded-5 px-5 py-3" [style]="{ backgroundColor: catalog.status[task.status].color, color: catalog.status[task.status].text }">{{catalog.status[task.status].name}}</div>
                  </div>
                <div class="col-md-6 offset-md-3 mt-3 text-center">
                  <h1 [countUp]="grade" [duration]="1000" style="font-size: 50px;" class="mb-0"></h1>
                  <p>Calificación</p>
                </div>
                <div class="col-md-4">
                  <!-- div *ngIf="task.completed" class="badge badge-pill bg-info">{{task.completed}}</!-->
                  <!-- div *ngIf="task.priority" class="badge badge-pill badge-lg rounded-4" [style]="{ backgroundColor: priority[task.priority].color }">{{priority[task.priority].name}}</!-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3 mb-5 pe-0">
          <div class="col-md-8">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12" *ngIf="!loading">
                    <h5>Resúmen de la tarea</h5>
                    <h2>#{{settlement.id}} {{settlement.name}}</h2>
                    <!-- h3>{{settlement.address}}</!-->
                    <p class="mb-2"><small>{{settlement.address}}, {{settlement.city}}, {{settlement.country}}</small></p>
                    <p class="mb-0" style="font-size: 17px;"><small>{{settlement.email}}</small></p>
                    <p class="mb-" style="font-size: 17px;"><small>{{settlement.phone}}</small></p>  

                  </div>
                  <div class="col-md-12 mt-4">
                    <div class="row">
                      <div *ngIf="loading" class="col-12">
                        <div class="mt-5 mb-5 text-center">
                          <fa-icon [icon]="icons.faCircleNotch" [spin]="true" size="4x"></fa-icon>
                        </div>
                      </div>
                      <div class="col-md-12" *ngIf="settlement.id != null">
                        <div *ngFor="let building of settlement.buildings!">

                          <div *ngFor="let tanque of building.tanques">
                            <div>{{tanque.name}}</div>
                            <div class="col-md-6 text-end">
                              <table class="table table-sm w-100">
                                <tr>
                                  <th>Calificación</th>
                                  <th>Duración</th>
                                </tr>
                                <tr *ngFor="let stat of tanque.stats">
                                  <td>{{stat.grade}}</td>
                                  <td>{{stat.duration | number : '1.2-2' }} seg.</td>
                                </tr>
                              </table>
                            </div>

                            <div *ngFor="let stat of tanque.stats">
                              <div class="row border border-1 rounded-3 m-2 p-2" *ngIf="stat.activity_type_id == 3">
                                <div class="col-md-3">
                                  <div class="rounded-4 shadow-sm" style="overflow: hidden;">
                                    <img [src]="loadImg(stat.item?.photo)" width="100%" />
                                  </div>
                                </div>
                                <div class="col-md-3">
                                  <p>Folio: {{stat.item?.id}}</p>
                                </div>
                                <div class="col-md-3">
                                  <p>Lectura: {{stat.item?.value}}</p>
                                </div>
                                <div class="col-md-3">
                                  <p>Fecha: {{stat.item?.created_at | date: 'dd/MM/yy HH:mm'}}</p>
                                </div>
                              </div>
                            </div>
                          </div>                          
                          <div *ngIf="building.units.length>0">
                            <h5>{{ building.name }}</h5>
                            <div class="list-group">
                              <div class="list-group-item" *ngFor="let unit of building.units">
                                <div class="row">
                                  <div class="col-md-6">
                                    <h4>#{{unit.id}} {{ unit.name }}</h4>
                                  </div>
                                  <div class="col-md-6 text-end">
                                    <table class="table table-sm w-100">
                                      <tr>
                                        <th>Calificación</th>
                                        <th>Duración</th>
                                      </tr>
                                      <tr *ngFor="let stat of unit.stats">
                                        <td>{{stat.grade}}</td>
                                        <td>{{stat.duration | number : '1.2-2' }} seg.</td>
                                      </tr>
                                    </table>
                                  </div>
                                  <div class="col-md-12">
                                    <div *ngFor="let stat of unit.stats">
                                      <div class="row border border-1 rounded-3 m-2 p-2" *ngIf="stat.activity_type_id == 2">
                                        <div class="col-md-3">
                                          <div class="rounded-4 shadow-sm" style="overflow: hidden;"><img [src]="loadImg(stat.item?.photo)" width="100%"></div>
                                          <div class="rounded-4 shadow-sm" style="overflow: hidden;"><img [src]="loadImg(stat.item?.ocr_image)" width="100%"></div>
                                        </div>
                                        <div class="col-md-3">
                                          <p>Folio: {{stat.item?.id}}</p>
                                        </div>
                                        <div class="col-md-3">
                                          <p>Lectura: {{stat.item?.value}}</p>
                                          <p>OCR: {{stat.item?.ocr_value}}</p>
                                        </div>
                                        <div class="col-md-3">
                                          <p>Fecha: {{stat.item?.created_at | date: 'dd/MM/yy HH:mm'}}</p>
                                        </div>
                                      </div>
                                      <div class="row border border-1 rounded-3 m-2 p-2" *ngIf="stat.activity_type_id == 7">
                                        <div class="col-md-3">
                                        <div class="rounded-4 shadow-sm" style="overflow: hidden;"><img [src]="loadImg(stat.item?.photo)" width="100%"></div>
                                          <div class="rounded-4 shadow-sm" style="overflow: hidden;"><img [src]="loadImg(stat.item?.medidor)" width="100%"></div>
                                          <div class="rounded-4 shadow-sm" style="overflow: hidden;"><img [src]="loadImg(stat.item?.ocr_image)" width="100%"></div>
                                        </div>
                                        <div class="col-md-3">
                                          <p>Folio: {{stat.item?.id}}</p>
                                          <p>OCR: {{stat.item?.ocr_value}}</p>
                                        </div>
                                        <div class="col-md-3">
                                          <p>Lectura: {{stat.item?.value}}</p>
                                        </div>
                                        <div class="col-md-3">
                                          <p>Fecha: {{stat.item?.created_at | date: 'dd/MM/yy HH:mm'}}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 pe-0" >
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="col-md-12 text-center mt-3 mb-4">
                      <button class="btn btn-lg btn-outline-primary rounded-4 w-100" [disabled]="loading" (click)="downloadCSV()">Descargar reporte</button>
                    </div>
                    <h5>Constructor</h5>
                    <div class="list-group">
                      <div class="list-group-item rounded-2" *ngFor="let activity of activities">
                        <div class="row">
                          <div class="col-md-8">
                            {{ activity.name }}
                          </div>
                          <div class="col-md-4 text-end">
                            {{ activity.weight }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <h5 class="mb-2 mt-4">Habilidades requeridas</h5>
                    <div *ngIf="task.skills?.length>0">
                      <div class="list-group-item" *ngFor="let skill of task.skills">
                        <div class="row">
                          <div class="col-md-8">
                            {{ skill.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="task.skills?.length==0">
                      <div class="list-group">
                        <div class="list-group-item text-center">No se requieren habilidades</div>
                      </div>
                    </div>

                    <h5 class="mb-2 mt-4">Herramientas requeridas</h5>
                    <div *ngIf="task.tools?.length>0">
                      <div class="list-group-item" *ngFor="let tool of task.tools">
                        <div class="row">
                          <div class="col-md-8">
                            {{ tool.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="task.tools?.length==0">
                      <div class="list-group">
                        <div class="list-group-item text-center">No se requieren herramientas</div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <!-- div class="card border-0 rounded-4 shadow mt-3">
              <div class="card-body">
                
              </div>
            </!-->
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class TaskReviewComponent implements OnInit {
  apiUrl: string = environment.AWS_REST_WSPREFIX;
  profile: any = {};
  task: any = {};
  builder: any = {};
  grade: number = 0;
  activities: any = [];
  settlement: any = {};
  technician: any = {};
  fullData: any = {};
  loading: boolean = false;

  techAvatar: string = '';

  downloading: boolean = false;

  constructor(
    private store: SettlementsService,
    private taskService: TasksService,
    private router: Router,
    private toastr: ToastrService,
    private activateRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    public catalog: CatalogsService,
    public icons: IconsService
  ) {
    this.profile = JSON.parse(localStorage.getItem('profile')!);
    this.loading = true;
    this.activateRoute.params.subscribe((params) => {
      this.taskService.getTaskReview(params['id']).subscribe((res: any) => {
        console.log('res', res);
        this.fullData = res;
        this.task = res.task;
        this.builder = res.builder;
        this.activities = res.activities;
        this.settlement = res.settlement;
        this.grade = res.grade.toFixed(2);
        this.technician = res.user;
        this.breadcrumbService.set('@TaskEdit', 'Resumen de la tarea ' + res.id);
        this.loading = false;
      });
    });
  }

  ngOnInit(): void {}

  async downloadCSV() {
    this.downloading = false;
    let options = {
      title: 'Reporte de tarea',
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      noDownload: false,
      showTitle: true,
      useBom: true,
      headers: [
        "# Tarea",
        "# Actividad",
        "Calificación",
        "Duración",
        "Peso",        
        "Condominio",
        "Edificio",
        "Unidad",
        "Técnico",
        "Lectura",
        "Fecha"
      ]
    };

    let stats: any = [];
    console.log('CONDOMINIO ===> ', this.settlement)
    await this.settlement.buildings.map((building: any) => {
      console.log('MAP building', building);
      building.units.map((unit: any) => {
        unit.stats.map((stat: any) => {
          stats.push({
            task_id: this.task.id,
            id: stat.id,
            grade: stat.grade,
            duration: stat.duration,
            weight: stat.weight,
            settlement_id: this.settlement.name,
            building: building.name,
            unit_id: unit.name,
            tecnico: `${this.technician.name} ${this.technician.last_name}`,
            lectura: stat.item.value,
            fecha: stat.item.created_at
          });
        });
      });
    });

    console.log('stats', stats);

    new ngxCsv(stats, 'Reporte', options);
    this.downloading = false;
  }

  loadImg(img: any) {
    if (img && img.blob) {
      return `${this.apiUrl}/storage/${img.blob.key.substring(0,2)}/${img.blob.key.substring(2,4)}/${img.blob.key}`;
    } else {
      return '';
    }
  }
}
