import { Component } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { faEdit, faPlus, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-services',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">        
        <div class="row mb-4 mt-4">
          <div class="col-md-5">
            <h1>Servicios</h1>
            <p>Configura los servicios asociados al condominio.</p>
            <xng-breadcrumb ></xng-breadcrumb>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" *ngIf="permissions?.modules.operation.services.write" routerLink="create">
            <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
              Agregar servicio</button>
          </div>
        </div>
        
            <div class="row">
              <div class="col-md-7">
                <!-- h2 class="mb-0">Habitacionales</!-->
              </div>
              <div class="col-md-5">
                <form [formGroup]="searchBox" (ngSubmit)="searchUtilities($event)">
                  <input class="form-control form-control-lg rounded-5 bg-transparent" placeholder="Buscar..." formControlName="search">
                </form>
              </div>
              <div class="col-md-12 mt-5 pb-3">
                <div class="list-group rounded-4 shadow" *ngIf="utilities.length>0">
                  <div class="list-group-item bg-light px-3 py-3" *ngFor="let service of utilities | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
                    <div class="row">
                      <div class="col-md-9 mt-1">
                        <h3 class="mb-0"><ngx-emoji emoji="hammer_and_wrench"></ngx-emoji> {{service.name}}</h3>
                        <!-- p class="mb-0 text-muted">{{service.description}}</!-->
                        <div class="badge badge-pill rounded-5 mb-0 px-3 py-1" [ngClass]="service.status == 'activo' ? 'bg-success' : 'bg-warning'">
                          <span class="text-capitalize">{{service.status}}</span>
                        </div>
                      </div>
                      <div class="col-md-3 text-end pt-1">
                        
                        <button class="btn btn-outline-primary" routerLink="{{service.id}}" *ngIf="permissions?.modules.operation.services.write">
                          <fa-icon [icon]="faEdit" size="1x"></fa-icon>
                          Editar
                        </button>
                      </div>
                    </div>
                  </div>                  
                </div>                
                <div class="list-group rounded-4 shadow" *ngIf="utilities.length==0">
                  <div class="list-group-item text-center py-5" *ngIf="loading">
                    <fa-icon [icon]="faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                    <p class="text-muted">Cargando...</p>
                  </div>
                  <div class="list-group-item text-center py-5" *ngIf="!loading">
                    <img src="/assets/images/empty-state.png" width="240px">
                    <h5 class="text-muted">No se encontraron registros</h5>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-end mt-4" *ngIf="utilities.length>0">
              <pagination-controls
                  (pageChange)="p = $event"
                  (pageChange)="pageChanged($event)"
                  previousLabel="Prev."
                  nextLabel="Sig."
                  screenReaderPaginationLabel="Pagination"
                  screenReaderPageLabel="page"                  
                ></pagination-controls>
              </div>
            </div>
          
      </div>
    </div>
  `,
  styleUrls: []
})
export class ServicesComponent {
  loading: boolean = false;
  utilities: any[] = [];
  meta: any = {};
  searchBox: FormGroup = new FormGroup({});
  profile: any = {};
  permissions: any = {};

  faEdit = faEdit;
  faPlus = faPlus;
  faCircleNotch = faCircleNotch;

  payload: any = {
    page: 1,
    per_page: 10,
  };

  p: number = 1;

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    private toastr: ToastrService,
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile_id = this.profile.id;
  }

  ngOnInit(): void {
    this.loadUtilities(this.payload);
    this.initForm();
  }

  loadUtilities(payload: any) {
    this.loading = true;
    this.store.getUtilities(payload).subscribe({
      next: (utilities: any) => {
        this.utilities = utilities.data;
        this.meta = utilities.meta;
        this.loading = false;
      },
      error: (err: any) => {
        console.log(err);
        this.loading = false;
        this.utilities = [];
        this.toastr.error('Ocurrió un error al obtener las amenidades.', 'Error');
      }
    });
  }

  initForm() {
    this.searchBox = this.fb.group({
      search: ['']
    })
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadUtilities(this.payload);
  }

  searchUtilities(event: any) {
    this.payload.page = 1;
    this.payload.name = this.searchBox.value.search;
    this.loadUtilities(this.payload);
  }
}
