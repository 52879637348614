import { AfterViewInit, Component, Input, SimpleChanges } from '@angular/core';
import { IconsService } from '../shared/services/icons.service';
import * as bs from 'bootstrap';
import { ImageService } from '../shared/services/image.service';
import { SettlementsService } from '../services/settlements.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: '[app-meter-unit]',
  template: `
    <div class="card rounded-5 bg-light shadow overflow-hidden mb-4" *ngIf="medidores">
      <div class="card-body bg-light p-4" *ngIf="medidores.length>0">
        <div class="row"  *ngFor="let medidor of medidores">
          <div class="col-8">
            <h5 class="mb-4"><span class="float-start mt-1">MEDIDOR ASIGNADO</span> </h5>
          </div>
          <div class="col-4">
            <div class="clearfix mb-4">
              <div class="badge badge-pill bg-primary rounded-5 float-end px-3" *ngIf="!medidor.maintenance">{{medidor.meter_status}}</div>
              <div class="badge badge-pill bg-warning rounded-5 float-end px-3" *ngIf="medidor.maintenance">En mantenimiento</div>
            </div>
          </div>
          <div class="col-4">
            <div class="rounded-3 overflow-hidden shadow-sm">
              <img [src]="last_meter" width="100%">
            </div>
          </div>
          <div class="col-8">
            <!-- app-item-tiny-gas-meter class="list-group-item" [meter]="medidor"></!-->
            <div class="row clearfix">
              <div class="col-4">
                <!-- ngx-qr-code [elementType]="medidor.meter_code"></!-->
                <p class="text-sm text-center">{{medidor.meter_code}}</p>
              </div>
              <div class="col-8">
                <h5 class="mb-0">{{medidor.name}}</h5>
                <h3 class="mb-0">{{medidor.brand}} {{medidor.meter_type}}</h3>
                <p class="text-muted text-sm mt-2 mb-0">Última lectura:</p>
                <p class="mb-0">{{medidor.last_read}}</p>
              </div>
            </div>
          </div>
          <div class="col-12 text-end mt-3">
            <hr>
            <button type="button" class="btn btn-outline-primary rounded-5" >Historial de mant.</button>
            <button type="button" class="btn btn-primary ms-2 rounded-5" (click)="editMedidor()">
              <fa-icon [icon]="icons.faEdit" class="me-2"></fa-icon>
              Editar
            </button>
            <button type="button" class="btn btn-outline-warning rounded-5 ms-2" *ngIf="!medidores[0].maintenance" (click)="addMaintenance()">
              <fa-icon [icon]="icons.faHammer" class="ms-2"></fa-icon>
              Mant.
            </button>
            <button type="button" class="btn btn-outline-danger rounded-5 ms-2" *ngIf="medidores[0].maintenance" (click)="removeMaintenance()">
              <fa-icon [icon]="icons.faHammer" class="ms-2"></fa-icon>
              Mant.
            </button>
          </div>
          <app-modal-gas-meter [unit]="unit" [meter]="medidores[0]" (updateGMeter)="updateGasMeter($event)"></app-modal-gas-meter>
          <app-modal-gas-meter-maintenance [meter]="medidores[0]"></app-modal-gas-meter-maintenance>
          <app-modal-gas-meter-maintenance-remove [meter]="medidores[0]"></app-modal-gas-meter-maintenance-remove>
        </div>
        
      </div>
      <div class="card-body bg-light p-4" *ngIf="medidores.length==0">
        <div class="row">
          <div class="col-8 text-start">
            <h5 class="mb-4">MEDIDOR ASIGNADO</h5>
          </div>
          <div class="col-12 my-3 text-center">
            <p>La unidad no tiene medidor asignado.</p>
            <button type="button" class="btn btn-lg btn-primary rounded-5" (click)="editMedidor()">
              <fa-icon [icon]="icons.faPlus" class="me-2"></fa-icon>Agregar medidor
            </button>
          </div>
        </div>
        <app-modal-gas-meter [unit]="unit"></app-modal-gas-meter>
      </div>
    </div>
  `,
  styles: []
})
export class MeterUnitComponent implements AfterViewInit {
  @Input() medidores: any;
  @Input() unit: any;
  @Input() permissions: any;

  last_meter: any;
  addingMedidor: boolean = false;

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    public icons: IconsService,
    private images: ImageService,
  ) { }

  ngAfterViewInit(): void {
    this.loadBillLatest(this.unit);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['unit'] && changes['unit'].currentValue) {
      this.loadBillLatest(changes['unit'].currentValue);
    }
  }

  loadBillLatest(unit: any) {
    console.log('UNIT CCCC======> ', unit);
    this.store.getBillLatest(unit.id).subscribe((data: any) => {
      console.log('LAST METER ======> ', data);
      if (data != null) {
        this.last_meter = this.images.buildURL(data.medidor);
      }
    });
  }

  addMaintenance() {
    new bs.Modal(<HTMLInputElement>document.getElementById('modalGasMeterMaintenance')).show();
  }

  removeMaintenance() {
    new bs.Modal(<HTMLInputElement>document.getElementById('modalGasMeterMaintenanceRemove')).show();
  }

  editMedidor() {
    new bs.Modal(<HTMLInputElement>document.getElementById('modalGasMeter')).show();
  }

  updateGasMeter(data: any) {
    // this.loadUnit(this.unit.id);
  }

}
