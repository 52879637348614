import { Component, OnInit } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-invoice-dashboard',
  template: `
    <div class="row">      
      <div class="col-sm-11 offset-sm-1 col-md-11 offset-md-1 col-lg-8 offset-lg-4 col-xl-8 offset-xl-3 mt-5">
        <div class="row mb-4">
          <div class="col-md-5">
            <h1>Facturación</h1>
          </div>
          <div class="col-md-7 mt-4 text-end">
          <!-- button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" routerLink="/condominios/create/condominio">
          <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
            Agregar
          </!-->
            <!-- button class="btn btn-primary btn-lg rounded-5 ms-3" routerLink="/bills/new">Crear recibo</!-->
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="row" *ngIf="settlementId == 0">
              <div class="col-md-8 mb-4">
                <h5 class="mb-0">PREFIL</h5>
                <h1>{{profile.name}}</h1>
              </div>
            </div>
            <div class="row" *ngIf="settlementId != 0">
              <div class="col-md-8">
                <h5 class="mb-0">Condominio</h5>
                <h1>{{settlement.name}}</h1>
              </div>
              <div class="col-md-4 text-end"></div>
              <div class="col-md-12">
                <p class="mb-2"><small>{{settlement.address}}, {{settlement.city}}, {{settlement.country}}</small></p>
                <p class="mb-0" style="font-size: 17px;"><small>{{settlement.email}}</small></p>
                <p class="mb-" style="font-size: 17px;"><small>{{settlement.phone}}</small></p>                
                <hr>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="">Condominio</label>
                  <select class="form-select form-select-lg bg-transparent" (change)="selectSettlement($event)">
                    <option selected [value]="0">Selecciona un condominio</option>
                    <option *ngFor="let settlement of settlements" [value]="settlement.id" [selected]="settlement.id == settlementId">{{settlement.name}}</option>
                  </select>
                </div>                
              </div>
              <div class="col-10">
                <div class="form-group mt-3">
                  <app-filter-advanced [toDate]="toDate" [fromDate]="fromDate" (dateRange)="updateDate($event)"></app-filter-advanced>
                </div>
              </div>
              <div class="col-2 mt-3 text-end">
                <button class="btn btn-primary rounded-3" (click)="searchBills()">
                  <fa-icon [icon]="icons.faSearch"></fa-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-5 offset-md-1">
            <div class="card border-0 rounded-5 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12 mb-3">
                    <h3>Resumen</h3>
                  </div>
                  <div class="col-md-6">
                    <h1 [countUp]="summary?.totals?.sum"></h1>
                    <p>Recibos del mes</p>
                  </div>
                  <div class="col-md-6">
                    <h1 [countUp]="summary?.unpaid?.sum"></h1>
                    <p>Recibos pendientes</p>
                  </div>
                  <div class="col-md-6">
                    <h1 [countUp]="summary?.paid?.sum"></h1>
                    <p>Recibos pagados</p>
                  </div>
                  <div class="col-md-6">
                    <h1 [countUp]="summary?.invoiced_upaid?.sum"></h1>
                    <p>Recibos facturados</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card border-0 rounded-5 shadow mt-3 mb-3">
              <div class="card-body p-3">
                <div class="row">
                  <div class="col-2">
                    <h4 class="mt-1 ms-3 mb-0">Reportes</h4>
                    <!-- p class="mb-0 ms-3 text-muted text-sm">Facturación</!-->
                  </div>
                  <div class="col-2">
                    <app-report-invoices-canceled [profile]="profile"></app-report-invoices-canceled>
                  </div>
                  <div class="col-2">
                    <app-report-invoices-ingreso [profile]="profile"></app-report-invoices-ingreso>
                  </div>
                  
                  <div class="col-2">
                    <app-report-invoices-global [profile]="profile"></app-report-invoices-global>
                  </div>
                  <div class="col-2">
                    <app-report-invoices-notas [profile]="profile"></app-report-invoices-notas>
                  </div>
                  <div class="col-2">
                    <app-report-invoices-generic [profile]="profile"></app-report-invoices-generic> 
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8 mt-4 mb-5">
            <div class="card border-0 rounded-5 shadow">
              <div class="card-body p-5">
                <div class="row">
                  <div class="col-md-12">
                    <h3 class="mt-3">Recibos no emitidos</h3>
                    <table class="table mt-3">
                      <tr class="border-bottom">
                        <th>Sub-total</th>
                        <td class="text-end"><span *ngIf="loading"><fa-icon [icon]="icons.faCircleNotch" [spin]="true"></fa-icon></span><span *ngIf="!loading">{{summary?.unpaid?.sub_total | currency}}</span></td>
                      </tr>
                      <tr class="border-bottom">
                        <th>I.V.A.</th>
                        <td class="text-end"><span *ngIf="loading"><fa-icon [icon]="icons.faCircleNotch" [spin]="true"></fa-icon></span><span *ngIf="!loading">{{summary?.unpaid?.taxes | currency}}</span></td>
                      </tr>
                      <tr class="border-bottom">
                        <th>Total</th>
                        <td class="text-end"><span *ngIf="loading"><fa-icon [icon]="icons.faCircleNotch" [spin]="true"></fa-icon></span><span *ngIf="!loading">{{summary?.unpaid?.total | currency}}</span></td>
                      </tr>
                    </table>
                    <h3 class="mt-3">Recibos pendientes de pago</h3>
                    <table class="table mt-3">
                      <tr class="border-bottom">
                        <th>Sub-total</th>
                        <td class="text-end"><span *ngIf="loading"><fa-icon [icon]="icons.faCircleNotch" [spin]="true"></fa-icon></span><span *ngIf="!loading">{{summary?.pending?.sub_total | currency}}</span></td>
                      </tr>
                      <tr class="border-bottom">
                        <th>I.V.A.</th>
                        <td class="text-end"><span *ngIf="loading"><fa-icon [icon]="icons.faCircleNotch" [spin]="true"></fa-icon></span><span *ngIf="!loading">{{summary?.pending?.taxes | currency}}</span></td>
                      </tr>
                      <tr class="border-bottom">
                        <th>Total</th>
                        <td class="text-end"><span *ngIf="loading"><fa-icon [icon]="icons.faCircleNotch" [spin]="true"></fa-icon></span><span *ngIf="!loading">{{summary?.pending?.total | currency}}</span></td>
                      </tr>
                    </table>
                    <h3 class="mt-3">Recibos pagados</h3>
                    <table class="table mt-3">
                      <tr class="border-bottom">
                        <th>Sub-total</th>
                        <td class="text-end"><span *ngIf="loading"><fa-icon [icon]="icons.faCircleNotch" [spin]="true"></fa-icon></span><span *ngIf="!loading">{{summary?.paid?.sub_total | currency}}</span></td>
                      </tr>
                      <tr class="border-bottom">
                        <th>I.V.A.</th>
                        <td class="text-end"><span *ngIf="loading"><fa-icon [icon]="icons.faCircleNotch" [spin]="true"></fa-icon></span><span *ngIf="!loading">{{summary?.paid?.taxes | currency}}</span></td>
                      </tr>
                      <tr class="border-bottom">
                        <th>Total</th>
                        <td class="text-end"><span *ngIf="loading"><fa-icon [icon]="icons.faCircleNotch" [spin]="true"></fa-icon></span><span *ngIf="!loading">{{summary?.paid?.total | currency}}</span></td>
                      </tr>
                    </table>

                    <h2>Totales</h2>
                    <table class="table mt-3">
                      <tr class="border-bottom">
                        <th>Sub-total</th>
                        <td class="text-end"><span *ngIf="loading"><fa-icon [icon]="icons.faCircleNotch" [spin]="true"></fa-icon></span><span *ngIf="!loading">{{summary?.totals?.sub_total | currency}}</span></td>
                      </tr>
                      <tr class="border-bottom">
                        <th>I.V.A.</th>
                        <td class="text-end"><span *ngIf="loading"><fa-icon [icon]="icons.faCircleNotch" [spin]="true"></fa-icon></span><span *ngIf="!loading">{{summary?.totals?.taxes | currency}}</span></td>
                      </tr>
                      <tr class="border-bottom">
                        <th>Total</th>
                        <td class="text-end"><span *ngIf="loading"><fa-icon [icon]="icons.faCircleNotch" [spin]="true"></fa-icon></span><span *ngIf="!loading">{{summary?.totals?.total | currency}}</span></td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-12 mt-4">
                    <hr>
                    <h3 class="mt-3">Facturas pagadas</h3>
                    <table class="table mt-3">
                      <tr class="border-bottom">
                        <th>Sub-total</th>
                        <td class="text-end"><span *ngIf="loading"><fa-icon [icon]="icons.faCircleNotch" [spin]="true"></fa-icon></span><span *ngIf="!loading">{{summary?.invoiced_paid?.sub_total | currency}}</span></td>
                      </tr>
                      <tr class="border-bottom">
                        <th>I.V.A.</th>
                        <td class="text-end"><span *ngIf="loading"><fa-icon [icon]="icons.faCircleNotch" [spin]="true"></fa-icon></span><span *ngIf="!loading">{{summary?.invoiced_paid?.taxes | currency}}</span></td>
                      </tr>
                      <tr class="border-bottom">
                        <th>Total</th>
                        <td class="text-end"><span *ngIf="loading"><fa-icon [icon]="icons.faCircleNotch" [spin]="true"></fa-icon></span><span *ngIf="!loading">{{summary?.invoiced_paid?.total | currency}}</span></td>
                      </tr>
                    </table>
                    <h3 class="mt-3">Facturas no pagadas</h3>
                    <table class="table mt-3">
                      <tr class="border-bottom">
                        <th>Sub-total</th>
                        <td class="text-end"><span *ngIf="loading"><fa-icon [icon]="icons.faCircleNotch" [spin]="true"></fa-icon></span><span *ngIf="!loading">{{summary?.invoiced_upaid?.sub_total | currency}}</span></td>
                      </tr>
                      <tr class="border-bottom">
                        <th>I.V.A.</th>
                        <td class="text-end"><span *ngIf="loading"><fa-icon [icon]="icons.faCircleNotch" [spin]="true"></fa-icon></span><span *ngIf="!loading">{{summary?.invoiced_upaid?.taxes | currency}}</span></td>
                      </tr>
                      <tr class="border-bottom">
                        <th>Total</th>
                        <td class="text-end"><span *ngIf="loading"><fa-icon [icon]="icons.faCircleNotch" [spin]="true"></fa-icon></span><span *ngIf="!loading">{{summary?.invoiced_upaid?.total | currency}}</span></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 mt-4">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12" id="timbres">
                    <h3>Timbres</h3>
                    <table class="table table-ms">
                      <tr>
                        <th>Utilizados</th>
                        <td class="text-end">
                          <h1 [countUp]="status[0].value"></h1>
                        </td>
                      </tr>
                      <tr>
                        <th>Restantes</th>
                        <td class="text-end">
                          <h1 [countUp]="status[1].value"></h1>
                        </td>
                      </tr>
                    </table>
                    <!-- ngx-charts-bar-vertical
                      [view]="[timbreChart[0],timbreChart[1]]"
                      [results]="status"
                      [xAxis]="false"
                      [yAxis]="false"
                      [legend]="false"
                    ></!-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class InvoiceDashboardComponent implements OnInit {
  profile: any = {};
  permissions: any = {};
  loading: boolean = false;

  summary: any = {};

  timbreChart: any[] = [];
  status: any = [
    {
      name: 'timbresUtilizados',
      value: 0
    },
    {
      name: 'creditosRestantes',
      value: 0
    },
  ];

  a = new Date();
  fromDate; // : any = "2023/12/01"; // Date = new Date();
  toDate; // : any = "2024/02/02"; // Date = new Date();

  settlementId: any = 0;
  settlement: any = {};
  settlements: any = [];

  payload: any = {
    created_at_since: '',
    created_at_to: '',
    profile_id: '',
  };

  constructor(
    private store: SettlementsService,
    public icons: IconsService,
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.fromDate = new NgbDate(this.a.getFullYear(), this.a.getMonth(), 1);
    this.toDate = new NgbDate(this.a.getFullYear(), this.a.getMonth()+1, 29);

    this.payload.created_at_since = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`;
    this.payload.created_at_to = `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`;
    this.payload.profile_id = this.profile.id;
  }

  ngOnInit(): void {
    let timbres = document.getElementById('timbres');
    this.timbreChart = [Number(timbres?.offsetWidth), 200];
    this.loadStatus();
    this.loadProfile();
    this.loadBillsPaidSummary(this.payload);
    this.loadSettlement(this.settlementId);
    this.loadSettlements();
  }

  updateDate(event: any) {
    this.fromDate = event.from;
    this.toDate = event.to;
    console.log('DATE RANGE ====> ', this.fromDate, this.toDate);
  }

  searchBills() {
    this.payload.created_at_since = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`;
    this.payload.created_at_to = `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`;
    this.loadBillsPaidSummary(this.payload);
  }

  loadStatus() {
    this.store.getInvoiceStatus(this.profile.id).subscribe((status: any) => {
      console.log("status account ===> ", status);
      this.status[0].value = status.timbresUtilizados;
      this.status[1].value = status.creditosRestantes;
    });

    console.log("status account ===> ", this.status);
  }

  selectSettlement(e: any) {
    this.settlementId = e.target.value;
    this.loadBillsPaidSummary(this.payload);
    this.loadSettlement(this.settlementId);
  }

  loadSettlements() {
    this.store.getSettlements({
      per_page: 1000,
      page: 1,
      utility_id: this.profile.utility_id
    }).subscribe((stls: any) => {
      this.settlements = stls.data;
    });
  }

  loadSettlement(id: any) {
    if (id == 0) return;
    this.store.getSettlement(id).subscribe((stl: any) => {
      this.settlement = stl;
    });
  }

  loadProfile() {
    this.store.getProfile(this.profile.id).subscribe((profile: any) => {
      this.profile = profile;
    });
  }

  loadBillsPaidSummary(payload: any) {
    this.loading = true;
    

    if (this.settlementId != 0) {
      payload.settlement_id = this.settlementId;
    }

    this.store.getBillsByUtilitySummary(payload).subscribe((summary: any) => {
      this.summary = summary;
      this.loading = false;
    });
  }
}
