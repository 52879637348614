import { Component, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { TasksService } from 'src/app/services/tasks.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-modal-skills',
  template: `
    <div #modalSkills id="modalSkills" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalSkills" aria-hidden="true">
      <div class="modal-dialog modal-dialog-top">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Habilidades</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" class="btn btn-link text-dark">
              <fa-icon [icon]="faTimes"></fa-icon>
            </button>
          </div>
          <div class="modal-body">
            <div class="list-group shadow-sm">
              <div class="list-group-item" *ngFor="let skill of skills">
                <div class="form-check form-switch ps-1">
                  <input
                    class="form-check-input float-end"
                    type="checkbox"
                    [checked]="skill.checked"
                    [value]="skill.id"
                    (change)="toggleSkill($event)"
                  >
                  <label class="form-check-label" for="flexCheckDefault">
                    {{skill.name}}
                  </label>
                </div>
              </div>              
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ModalSkillsComponent implements OnInit {
  @Input() technician: any;
  @Input() profile: any;

  skills: any = [];
  asigned_skills: any = [];

  faTimes = faTimes;

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
    private store: SettlementsService,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private tasks: TasksService,
  ) { }

  ngOnInit(): void {
    this.loadSkills();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['technician'] && changes['technician'].currentValue) {
      this.loadAsignedSkills();
    }
  }

  loadSkills() {
    this.tasks.getSkills({ profile_id: this.profile.profile.id }).subscribe({
      next: (resp: any) => {
        this.skills = resp.data;
      },
      error: (err) => {
        this.toastr.error('Ocurrió un error al obtener las habilidades.');
        console.log(err);
      }
    });
  }

  loadAsignedSkills() {
    if (!this.technician.id) return;
    this.tasks.getUserSkills(this.technician.id).subscribe({
      next: (resp: any) => {
        this.asigned_skills = resp;
        this.asigned_skills.map((skill_asigned: any) => {
          this.skills.map((skill: any) => {
            skill.checked = skill.id == skill_asigned.skill_id ? true : false;
          });
        });
      },
      error: (err) => {
        this.toastr.error('Ocurrió un error al obtener las habilidades.');
        console.log(err);
      }
    });
  }

  toggleSkill(event: any) {
    let currSkill: any = this.skills.filter((x: any) => x.id == event.target.value)[0];
    console.log("TOGGLE SKILL ", currSkill);
    if (currSkill.checked) {
      this.removeSkill(currSkill);
    } else {
      this.addSkill(currSkill);
    }
  }

  addSkill(currSkill: any) {
    this.tasks.addUserSkill({ user_id: this.technician.id, skill_id: currSkill.id }).subscribe({
      next: (resp: any) => {
        currSkill.checked = !currSkill.checked;
        this.toastr.success('Se agregó la habilidad correctamente.');
      },
      error: (err) => {
        this.toastr.error('Ocurrió un error al agregar la habilidad.');
        console.log(err);
      }
    });
  }

  removeSkill(currSkill: any) {
    console.log("REMOVE SKILL ", currSkill)
    // this.tasks.removeUserSkill({ user_id: this.technician.id, skill_id: event.target.value }).subscribe({
      // next: (resp: any) => {
        // console.log(resp);
        // this.toastr.success('Se removió la habilidad correctamente.');
      // },
      // error: (err) => {
        // this.toastr.error('Ocurrió un error al eliminar la habilidad.');
        // console.log(err);
      // }
    // });
  }
}
