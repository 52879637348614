import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-search-tenants',
  template: `
    <div class="row">
      <div class="col-12">
        <form [formGroup]="searchForm" (ngSubmit)="searchUsers()">
          <div class="form-group">
            <input type="text" class="form-control form-control-lg" placeholder="Buscar inquilino" formControlName="search">
          </div>
        </form>
      </div>
      <div class="col-12 mt-3 mb-3">
        <div class="list-group" *ngIf="users.length>0">
          <div class="list-group-item clearfix" *ngFor="let tenant of users">
            <p class="mb-0 float-start">{{tenant.name}}</p>
            <button class="btn btn-outline-info btn-sm float-end" (click)="addTenant(tenant)">
              <fa-icon [icon]="icons.faPlus"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class SearchTenantsComponent {

  searchForm: FormGroup = new FormGroup({});
  users: any[] = [];

  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    public icons: IconsService
  ) { }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      search: ['']
    })
  }

  searchUsers() {
    this.store.searchInquilinos(this.searchForm.value.search).subscribe((users: any) => {
      console.log('users ==> ', users);
      this.users = users.data;
    });
  }

  addTenant(tenant: any) {
    console.log('tenant ==> ', tenant);
  }
}
