import { Component } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { faEdit, faPlus, faTrashAlt, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BreadcrumbService } from 'xng-breadcrumb';
import { TasksService } from 'src/app/services/tasks.service';

@Component({
  selector: 'app-activity-index',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
        <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-5">
            <h1>Actividades</h1>
            <p>Configura las actividades asociadas al servicio técnico.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" *ngIf="permissions?.modules.operation.service.activities.write" routerLink="create">
              <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
              Agregar actividad
            </button>
          </div>
        </div>
        <div class="card border-0 rounded-4 shadow">
          <div class="card-body p-4">
            <div class="row mb-4">
            <div class="col-md-7">
                <!-- h2 class="mb-0">Habitacionales</!-->
              </div>
              <div class="col-md-5">
                <form [formGroup]="searchBox" (ngSubmit)="searchCategories($event)">
                  <input class="form-control form-control-lg" placeholder="Buscar..." formControlName="search">
                </form>
              </div>
            </div>
            <div class="col-md-12 mt-5 pb-3">
              <div class="list-group rounded-4 shadow" *ngIf="categories.length>0">
                <div class="list-group-item bg-light px-3 py-3" *ngFor="let amenity of categories | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
                  <div class="row">
                    <div class="col-md-8">
                      <h4 class="mb-0">{{amenity.name}}</h4>
                      <!-- p class="mb-0">{{amenity.settlement.name}}</!-->
                      <p class="mb-0 text-sm text-muted">Actualizado: {{amenity.updated_at | date: 'dd/MM/yyyy HH:mm'}}</p>
                    </div>
                    <div class="col-md-4 clearfix">
                      
                      <div class="float-end">
                        <!-- div class="badge rounded-pill me-3 px-3 py-2 border border-3" [ngClass]="amenity.status == 'active' ? 'border-success text-success' : 'border-warning text-warning'">
                          <p class="mb-0 text-capitalize">{{amenity.status}}</p>
                        </!-->
                        <button class="btn btn-outline-primary text-end" routerLink="{{amenity.id}}" *ngIf="permissions?.modules.operation.service.activities.write">
                          <fa-icon [icon]="faEdit" size="1x"></fa-icon>
                          Editar
                        </button>
                        <!-- button class="btn btn-link text-end" routerLink="/amenity/{{amenity.id}}">
                          <fa-icon [icon]="faTrashAlt" size="1x" class="text-danger"></fa-icon>
                        </!-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                <div class="list-group rounded-4 shadow" *ngIf="categories.length==0">
                  <div class="list-group-item text-center py-5" *ngIf="loading">
                    <fa-icon [icon]="faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                    <p class="text-muted">Cargando...</p>
                  </div>
                  <div class="list-group-item text-center py-5" *ngIf="!loading">
                    <img src="/assets/images/empty-state.png" width="240px">
                    <h5 class="text-muted">No se encontraron registros</h5>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-end mt-4" *ngIf="categories.length>0">
                <pagination-controls
                  (pageChange)="p = $event"
                  (pageChange)="pageChanged($event)"
                  previousLabel="Prev."
                  nextLabel="Sig."
                  screenReaderPaginationLabel="Pagination"
                  screenReaderPageLabel="page"                  
                ></pagination-controls>
              </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ActivityIndexComponent {
  loading: boolean = false;
  categories: any[] = [];
  meta: any = {};
  searchBox: FormGroup = new FormGroup({});
  profile: any = {};
  permissions: any = {};

  faPlus = faPlus;
  faEdit = faEdit;
  faCircleNotch = faCircleNotch;

  payload: any = {
    page: 1,
    per_page: 10,
  };

  p: number = 1;

  constructor(
    private tasks : TasksService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private breadcrumbService: BreadcrumbService,
  ) { 
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile_id = this.profile.id;
  }

  ngOnInit(): void {
    this.loadCategories(this.payload);
    this.initForm();
    this.breadcrumbService.set('@amenidad', 'Amenidades');
  }

  initForm() {
    this.searchBox = this.fb.group({
      search: ['']
    });
  }

  searchCategories(event: any) {
    this.payload.page = 1;
    this.payload.name = this.searchBox.value.search;
    this.loadCategories(this.payload);
  }

  loadCategories(payload: any) {
    this.loading = true;
    this.tasks.getActivities(payload).subscribe({
      next: (categories: any) => {
        this.categories = categories.data;
        this.meta = categories.meta;
        this.loading = false;
      },
      error: (err: any) => {
        console.log(err.status);
        this.loading = false;
      }
    });
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadCategories(this.payload);
  }
}
