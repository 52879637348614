import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { ImageService } from 'src/app/shared/services/image.service';

@Component({
  selector: 'app-refuel-show',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
        <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4">
          <div class="col-md-9">
            <h1>Suministro de combustible</h1>
            <p>Configura las suministro que están disponibles para los departamentos.</p>
          </div>
          <div class="col-md-3 text-end mt-4">
            
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <div class="card border-0 rounded-5 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h5>Tanque suministrado</h5>
                  </div>
                </div>
              </div>
            </div>

            <div class="card border-0 rounded-5 shadow mt-4">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h5>Ubicación</h5>
                  </div>
                </div>
              </div>
            </div>

            <div class="card border-0 rounded-5 shadow mt-4">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h5>Orden de suministro</h5>
                  </div>
                  <div class="col-12">
                    <h3>{{refuel.folio}}</h3>
                    <p>Litros solicitados: {{refuel.liters_po}}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="card border-0 rounded-5 shadow mt-4">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h5>Detalle del suministro</h5>
                    <!-- p>Configura la amenidad con los siguientes campos.</!-- -->
                    <div class="form-group mt-4">
                      <label for="folio" class="form-control-label">Folio</label>
                      <input type="text" class="form-control" [(ngModel)]="refuel.folio" readonly>
                    </div>
                    <div class="form-group mt-4">
                      <label for="">Lecutra Pre carga %</label>
                      <input type="text" class="form-control" [(ngModel)]="refuel.pre_charge" readonly>
                    </div>
                    <div class="form-group mt-4">
                      <label for="">Lectura post carga %</label>
                      <input type="text" class="form-control" [(ngModel)]="refuel.post_charge" readonly>
                    </div>
                    <div class="form-group mt-4 mb-4">
                      <label for="">Litros cargados</label>
                      <input type="text" class="form-control" [(ngModel)]="refuel.liters" readonly>
                    </div>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="card border-0 rounded-5 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h4>Evidencia de la recarga</h4>
                  </div>
                  <div class="col-md-12 mt-3">
                    <div class="form-group">
                      <h5>Pre carga</h5>
                      <img [src]="refuel.pre_charge_image" width="100%">
                    </div>
                  </div>
                  <div class="col-md-12 mt-3">
                    <div class="form-group">
                      <h5>Post carga</h5>
                      <img [src]="refuel.post_charge_image" width="100%">
                    </div>
                  </div>
                  <div class="col-md-12 mt-3">
                    <div class="form-group">
                      <h5>Ticket</h5>
                      <img [src]="refuel.ticket_image" width="100%">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class RefuelShowComponent {
  loading: boolean = false;
  refuel: any = {};

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    public icons: IconsService,
    public images: ImageService
  ) {
    const ctl = this;
    this.loading = true;
    this.activateRoute.params.subscribe(params => {
      this.store.getRefuel(params['id']).subscribe({
        next: (res: any) => {
          this.loading = false;
          
          res.post_charge_image = this.images.buildURL(res.post_charge_image);
          res.pre_charge_image = this.images.buildURL(res.pre_charge_image);
          res.ticket_image = this.images.buildURL(res.ticket_image);

          console.log("RES REFUEL =>", res);
          ctl.refuel = res;
        },
        error: (err: any) => {
          console.log(err);
          this.loading = false;
          this.toastr.error('Ocurrió un error al obtener la amenidad.', 'Error');
        }
      });
    });
  }
}
