import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { TasksService } from 'src/app/services/tasks.service';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-skill-edit',
  template: `
    <div class="row">
    <div class="col-sm-12 offset-sm-0 col-lg-6 offset-lg-3 col-xl-6 offset-xl-3 mt-4">
    <xng-breadcrumb></xng-breadcrumb>
      <div class="row mb-4 mt-4">
        <div class="col-md-8">
          <h1>Habilidad</h1>
          <p>Configura las habilidades que están disponibles para los técnicos.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-7">
          <div class="card border-0 rounded-4 shadow">
            <div class="card-body p-4">
              <div class="row">
                <div class="col-md-12">
                  <h4>Editar habilidad</h4>
                  <p>Configura la habilidad con los siguientes campos.</p>
                  <app-form-skill (skillData)="updateSkill($event)" [profile]="profile" [skill]="skill" [loading]="loading"></app-form-skill>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  `,
  styleUrls: []
})
export class SkillEditComponent {
  skill: any = null;
  loading: boolean = false;
  profile: any = {};

  constructor(
    private store: SettlementsService,
    private tasks: TasksService,
    private toastr: ToastrService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
  ) {}

  ngOnInit(): void {
    this.profile = JSON.parse(localStorage.getItem('profile')!);
    this.breadcrumbService.set('@skillCreate', 'Editar habilidad');
    this.loading = true;
    this.activateRoute.params.subscribe(params => {
      this.tasks.getSkill(params['id']).subscribe({
        next: (res: any) => {
          this.loading = false;
          this.skill = res;
          this.breadcrumbService.set('@skillEdit', this.skill.name);
        },
        error: (err: any) => {
          console.log(err);
          this.loading = false;
          this.toastr.error('Ocurrió un error al obtener la habilidad.', 'Error');
        }
      });
    });
  }

  updateSkill(event: any) {
    console.log("updateSkill ", event);

    this.tasks.updateSkill(this.skill.id, event).subscribe({
      next: (res: any) => {
        this.toastr.success('La habilidad se actualizó correctamente.', 'Actualizado');
        this.router.navigate(['/inventory/skills']);
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al actualizar la habilidad.', 'Error');
      }
    });
  }
}
