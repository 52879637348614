import { Component, ViewChild } from '@angular/core';
import { faTimes, faPlus, faEdit, faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import * as bs from 'bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TasksService } from 'src/app/services/tasks.service';

@Component({
  selector: 'app-holidays',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
        <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-5">
            <h1>Días no laborales</h1>
            <p>Configura los días no laborales.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" *ngIf="permissions?.modules.operation.service.holidays.write" (click)="addHoliday()">
              <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
              Agregar día no laboral
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row mb-4">
                  <div class="col-md-12">
                    <div class="list-group">
                      <div class="list-group-item list-group-item-action" *ngFor="let holiday of holidays">
                        <div class="row">
                          <div class="col-md-4">{{holiday.holiday}}</div>
                          <div class="col-md-4"></div>
                          <div class="col-md-4 text-end">
                            <button class="btn btn-outline-danger btn-sm rounded-3" *ngIf="permissions?.modules.operation.service.holidays.write" (click)="deleteHoliday(holiday)">
                              <fa-icon [icon]="faTimes" class="me-2"></fa-icon>
                              Eliminar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-end mt-4" >
            <pagination-controls
              (pageChange)="p = $event"
              (pageChange)="pageChanged($event)"
              previousLabel="Prev."
              nextLabel="Sig."
              screenReaderPaginationLabel="Pagination"
              screenReaderPageLabel="page"
              *ngIf="holidays.length>0"
            ></pagination-controls>
          </div>
        </div>        
      </div>      
    </div>
    <app-modal-holiday (holidayData)="createHoliday($event)"></app-modal-holiday>
  `,
  styleUrls: []
})

export class HolidaysComponent {
  @ViewChild('modalHolidays') modalHolidays: any;

  profile: any = {};
  permissions: any = {};
  holidays: any = [];
  meta: any = {};
  faPlus = faPlus;
  payload: any = {
    profile_id: 1,
    page: 1,
    per_page: 10,
  };

  faTimes = faTimes;

  p: number = 1;
  
  constructor(
    private taskService: TasksService,
    private toastr: ToastrService,
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile_id = this.profile.id;
  }

  ngOnInit(): void {
    this.loadHolidays(this.payload);
  }

  addHoliday() {
    new bs.Modal(<HTMLInputElement>document.getElementById('modalHolidays')).show();
  }

  loadHolidays(payload: any = {}) {
    this.taskService.getHolidays(payload).subscribe((res: any) => {
      console.log(res);
      this.holidays = res.data;
    });
  }

  createHoliday(event: any) {
    console.log(event);
    this.payload.holiday = event.fecha;
    this.taskService.createHoliday({
      profile_id: this.profile.profile.id,
      holiday: event.fecha    
    }).subscribe((res: any) => {
      console.log(res);
      this.toastr.success('Día no laboral creado.');
      window.location.reload();
    });
  }

  pageChanged(event: any) {
    this.payload.page = event;    
  }

  deleteHoliday(holiday: any) {
    console.log(holiday);
    this.taskService.deleteHoliday(holiday.id).subscribe((res: any) => {
      console.log(res);
      this.toastr.success('Día no laboral eliminado.');
      window.location.reload();
    });
  }
}
