import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import * as bs from 'bootstrap';

import { Tenant } from 'src/app/models/tenant.model';
import { Unit } from 'src/app/models/unit.model';
import { Building } from 'src/app/models/building.model';
import { Settlement } from 'src/app/models/settlement.model';
import { Floor } from 'src/app/models/floor.model';

import { faCircleNotch, faChevronLeft, faEllipsisVertical, faTrashAlt, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import { BreadcrumbService } from 'xng-breadcrumb';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-bills-tenants',
  template: `
    <div class="row">
  <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-4">
    <div class="row mb-4">
      <xng-breadcrumb ></xng-breadcrumb>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-8">
            <h5 class="mb-0">Condominio</h5>
            <h1>{{settlement.name}}</h1>
          </div>
          <div class="col-md-4 text-end">
            <button type="button" class="btn btn-link" routerLink="/condominios/edit/{{settlement.id}}">Editar</button>
          </div>
          <div class="col-md-12">
            <p class="mb-2"><small>{{settlement.address}}, {{settlement.city}}, {{settlement.country}}</small></p>
            <p class="mb-0" style="font-size: 17px;"><small>{{settlement.email}}</small></p>
            <p class="mb-" style="font-size: 17px;"><small>{{settlement.phone}}</small></p>                
            <hr>
          </div>
          <div class="col-md-5">
            <!-- button type="button" class="btn btn-link" (click)="addBuilding()">
              + Agregar edificio
            </!-->
          </div>
          <div class="col-md-7">
            <select class="form-select form-select-lg" (change)="selectBuilding($event)">
              <option [value]="null" disabled >Selecciona un edificio</option>
              <option *ngFor="let building of buildings" [value]="building.id" [selected]="building.id==settlementId">{{building.name}}</option>
            </select>            
          </div>
          <div class="col-md-12">
            <hr>
            <div class="card rounded-4 shadow">
              <div class="card-body py-4 px-3">
                <div class="col-md-6 mt-4">
                  <h5>Piso(s)</h5>
                </div>
                <div class="list-group rounded-4 bg-light mt-2" *ngIf="building.floors?.length>0">
                  <div class="list-group-item list-group-item-light ps-2" *ngFor="let floor of building.floors">
                    Piso {{floor.name}}
                    <div class="list-group rounded-4 bg-light mt-2" *ngIf="floor.units.length>0">
                      <div class="list-group-item list-group-item-light ps-2" *ngFor="let unit of floor.units">                      
                        <app-building-units [unit]="unit" (unitData)="selectUnit($event)"></app-building-units>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group-item" *ngIf="building.floors?.length==0">
                  <p class="mt-3 mb-3 text-center">No hay registros aún.</p>
                </div>
                <div class="col-md-6 mt-4">
                  <h5>Tanque(s)</h5>
                </div>

                <div class="col-md-6 mt-3 text-end" *ngIf="selectedBuilding.tanques">
                  <!-- button type="button" class="btn btn-sm btn-outline-info" *ngIf="selectedBuilding.tanques.length==0" (click)="addTank(building)"><fa-icon [icon]="faPlus"></fa-icon></!-button -->
                </div>
                <div class="col-12">
                  <hr class="mt-1">
                </div>
                <div class="col-md-12 mt-2">
                  <div class="list-group rounded-3">
                    <div class="list-group-item p-2" *ngFor="let tanque of selectedBuilding.tanques">
                      <ngx-emoji emoji="alembic" [style]="{ bottom: '10px', right: '2px' }"></ngx-emoji> <b class="mt-0 ms-2">{{tanque.name}}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>        
      </div>
      <div class="col-md-5 offset-md-1">
        <div class="card rounded-5 shadow mb-4">
          <div class="card-body py-4 px-4">
            <div class="row">
              <div class="col-md-12 mb-3">
                <h5>Resumen</h5>
              </div>
              <div class="col-md-4">
                <p class="mb-0">Edificios</p>
                <h1 class="mb-0">{{buildings.length}}</h1>                
              </div>
              <div class="col-md-4">
                <p class="mb-0">Deptos.</p>
                <h1 class="mb-0">{{countUnits}}</h1>
              </div>
              <div class="col-md-4">
                <p class="mb-0">Tanques</p>
                <h1 class="mb-0">{{countTanques}}</h1>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="col-md-12 text-end">
              <button type="button" class="btn btn-link" routerLink="/condominios/{{settlement.id}}">Configuración</button>
            </div>
          </div>
        </div>
        <app-unit-details [unit]="selectedUnit"></app-unit-details>
    </div>
  </div>  
</div>
  `,
  styleUrls: []
})
export class BillsTenantsComponent {
  apiUrl: string = environment.AWS_REST_WSPREFIX;
  settlementId: number = 0;
  settlement: any = {};
  buildings: Building[] = [];
  building: any = null;
  utilities: any = [];

  loading: boolean = false;
  loadingUtilities: boolean = false;
  selectedBuilding: any = {};

  countUnits: number = 0;
  countTanques: number = 0;

  buildingForm: FormGroup = new FormGroup({});
  floorForm: FormGroup = new FormGroup({});
  unitForm: FormGroup = new FormGroup({});

  addingUtility: boolean = false;
  addingFloor: boolean = false;
  addingUnit: boolean = false;

  faCircleNotch = faCircleNotch;
  faEllipsisVertical = faEllipsisVertical;
  faChevronLeft = faChevronLeft;
  faTrashAlt = faTrashAlt;
  faPlus = faPlus;
  faEdit = faEdit;

  selectedUnit: any = null;

  title = 'angular-text-search-highlight';
  searchText = '';
  characters = [
    'Ant-Man',
    'Aquaman',
    'Asterix',
    'The Atom',
    'The Avengers',
    'Batgirl',
    'Batman',
    'Batwoman',    
  ]
  
  constructor(
    private store : SettlementsService,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private breadcrumbService: BreadcrumbService,
  ) {
    this.activateRoute.params.subscribe(params => {
      this.settlementId = params['id'];
      console.log('settlementId => ', this.settlementId);
      this.store.getSettlement(this.settlementId).subscribe((settlement: any) => {
        this.settlement = settlement;
        this.breadcrumbService.set('@unitName', settlement.name);
      });
    });
  }
  
  ngOnInit(): void {
    // this.buildBuildingForm();
    this.loadBuilding(this.settlementId);
  }

  async loadBuilding(settlementId: number) {
    this.buildings = [];
    this.building = {};
    this.store.getBuildingsBySettlement(settlementId).subscribe({
      next: (data: any) => {
        this.buildings = data.sort((a: any, b: any) => a.id -b.id);
        this.loading = true;
        this.selectedBuilding = this.buildings[0];
        // this.breadcrumbService.set('@settlementName', `${this.settlement.name} / ${this.selectedBuilding.name}`); 
        this.buildings.map((building: any) => {
          building.floors.map((floor: any) => {
            this.countUnits += floor.units.length
          });
          this.countTanques += building.tanques.length;
        });
        this.setBuilding(this.buildings[0].id);
      },
      error: (e: any) => {
        console.log('error => ', e);
        this.toastr.error('Edificios no pudieron ser cargados.', 'Error');
        this.loading = false;
      }
    });
  }

  searchChanges(e: any) {
    
    console.log(e.target.value);
    // this.description = this.getDescription();

  }

  buildBuildingForm() {
    this.buildingForm = this.fb.group({
      name: ['', Validators.required],
      clave: ['', Validators.required],
    });
  }

  buildFloorForm() {
    this.floorForm = this.fb.group({
      name: ['', Validators.required],
      building_id: ['', Validators.required],
    });
  }

  buildUnitForm() {
    this.unitForm = this.fb.group({
      name: ['', Validators.required],
      number: [''],
      bedrooms: [''],
      bathrooms: [''],
      parking: [''],
      area: [''],
      unit_type_id: [''],
      floor_id: ['', Validators.required],
    });
  }

  selectBuilding(e: any) {
    this.setBuilding(e.target.value);
  }

  async setBuilding(id: any)  {
    this.building = {};
    this.loading = true;
    this.store.getBuilding(id).subscribe({
      next: (data: any) => {
        try {
          console.log('===== data => ', data);
          this.buildingForm.patchValue({
            name: data.name,
            clave: data.clave,
          });

          this.building.floors = data.floors.sort((a: any, b: any) => b.id -a.id);
          console.log("WAT ", id);
          

          // this.selectedBuilding = this.building;
          this.selectedBuilding = this.buildings.filter((x: any) => x.id == id)[0];
          // console.log(selected);


          this.building = data;
          this.loading = false;
        } catch (error) {
          this.loading = false;
        }      
      },
      error: (error: any) => {
        this.toastr.error('Edificio no pudo ser cargado.', 'Error');
        this.loading = false;
      }
    });
  }

  selectUnit(e: any) {
    console.log('selectUnit => ', e);
    this.selectedUnit = e;
  }

  

  getTenantAvatar(tenant: any) {
    if (tenant.avatar) {
      return `${this.apiUrl}/storage/${tenant.avatar.blob.key.substring(0,2)}/${tenant.avatar.blob.key.substring(2,4)}/${tenant.avatar.blob.key}`;
    } else {
      return 'assets/images/avatars/default-avatar.png';
    }
  }

  get bfc() {
    return this.buildingForm.controls;
  }

  get ffc() {
    return this.floorForm.controls;
  }

  get ufc() {
    return this.unitForm.controls;
  }

  addFloor() {
    if (this.floorForm.invalid) {
      this.floorForm.markAllAsTouched();
      this.toastr.error('Formulario inválido.', 'Error');
      return;
    }

    this.store.createFloor(this.floorForm.value).subscribe({
      next: (data: any) =>  {
        this.toastr.success('Piso creado con correctamente.', '¡Éxito!');
        this.loadBuilding(this.settlementId);
        this.addingFloor = false;
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Piso no pudo ser creado.', 'Error');
        this.addingFloor = false;
      }
    });
  }

  plusFloor() {
    console.log(this.selectedBuilding.id);
    this.buildFloorForm();
    this.addingFloor = true;
    this.floorForm.patchValue({
      building_id: this.selectedBuilding.id,
    })
  }

  cancelFloor() {
    this.addingFloor = false;
    this.floorForm.reset();
  }

  plusUtility() {
    this.addingUtility = true;
    if (this.utilities.length == 0) {
      this.loadingUtilities = true;

      const stockUtilites = [
        { id: 1, name: 'Gas', status: 'ok' }
      ];

      stockUtilites.map((utility: any, index: number) => {
        console.log(this.selectedBuilding);
        if (this.selectedBuilding.utilities.length > 0) {
          this.selectedBuilding.utilities.map((su: any) => {
            if (utility.name != su.name) this.utilities.push(utility);
          });
        } else {
          this.utilities = stockUtilites;
        }
      });

      this.loadingUtilities = false;


      // this.store.getUtilities().subscribe({
        // next: (data: any) => {
        // this.utilities = this.selectedBuilding.utilities.filter((obj1: any) => data.filter((obj2: any) => obj1.id != obj2.id))         
        // this.loadingUtilities = false;
        // },
        // error: (error: any) => {
          // console.log('error => ', error);
          // this.toastr.error('Servicios no pudieron ser cargados.', 'Error');
          // this.loadingUtilities = false;
        // }
      // });


    }    
  }

  addUtility(utility: any) {
    // TODO updateUtilitiy to building
    // 
    const payload: any = {
      name: utility.name,
      status: utility.status,
      building_id: this.selectedBuilding.id
    }
    console.log("=====>");
    // console.log();
    console.log(payload);
    this.store.createUtility(payload).subscribe({
      next: (data: any) => {
        this.loadBuilding(this.settlementId);
        this.addingUtility = false;
      },
      error: (err: any) => {
        console.log('error => ', err);
        this.toastr.error('Unidad no pudo ser creada.', 'Error');
      }
    })
  }

  removeUtility(utility: any) {
    this.store.destroyUtility(utility.id).subscribe({
      next: (data: any) => {},
      error: (err: any) => {}
    })
  }

  cancelUtility() {
    this.addingUtility = false;
    this.loadingUtilities = false;
  }

  addTank(building: Building) {}

  removeTank(tanque: Building) {
    this.store.destroyTanque(tanque.id).subscribe({
      next: (data: any) => {
        
      },
      error: (err: any) => {

      }
    })
  }

  selectUnitType(e: any) {
    console.log('selectUnitType => ', e.target.value);
    this.unitForm.patchValue({
      unit_type_id: e.target.value
    });
  }

  async plusUnit(floor: any) {
    this.addingUnit = true;
    this.buildUnitForm();
    console.log('floor => ', floor);
    this.unitForm.patchValue({
      floor_id: floor.id
    });
  }

  cancelUnit() {
    this.addingUnit = false;
    this.unitForm.reset();
  }

  createUnit() {
    console.log(this.unitForm.value);

    if (this.unitForm.invalid) {
      this.unitForm.markAllAsTouched();
      this.toastr.error('Formulario inválido.', 'Error');
      return;
    }

    this.store.createUnit(this.unitForm.value).subscribe({
      next: (data: any) =>  {
        this.toastr.success('Unidad se creó correctamente.', 'Éxito');
        this.addingUnit = false;
        this.unitForm.reset();
        this.setBuilding(this.selectedBuilding.id);
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Unidad no pudo ser creada.', 'Error');
      }
    });
  }

  updateBuilding() {
    this.loading = true;

    console.log(this.buildingForm.value);
    console.log(this.selectedBuilding.id);

    if (this.buildingForm.invalid) {
      this.buildingForm.markAllAsTouched();
      this.toastr.error('Formulario inválido.', 'Error');
      this.loading = false;
      return;
    }

    this.store.updateBuilding(this.building.id, this.buildingForm.value).subscribe({
      next: (data: any) =>  {
        this.toastr.success('Edificio se actualizó correctamente.', 'Éxito');
        this.loading = false;
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Edificio no pudo ser actualizado.', 'Error');
        this.loading = false;
      }
    })
  }

  addBuilding() {
    new bs.Modal(<HTMLInputElement>document.getElementById('modalBuilding')).show();
  }

  createBuilding(event: any) {
    console.log("CREATE BUILDING ===========");
    console.log(this.settlementId);
    console.warn(event);
    const payload = {
      name: event.name,
      clave: event.clave,
      settlement_id: this.settlementId,
    };

    this.store.createBuilding(payload).subscribe({
      next: (data: any) => {
        this.toastr.success('Edificio se creó correctamente.', 'Éxito');
        window.location.reload();
      },
      error: (err: any) => {
        this.toastr.error('Edificio no pudo ser creado.', 'Error');
      }
    });
  }
}
