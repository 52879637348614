import { Component } from '@angular/core';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-tasks-dashboard',
  template: `
    <div class="row">
    <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">    
      <div class="row mb-4 ">
        <div class="col-md-6">
          <h1>Tareas</h1>
          <p>Resumen de tareas</p>
          <xng-breadcrumb ></xng-breadcrumb>
        </div>
        <div class="col-md-6 text-end mt-2"> 
          <!-- button class="btn btn-outline-info btn-lg rounded-5 shadow me-3 border border-3 border-info" (click)="generateReport()">Reporte inventario</!-->
          <!-- button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" *ngIf="permissions?.modules.operation.combustibles.tanques.write" routerLink="create">
            <fa-icon [icon]="icons.faPlus" class="me-2"></fa-icon>
            Agregar tanque</!-->
        </div>
      </div>
      
      <div class="row mt-4 mb-5">
  `,
  styleUrls: []
})
export class TasksDashboardComponent {
  profile: any = {};
  permissions: any = {};

  constructor(
    public icons: IconsService
  ) {
    this.profile = JSON.parse(localStorage.getItem('profile') || '{}');
    this.permissions = JSON.parse(localStorage.getItem('permissions') || '{}');
  }

  ngOnInit(): void {
    
  }
}
