import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { TasksService } from 'src/app/services/tasks.service';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-tool-edit',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-6 offset-lg-3 col-xl-6 offset-xl-3 mt-4">
      <xng-breadcrumb></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-8">
            <h1>Herramienta</h1>
            <p>Configura la herramienta que están disponibles para los técnicos.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h4>Editar habilidad</h4>
                    <p>Configura la habilidad con los siguientes campos.</p>
                    <app-form-tool (toolData)="updateTool($event)" [profile]="profile" [tool]="tool" [loading]="loading"></app-form-tool>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ToolEditComponent implements OnInit {
  tool: any = null;
  loading: boolean = false;
  profile: any = JSON.parse(localStorage.getItem('profile') || '{}');

  constructor(
    private store: SettlementsService,
    private tasks: TasksService,
    private toastr: ToastrService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.set('@toolCreate', 'Editar herramienta');
    this.loading = true;
    this.activateRoute.params.subscribe(params => {
      this.tasks.getTool(params['id']).subscribe({
        next: (res: any) => {
          this.loading = false;
          this.tool = res;
          this.breadcrumbService.set('@toolEdit', this.tool.name);
        },
        error: (err: any) => {
          console.log(err);
          this.loading = false;
          this.toastr.error('Ocurrió un error al obtener la herramienta.', 'Error');
        }
      });
    });
  }

  updateTool(event: any) {
    this.tasks.updateTool(this.tool.id, event).subscribe({
      next: (res: any) => {
        this.toastr.success('La herramienta se actualizó correctamente.', 'Actualizado');
        this.router.navigate(['/inventory/tools']);
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al actualizar la herramienta.', 'Error');
      }
    });
  }
}
