import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-category-create',
  template: `
  <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
        <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-9">
            <h1>Actividades</h1>
            <p>Configura las actividades que están disponibles para los servicios técnicos.</p>
          </div>
          <div class="col-md-3 text-end mt-4">
            
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h4>Editar actividad</h4>
                    <p>Configura la actividad con los siguientes campos.</p>
                    <app-form-task-category (categoryData)="createCategory($event)" [loading]="loading"></app-form-task-category>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class CategoryCreateComponent implements OnInit {
  loading: boolean = false;

  constructor(
    private store: SettlementsService,
    private router: Router,
    private toastr: ToastrService,
    private breadcrumbService: BreadcrumbService,
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.set('@index', 'Mantenimientos');
    this.breadcrumbService.set('@categories', 'Categoría de tareas');
    this.breadcrumbService.set('@categoryCreate', 'Crear categoría');
  }


  createCategory(event: any) {
    this.store.createTaskCategory(event).subscribe({
      next: (resp: any) => {
        this.loading = false;
        this.toastr.success('Se ha creado la categoría correctamente.', 'Categoría creada');
        this.router.navigateByUrl('/maintenances/categories');
      },
      error: (err: any) => {
        this.loading = false;
        this.toastr.error('No se ha podido crear la categoría.', 'Error');
      }
    });
  }
}
