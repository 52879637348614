import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-tanque-edit',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
      <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-8">
            <h1>Editar tanque</h1>
            <p>Configura el tanque de gas instalado en el edificio del condominio.</p>
          </div>
          <!-- div class="col-md-4 text-end">
            <button class="btn btn-primary btn-lg rounded-5"  >Agregar tanque</button>
          </!-->
        </div>
        <div class="row">
          <div class="col-md-8">
            <div class="card rounded-5 bg-light">
              <div class="card-body p-4">
                <div class="row mt-0">
                  <div class="col-md-12">
                    <h2 class="card-title mb-4">
                      <ngx-emoji emoji="alembic" [style]="{ bottom: '10px', right: '2px' }"></ngx-emoji>
                      Datos tanque
                    </h2>
                    <div class="alert alert-info">
                      <p class="mb-0">Recuerda agregar más datos posibles ya que esto ayuda a la información estadística.</p>
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <app-form-tanque
                      [tanqueId]="tanqueId"
                      [profile]="profile"
                      [permissions]="permissions"
                      (updateForm)="editTanque($event)"
                      (cancelForm)="cancelEdit()"
                    ></app-form-tanque>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- div class="col-md-4">
            <div class="card rounded-5 bg-light">
              <div class="card-body p-4">
                <div class="row mt-0">
                  <div class="col-md-12">
                    <h3>
                    <img src="assets/images/building-avatar.png" width="20">
                      <span class="ms-2 pt-2">Condominio</span>
                    </h3>
                    <table class="table table-striped">
                      <thead>
                      <tr>
                        <th>A</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>AA</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </!-->
        </div> 
      </div>
    </div>
  `,
  styleUrls: []
})
export class TanqueEditComponent implements OnInit {
  tanqueId: number = 0;
  loading: boolean = false;
  profile: any;
  permissions: any;
  payload: any = {
    page: 1,
    per_page: 100,
  };

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private store: SettlementsService,
    private toastr: ToastrService,
    private breadcrumbService: BreadcrumbService,
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.id = this.profile ? this.profile.id : 0;
  }

  ngOnInit(): void {
    this.activateRoute.params.subscribe(params => {
      this.tanqueId = params['id'];
    });
    this.breadcrumbService.set('@tanques', 'Tanques');
    this.breadcrumbService.set('@editarTanque', this.tanqueId.toString());
  }

  editTanque(data: any) {
    console.log("EDIT");
    this.store.updateTanque(this.tanqueId, data).subscribe({
      next: (res: any) => {
        console.log("RES => ", res);
        this.toastr.success('Exito', 'Tanque actualizado corrrectamente.');
        this.router.navigate(['/tanques']);
      },
      error: (err: any) => {
        this.toastr.error('Error', 'Ocurrió un error al actualizar el tanque');
      },
    });
  }

  cancelEdit() {
    console.log("CANCEL")
    this.router.navigate(['/tanques']);
  }

}
