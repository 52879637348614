import { Component } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-index-reports',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-4">
        <div class="row mb-4 mt-0">
          <div class="col-md-5">
            <h1>Reportes</h1>
            <p>Consulta la información del sistema.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8">
            <div class="card border-0 rounded-4 mb-5">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-10">
                    <app-filter-advanced [toDate]="toDate" [fromDate]="fromDate" (dateRange)="updateDate($event)"></app-filter-advanced>                  
                  </div>
                  <div class="col-md-2 text-start">
                    <button class="btn btn-outline-primary shadow-sm" (click)="search()">Filtrar</button>
                  </div>
                  <div class="col-md-12 mt-4 mb-4">
                    <ul class="list-group mt-4">
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-8">Auditoria T1 internacional</div>
                            <div class="col-4 text-end"><button type="button" class="btn btn-outline-info btn-sm">Generar reporte</button></div></div></li>
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-8">Auditoria T1 Nacional</div>
                            <div class="col-4 text-end"><button type="button" class="btn btn-outline-info btn-sm">Generar reporte</button></div></div></li>
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-8">Auditoria T2 Sur</div>
                            <div class="col-4 text-end"><button type="button" class="btn btn-outline-info btn-sm">Generar reporte</button></div></div></li>
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-8">Auditoria T2 Norte</div>
                            <div class="col-4 text-end"><button type="button" class="btn btn-outline-info btn-sm">Generar reporte</button></div></div></li>
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-8">Concentrado terminales</div>
                            <div class="col-4 text-end"><button type="button" class="btn btn-outline-info btn-sm">Generar reporte</button></div></div></li>
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-8">Reporte Mensual inventario Gas.</div>
                            <div class="col-4 text-end"><button type="button" class="btn btn-outline-info btn-sm">Generar reporte</button></div></div></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card border-0 rounded-4 mb-5">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <div class="clearfix">
                      <h5 class="float-start mt-1">Reportes generales</h5>
                      <fa-icon [icon]="faCircleNotch" [spin]="true" *ngIf="generatingReport" class="text-secondary float-end"></fa-icon>
                    </div>
                    <div class="alert alert-info" *ngIf="generatingReport">
                      <p class="mb-0 text-center">Creando reporte</p>
                    </div>
                    <ul class="list-unstyled mt-4">
                      <li><a (click)="generateContratosCondo()" class="nav-link mt-3 clearfix" style="text-decoration: underline;">Contratos condominio por vigencia</a></li>
                      <!-- li><a (click)="generateContratosUnits()" class="nav-link mt-3 clearfix" style="text-decoration: underline;">Contratos departamentos por vigencia</a></!-->
                      <li><a (click)="generateContratosComm()" class="nav-link mt-3 clearfix" style="text-decoration: underline;">Contratos comercial por vigencia</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class IndexReportsComponent {
  profile: any = {};
  generatingReport: boolean = false;

  faCircleNotch = faCircleNotch;

  a = new Date();
  fromDate; // : any = "2024-02-01"; // Date = new Date();
  toDate; // : any = "2024-02-29";

  
  constructor(
    private store: SettlementsService,
    private calendar: NgbCalendar,
  ) {
    this.profile = JSON.parse(localStorage.getItem('profile') || '{}');
    this.fromDate = new NgbDate(this.a.getFullYear(), this.a.getMonth()+1, 1); // new Date(this.a.getFullYear(), this.a.getMonth()+1, 0);
    this.toDate = new NgbDate(this.a.getFullYear(), this.a.getMonth()+1, 29);
  }

  generateContratosCondo() {
    this.generatingReport = true;
    console.log('Contratos por vigencia ', this.profile.profile.utility_id);
    this.store.getContratos({
      utility_id: this.profile.profile.utility_id,
      tipo: 0
    }).subscribe((data: any) => {
      console.log('Contratos data ====> ', data);

      let options = {
        title: 'Vigencia contrato condominios',
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        noDownload: false,
        showTitle: true,
        useBom: true,
        headers: [
          "id",
          "Condominio",
          "Correo",
          "Vigencia"
        ]
      };

      new ngxCsv(data, 'Vigencia contratos condominios', options);
      this.generatingReport = false;
    });
  }

  generateContratosUnits() {
    this.store.getContratos({
      utility_id: this.profile.profile.utility_id,
      tipo: 0
    }).subscribe((data: any) => {
      console.log('Contratos data ====> ', data);
    });
  }

  generateContratosComm() {
    console.log('Contratos por vigencia ', this.profile.profile.utility_id);
    this.store.getContratos({
      utility_id: this.profile.profile.utility_id,
      tipo: 1,
    }).subscribe((data: any) => {
      let options = {
        title: 'Vigencia contrato comercial',
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        noDownload: false,
        showTitle: true,
        useBom: true,
        headers: [
          "id",
          "Condominio",
          "Correo",
          "Vigencia"
        ]
      };

      new ngxCsv(data, 'Vigencia contratos comerciales', options);
      this.generatingReport = false;
    });
  }

  updateDate(event: any) {}

  search() {}
}
