import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { IconsService } from 'src/app/shared/services/icons.service';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ImageService } from 'src/app/shared/services/image.service';

import * as bs from 'bootstrap';

@Component({
  selector: 'app-payments',
  template: `
    <div class="row">
    <div class="col-sm-11 offset-sm-1 col-md-11 offset-md-1 col-lg-8 offset-lg-4 col-xl-8 offset-xl-3 mt-5">
        <div class="row mb-4">
          <div class="col-md-5">
            <h1>Recibos pagados</h1>
            <p>Consulta a los pagos realizados.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <button class="btn btn-outline-primary btn-lg rounded-5 border border-primary border-2" (click)="downloadCSV()">
              <!-- [disabled]="transactionList.length==0" -->
              <fa-icon [icon]="icons.faDownload" class="me-3" *ngIf="!downloading"></fa-icon>
              <fa-icon [icon]="icons.faCircleNotch" [spin]="true" class="me-3" *ngIf="downloading"></fa-icon>
              Generar reporte
            </button>
            <!-- button class="btn btn-primary btn-lg rounded-5 ms-3" routerLink="/bills/new">Crear recibo</!-->
          </div>
          <xng-breadcrumb ></xng-breadcrumb>
        </div>
        <app-filter-full
          [profile]="profile"
          [toDate]="toDate"
          [fromDate]="fromDate"
          [payload]="payFilterPrefs"
          (billsSettlement)="loadBillsBySettlement($event)"
          (billsBuilding)="loadBillsByBuilding($event)"
          (billsFloor)="loadBillsByFloor($event)"
          (billsUnit)="loadBillsByUnit($event)"
          (dateRange)="updateDate($event)"
          (billsSort)="loadBillsSort($event)"
          (billsOrder)="loadBillsOrder($event)"
          (billsPerPage)="loadBillsPerPage($event)"
          (clear)="clearFilter()"
        ></app-filter-full>
        <div class="row">
          <div class="col-md-12 mt-4">
          <div class="row">
              <div class="col-2">
                <h5 class="ms-3"># Recibo</h5>
              </div>
              <div class="col-2">
                <h5 class="ms-2">Unidad</h5>
              </div>
              <div class="col-2 text-center">
                <h5>Consumo</h5>
              </div>
              <div class="col-2 text-center">
                <h5>Pago</h5>
              </div>
              <div class="col-2 text-center">
                <h5>Vence</h5>
              </div>
              <div class="col-1 text-center">
                <h5>Pagado</h5>
              </div>
            </div>
            <div class="list-group rounded-5 shadow mt-2" *ngIf="bills.length > 0">
              <div class="list-group-item bg-white px-4 py-3" *ngFor="let bill of bills | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
                <app-item-bill-paid 
                  [bill]="bill" 
                  [profile]="profile" 
                  [permissions]="permissions"
                  (updateDate)="updateBillDate($event)"
                ></app-item-bill-paid>
              </div>
            </div>
            <div class="list-group rounded-4 shadow" *ngIf="bills.length==0">
              <div class="list-group-item text-center py-5" *ngIf="!loading">
                <img src="/assets/images/empty-state.png" width="180px">
                <h5 class="text-muted">No se encontraron registros</h5>
              </div>
              <div *ngIf="loading" class="text-center m-3">
                <fa-icon [icon]="icons.faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                <p class="text-muted">Cargando...</p>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-end mt-3">
            <pagination-controls
              (pageChange)="p = $event"
              (pageChange)="pageChanged($event)"
              previousLabel="Prev."
              nextLabel="Sig."
              screenReaderPaginationLabel="Pagination"
              screenReaderPageLabel="page"
              *ngIf="meta.total_pages > 1"
            ></pagination-controls>
          </div>
        </div>
      </div>
    </div>
    <app-modal-set-bill-date [bill]="selectedBill"></app-modal-set-bill-date>
  `,
  styleUrls: []
})
export class PaymentsComponent implements OnInit {
  apiUrl: string = environment.AWS_REST_WSPREFIX;
  bills: any = [];
  meta: any = {};
  profile: any = {};
  permissions: any = {};
  searchBox: FormGroup = new FormGroup({});

  selectedBill: any = null;

  searchActive: boolean = false;
  loading: boolean = false;

  payload: any = {
    page: 1,
    per_page: 100,
    status: true,
    state: [1,2],
    created_at_since: '',
    created_at_to: '',
    sort: 'paid_date',
    order: 'DESC'
  }

  a = new Date();
  fromDate; // : any = "2023/12/01"; // Date = new Date();
  toDate; // : any = "2024/02/02"; // Date = new Date();

  p: number = 1;

  settlements: any = [];
  buildings: any = [];
  floors: any = [];
  units: any = [];

  downloading: boolean = false;

  transactionList: any = [];

  payFilterPrefs: any = {};

  constructor(
    private store : SettlementsService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public icons: IconsService,
    public images: ImageService
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile_id = this.profile.id;

    this.fromDate = new NgbDate(this.a.getFullYear(), this.a.getMonth(), 1);
    this.toDate = new NgbDate(this.a.getFullYear(), this.a.getMonth()+1, 29);

    this.payload.created_at_since = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
    this.payload.created_at_to = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;

    this.payFilterPrefs = localStorage.getItem('payFilterPrefs') != null ? JSON.parse(localStorage.getItem('payFilterPrefs')!) : this.payload;
    this.payFilterPrefs.state = [1,2];
    this.payFilterPrefs.status = true;
    this.payFilterPrefs.profile_id = this.profile.id;
    this.p = this.payFilterPrefs.page;
  }

  ngOnInit(): void {
    // this.loadLogo();
    this.loadBills(this.payFilterPrefs);
  }

  async loadLogo() {
    const img = this.images.buildURL(this.profile.logo);
    const imx: any = await this.images.getBase64ImageFromUrl(img);
    this.profile.logo = `${imx.split(',')[1]}`;
  }

  updateDate(event: any) {
    this.fromDate = event.from;
    this.toDate = event.to;

    if (this.toDate.after(this.fromDate) || this.toDate.equals(this.fromDate)) {
      this.payFilterPrefs.created_at_since = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
      this.payFilterPrefs.created_at_to = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;
      this.loadBills(this.payFilterPrefs);
    }
  }

  loadBills(payload: any) {
    this.store.getTransactionsQuery(payload).subscribe((data: any) => {
      console.log('getBillsQuery data => ', data);
      this.bills = data.data; //  data.filter((el: any) => el.status == true);
      this.meta = data.meta;
      this.loading = false;
      localStorage.setItem('payFilterPrefs', JSON.stringify(this.payFilterPrefs));
      console.log('data => ', data);
    });
  }

  loadBillsBySettlement(settlement_id: any) {
    this.payFilterPrefs.settlement_id = settlement_id;    
    this.payFilterPrefs.page = 1;
    this.p = 1;

    delete this.payFilterPrefs.building_id;
    delete this.payFilterPrefs.floor_id;
    delete this.payFilterPrefs.unit_id;

    this.loadBills(this.payFilterPrefs);   
  }

  loadBillsByBuilding(building_id: any) {
    this.payFilterPrefs.building_id = building_id;
    this.payFilterPrefs.page = 1;
    this.p = 1;

    delete this.payFilterPrefs.floor_id;
    delete this.payFilterPrefs.unit_id;

    this.loadBills(this.payFilterPrefs);
  }

  loadBillsByFloor(floor_id: any) {
    this.payFilterPrefs.floor_id = floor_id;
    this.payFilterPrefs.page = 1;
    this.p = 1;

    delete this.payFilterPrefs.unit_id;

    this.loadBills(this.payFilterPrefs);
  }

  loadBillsByUnit(unit_id: any) {
    this.payFilterPrefs.unit_id = unit_id;
    this.payFilterPrefs.page = 1;
    this.p = 1;
    this.loadBills(this.payFilterPrefs);
  }

  clearFilter() {    
    this.payFilterPrefs.page = 1;
    this.p = 1;

    delete this.payFilterPrefs.settlement_id;
    delete this.payFilterPrefs.building_id;
    delete this.payFilterPrefs.floor_id;
    delete this.payFilterPrefs.unit_id;

    this.loadBills(this.payFilterPrefs);
  }

  pageChanged(event: any) {
    this.payFilterPrefs.page = event;
    this.loadBills(this.payFilterPrefs);
  }

  loadBillsSort(event: any) {
    this.payFilterPrefs.sort = event;
    this.loadBills(this.payFilterPrefs);
  }

  loadBillsOrder(event: any) {
    this.payFilterPrefs.order = event;
    this.loadBills(this.payFilterPrefs);
  }

  loadBillsPerPage(event: any) {
    this.payFilterPrefs.per_page = event;
    this.loadBills(this.payFilterPrefs);
  }


  gotoPayment(bill: any) {
    this.router.navigate(['/payments/pay', bill.id]);
  }

  updateBillDate(event: any) {
    this.selectedBill = event;
    new bs.Modal(<HTMLInputElement>document.getElementById('modalSetBillDate')).show();
  }

  updateList(event: any) {
    console.log("event: ", event);
    if (event.checked) {
      this.transactionList.push(event.transaction.id);
    } else {
      this.transactionList = this.transactionList.filter((id: any) => id !== event.transaction.id);    
    }
  }

  async downloadCSV() {
    this.downloading = true;
    // console.log('transactions => ', this.transactionList);
    // this.store.downloadTransactions({ list: this.transactionList }).subscribe((data: any) => {
    console.log('payload => ', this.payload);
    this.payload.per_page = 2000;
    let bills: any = {};

    let options = {
      title: 'Vigencia contrato comercial',
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      noDownload: false,
      showTitle: true,
      useBom: true,
      headers: [
        "# Recibo",
        "Referencia",
        "Metodo pago",
        "Condominio",
        "Edificio",
        "Departamento",
        "Consumo LT",
        "Consumo M3",
        "Consumo $",
        "Lectura Ant.",
        "Lectura Act.",
        "Costo Admon.",
        "Precio Gas",
        "Adeudos",
        "Recargos",
        "Sub total",
        "IVA",
        "Total",
        "Fecha vencimiento",
        "Fecha pago",
        "Días periodo",
        "Factura"
      ]
    };

    if (this.payload.unit_id) {
      await this.store.getTransactionsByUnit(this.payload.unit_id, this.payload).subscribe(async (data: any) => {
        let datos: any = [];

        await data.data.map((bill: any) => {
          datos.push({
            id: bill.id,
            reference: bill.transaction_id,
            method_payment: bill.payment_method_brand,
            settlement_name: bill.settlement_name,
            building_name: bill.building_name,
            unit_name: bill.unit_name,
            consumo_lt: bill.consumo_lt,
            consumo_m3: bill.consumo_m3,
            consumo_total: bill.consumo_total,
            read_curr: bill.read_curr,
            read_prev: bill.read_prev,
            admin_price: bill.admin_price,
            gas_price: bill.gas_price,
            interests: bill.interests,
            recargos: bill.recargos,
            sub_total: bill.sub_total,
            taxes: bill.taxes,
            total: bill.total,
            due_date: bill.due_date,
            paid_date: bill.paid_date,
            days: bill.days,
            invoice_id: bill.invoice_id,
          });
        });

        new ngxCsv(datos, 'Reporte de transacciones', options);

        this.downloading = false;
      });
    } else if (this.payload.floor_id) {
      await this.store.getTransactionsByFloor(this.payload.floor_id, this.payload).subscribe(async (data: any) => {
        let datos: any = [];

        await data.data.map((bill: any) => {
          datos.push({
            id: bill.id,
            reference: bill.transaction_id,
            method_payment: bill.payment_method_brand,
            settlement_name: bill.settlement_name,
            building_name: bill.building_name,
            unit_name: bill.unit_name,
            consumo_lt: bill.consumo_lt,
            consumo_m3: bill.consumo_m3,
            consumo_total: bill.consumo_total,
            read_curr: bill.read_curr,
            read_prev: bill.read_prev,
            admin_price: bill.admin_price,
            gas_price: bill.gas_price,
            interests: bill.interests,
            recargos: bill.recargos,
            sub_total: bill.sub_total,
            taxes: bill.taxes,
            total: bill.total,
            due_date: bill.due_date,
            paid_date: bill.paid_date,
            days: bill.days,
            invoice_id: bill.invoice_id,
          });
        });

        new ngxCsv(datos, 'Reporte de transacciones', options);

        this.downloading = false;
      });
    } else if (this.payload.building_id) {
      await this.store.getTransactionsByBuilding(this.payload.building_id, this.payload).subscribe(async (data: any) => {
        let datos: any = [];

        await data.data.map((bill: any) => {
          datos.push({
            id: bill.id,
            reference: bill.transaction_id,
            method_payment: bill.payment_method_brand,
            settlement_name: bill.settlement_name,
            building_name: bill.building_name,
            unit_name: bill.unit_name,
            consumo_lt: bill.consumo_lt,
            consumo_m3: bill.consumo_m3,
            consumo_total: bill.consumo_total,
            read_curr: bill.read_curr,
            read_prev: bill.read_prev,
            admin_price: bill.admin_price,
            gas_price: bill.gas_price,
            interests: bill.interests,
            recargos: bill.recargos,
            sub_total: bill.sub_total,
            taxes: bill.taxes,
            total: bill.total,
            due_date: bill.due_date,
            paid_date: bill.paid_date,
            days: bill.days,
            invoice_id: bill.invoice_id,
          });
        });

        new ngxCsv(datos, 'Reporte de transacciones', options);

        this.downloading = false;
      });
    } else if (this.payload.settlement_id) {
      await this.store.getTransactionsBySettlement(this.payload.settlement_id, this.payload).subscribe(async (data: any) => {
        let datos: any = [];

      await data.data.map((bill: any) => {
        datos.push({
          id: bill.id,
          reference: bill.transaction_id,
          method_payment: bill.payment_method_brand,
          settlement_name: bill.settlement_name,
          building_name: bill.building_name,
          unit_name: bill.unit_name,
          consumo_lt: bill.consumo_lt,
          consumo_m3: bill.consumo_m3,
          consumo_total: bill.consumo_total,
          read_curr: bill.read_curr,
          read_prev: bill.read_prev,
          admin_price: bill.admin_price,
          gas_price: bill.gas_price,
          interests: bill.interests,
          recargos: bill.recargos,
          sub_total: bill.sub_total,
          taxes: bill.taxes,
          total: bill.total,
          due_date: bill.due_date,
          paid_date: bill.paid_date,
          days: bill.days,
          invoice_id: bill.invoice_id,
        });
      });

      new ngxCsv(datos, 'Reporte de transacciones', options);

      this.downloading = false;
      });
    } else {
      await this.store.getTransactionsQuery(this.payload).subscribe(async (data: any) => {
        let datos: any = [];

      await data.data.map((bill: any) => {
        datos.push({
          id: bill.id,
          reference: bill.transaction_id,
          method_payment: bill.payment_method_brand,
          settlement_name: bill.settlement_name,
          building_name: bill.building_name,
          unit_name: bill.unit_name,
          consumo_lt: bill.consumo_lt,
          consumo_m3: bill.consumo_m3,
          consumo_total: bill.consumo_total,
          read_curr: bill.read_curr,
          read_prev: bill.read_prev,
          admin_price: bill.admin_price,
          gas_price: bill.gas_price,
          interests: bill.interests,
          recargos: bill.recargos,
          sub_total: bill.sub_total,
          taxes: bill.taxes,
          total: bill.total,
          due_date: bill.due_date,
          paid_date: bill.paid_date,
          days: bill.days,
          invoice_id: bill.invoice_id,
        });
      });

      new ngxCsv(datos, 'Reporte de transacciones', options);

      this.downloading = false;
      });
    }

    console.log('bills => ', bills);

    // await this.store.getBillsQuery(this.payload).subscribe(async (data: any) => {
      // console.log('Transactions data => ', data);
      

      
    // });
    this.payload.per_page = 100;
  }

}