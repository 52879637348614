import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TasksService } from 'src/app/services/tasks.service';
import { BreadcrumbService } from 'xng-breadcrumb';


@Component({
  selector: 'app-builder-create',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
      <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-9">
            <h1>Constructor</h1>
            <p>Configura las amenidades que están disponibles para los departamentos.</p>
          </div>
          <div class="col-md-3 text-end mt-4">
            
          </div>
        </div>
        <app-form-builder [builder]="builder" (builderData)="saveBuilder($event)"></app-form-builder>
        
      </div>
    </div>
  `,
  styleUrls: []
})
export class BuilderCreateComponent implements OnInit {
  builder: any = null;

  constructor(
    private router: Router,
    private tasksService: TasksService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private breadcrumbService: BreadcrumbService,
  ) { }

  ngOnInit(): void {
    this.breadcrumbService.set('@builder', 'Constructor');    
  }

  saveBuilder(payload: any) {
    this.tasksService.createBuilder(payload).subscribe({
      next: (builder: any) => {
        this.toastr.success('Constructor creado correctamente.');
        this.router.navigateByUrl('/maintenances/builders');
      },
      error: (err: any) => {
        this.toastr.error('Ocurrió un error al crear el constructor.');
      },
    });
  }

  
}
