import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { IconsService } from 'src/app/shared/services/icons.service';
import * as bs from 'bootstrap';

@Component({
  selector: 'app-receipts',
  template: `
    <div class="row">
    <div class="col-sm-11 offset-sm-1 col-md-11 offset-md-1 col-lg-8 offset-lg-4 col-xl-8 offset-xl-3 mt-5">
        <div class="row mb-2">
          <div class="col-md-7 col-lg-7">
            <h1>Recibos no pagados</h1>
            <p>Consulta los recibos pendientes de pago.</p>
          </div>
          <div class="col-md-5 col-lg-5 mt-4 text-end">
            <!-- button class="btn btn-primary btn-lg rounded-5" routerLink="/payments/receipts">Generar recibos</!-->
            <!-- button class="btn btn-primary btn-lg rounded-5 ms-3" routerLink="/bills/new">Crear recibo</!-->
          </div>
          <xng-breadcrumb ></xng-breadcrumb>
        </div>
        <app-filter-full
          [profile]="profile"
          [toDate]="toDate"
          [fromDate]="fromDate"
          [payload]="payFilterPrefs"
          (billsSettlement)="loadBillsBySettlement($event)"
          (billsBuilding)="loadBillsByBuilding($event)"
          (billsFloor)="loadBillsByFloor($event)"
          (billsUnit)="loadBillsByUnit($event)"
          (billsSort)="loadBillsSort($event)"
          (billsOrder)="loadBillsOrder($event)"
          (billsPerPage)="loadBillsPerPage($event)"
          (dateRange)="updateDate($event)"
          (clear)="clearFilter()"
        ></app-filter-full>
        <div class="row">
          <div class="col-md-12 mt-4">
            <div class="row">
              <div class="col-2">
                <h5 class="ms-3"># Recibo</h5>
              </div>
              <div class="col-2">
                <h5 class="ms-2">Unidad</h5>
              </div>
              <div class="col-2 text-center">
                <h5>Consumo</h5>
              </div>
              <div class="col-2 text-center">
                <h5>Total a pagar</h5>
              </div>
              <div class="col-2 text-center">
                <h5>Creado</h5>
              </div>
              <div class="col-1 text-center">
                <h5>Vence</h5>
              </div>
            </div>
            <div class="list-group rounded-4 shadow mt-2" *ngIf="bills.length > 0">
              <div class="list-group-item bg-white px-4 py-3" *ngFor="let bill of bills | paginate: { itemsPerPage: payFilterPrefs.per_page, currentPage: p, totalItems: meta.total_entries}">
                <app-item-bill
                  [bill]="bill"
                  [profile]="profile"
                  [permissions]="permissions"
                  (selectBill)="dispatchPayment($event)" 
                ></app-item-bill>
              </div>
            </div>
            <div class="list-group rounded-4 shadow" *ngIf="bills.length==0">
              <div class="list-group-item text-center py-5" *ngIf="!loading">
                <img src="/assets/images/empty-state.png" width="180px">
                <h5 class="text-muted">No se encontraron registros</h5>
              </div>
              <div *ngIf="loading" class="text-center m-3">
                <fa-icon [icon]="icons.faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                <p class="text-muted">Cargando...</p>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-end mt-5 mb-5" *ngIf="meta.total_pages > 1" >
            <pagination-controls
              (pageChange)="p = $event"
              (pageChange)="pageChanged($event)"
              previousLabel="Prev."
              nextLabel="Sig."
              screenReaderPaginationLabel="Pagination"
              screenReaderPageLabel="page"
              *ngIf="bills.length>0"
            ></pagination-controls>
          </div>
        </div>
        <app-modal-options-pay [bill]="selectedBill" [loading]="loading" (billData)="payBill($event)"></app-modal-options-pay>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ReceiptsComponent implements OnInit {

  bills: any = [];
  tenant: any = {};
  meta: any = {};
  profile: any = {};
  permissions: any = {};
  loading: boolean = false;

  searchActive: boolean = false;

  selectedBill: any = {};

  payload: any = {    
    page: 1,
    per_page: 100,
    status: false,
    state: [1,2,3],
    created_at_since: '',
    created_at_to: ''
  };

  a = new Date();
  fromDate;
  toDate;

  p: number = 1;
  
  settlements: any = [];
  buildings: any = [];
  floors: any = [];
  units: any = [];

  payFilterPrefs: any = {};

  constructor(
    private store : SettlementsService,
    private toastr: ToastrService,
    public icons: IconsService
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile_id = this.profile.id;

    this.fromDate = new NgbDate(this.a.getFullYear(), this.a.getMonth(), 1);
    this.toDate = new NgbDate(this.a.getFullYear(), this.a.getMonth()+1, 29);
    this.payload.created_at_since = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`;
    this.payload.created_at_to = `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`;

    this.payFilterPrefs = localStorage.getItem('payFilterPrefs') != null ? JSON.parse(localStorage.getItem('payFilterPrefs')!) : this.payload;
    this.payFilterPrefs.state = [1,2,3];
    this.payFilterPrefs.status = false;
    this.payFilterPrefs.profile_id = this.profile.id;
    this.p = this.payFilterPrefs.page;
  }

  ngOnInit(): void {
    this.loadBills(this.payFilterPrefs);
  }

  updateDate(event: any) {
    this.fromDate = event.from;
    this.toDate = event.to;

    if (this.toDate.after(this.fromDate) || this.toDate.equals(this.fromDate)) {
      this.payFilterPrefs.created_at_since = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
      this.payFilterPrefs.created_at_to = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;
      this.loadBills(this.payFilterPrefs);
    }
  }

  loadBills(payload: any) {
    this.loading = true;
    this.store.getBillsQuery(payload).subscribe({
      next: (data: any) => {
        console.log('BILLS => ', data);
        this.bills = data.data;
        this.meta = data.meta;
        this.loading = false;
        localStorage.setItem('payFilterPrefs', JSON.stringify(this.payFilterPrefs));
      }, error: (err: any) => {
        this.loading = false;
      }
    });
  }

  loadBillsBySettlement(settlement_id: any) {
    this.payFilterPrefs.settlement_id = settlement_id;    
    this.payFilterPrefs.page = 1;
    this.p = 1;

    delete this.payFilterPrefs.building_id;
    delete this.payFilterPrefs.floor_id;
    delete this.payFilterPrefs.unit_id;

    this.loadBills(this.payFilterPrefs);   
  }

  loadBillsByBuilding(building_id: any) {
    this.payFilterPrefs.building_id = building_id;
    this.payFilterPrefs.page = 1;
    this.p = 1;

    delete this.payFilterPrefs.floor_id;
    delete this.payFilterPrefs.unit_id;

    this.loadBills(this.payFilterPrefs);
  }

  loadBillsByFloor(floor_id: any) {
    this.payFilterPrefs.floor_id = floor_id;
    this.payFilterPrefs.page = 1;
    this.p = 1;

    delete this.payFilterPrefs.unit_id;

    this.loadBills(this.payFilterPrefs);
  }

  loadBillsByUnit(unit_id: any) {
    this.payFilterPrefs.unit_id = unit_id;
    this.payFilterPrefs.page = 1;
    this.p = 1;
    this.loadBills(this.payFilterPrefs);
  }

  clearFilter() {    
    this.payFilterPrefs.page = 1;
    this.p = 1;

    delete this.payFilterPrefs.settlement_id;
    delete this.payFilterPrefs.building_id;
    delete this.payFilterPrefs.floor_id;
    delete this.payFilterPrefs.unit_id;

    this.loadBills(this.payFilterPrefs);
  }

  pageChanged(event: any) {
    this.payFilterPrefs.page = event;
    this.loadBills(this.payFilterPrefs);
  }

  loadBillsSort(event: any) {
    console.log('loadBillsSort', event);
    this.payFilterPrefs.sort = event;
    this.loadBills(this.payFilterPrefs);
  }

  loadBillsOrder(event: any) {
    this.payFilterPrefs.order = event;
    this.loadBills(this.payFilterPrefs);
  }

  loadBillsPerPage(event: any) {
    this.payFilterPrefs.per_page = event;
    this.loadBills(this.payFilterPrefs);
  }

  dispatchPayment(bill: any) {
    console.log('dispatchPayment', bill);
    this.selectedBill = bill;
    new bs.Modal(<HTMLInputElement>document.getElementById('modalOptionsPay')).show();
  }

  deleteBill(bill: any) {
    let confirmation = confirm('¿Está seguro de eliminar el recibo?');

    if (!confirmation) {
      return;
    } else {
      this.store.destroyBill(bill.id).subscribe({
        next: (data: any) => {
          this.toastr.success('Recibo eliminada correctamente', 'Éxito');
          this.loadBills(this.payload);
        },
        error: (err: any) => {
          this.toastr.error('Ocurrió un error al eliminar el recibo', 'Error');
        }
      });
    }
  }

  payBill(bill: any) {
    console.log('PAY BILL', bill);
    
    this.store.createTransaction(bill).subscribe({
      next: (data: any) => {
        this.toastr.success('Recibo creado correctamente.', 'Éxito');
        this.loading = false;
        // this.loadBills(this.payload);
        window.location.reload();
      },
      error: (err: any) => {
        this.toastr.error('Ocurrió un error al crear el recibo', 'Error');
        this.loading = false;
      }
    });
  }

}
