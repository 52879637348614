import { Component, Input } from '@angular/core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { SettlementsService } from 'src/app/services/settlements.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { ImageService } from 'src/app/shared/services/image.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-item-invoice-canceled',
  template: `
    <div class="row">
      <div class="col-md-8 ps-4 pe-0 py-3">
        <h4 class="mb-0">Id #: {{invoice.id}}</h4>
        <p class="mb-0">Folio fiscal: {{invoice.uuid}}</p>
        <p class="mb-0">{{invoice.settlement_name}}, {{invoice.building_name}}, {{invoice.unit_name}}</p>
      </div>
      <div class="col-md-3 text-end ps-0 pe-3 py-3">
        <p class="mb-0" class="mb-0 text-sm text-muted">Facturado: {{invoice.created_at | date: 'dd/MM/yyyy - HH:mm'}}</p>
        <p class="mb-0" class="mb-0 text-sm text-muted">Cancelada: {{invoice.updated_at | date: 'dd/MM/yyyy'}}</p>
      </div>
      <div class="col-md-1 text-center p-0">
        <div class="hover-info border-start pt-3 pe-4" style="height: 100%;" (click)="downloadFile()">
          <fa-icon [icon]="icons.faDownload" class="center-icon"></fa-icon>
        </div>
          <!-- div class="badge rounded-pill me-3 px-3 py-2 border border-3" [ngClass]="invoice.status == 'active' ? 'border-success text-success' : 'border-warning text-warning'">
            <p class="mb-0 text-capitalize">{{invoice.status}}</p>
          </!-->
          <!-- a class="btn btn-outline-dark rounded-4 btn-lg text-end" href="{{receiptUrl}}/public/{{ invoice.pdf }}" target="_blank">
            
            Descargar
          </!-->
      </div>
    </div>
  `,
  styleUrls: []
})
export class ItemInvoiceCanceledComponent {
  receiptUrl: string = environment.RECIBOS_REST_WSPREFIX;
  apiUrl: string = environment.AWS_REST_WSPREFIX;
  @Input() invoice: any;
  @Input() profile: any;
  @Input() permissions: any;

  constructor(
    private store: SettlementsService,
    public icons: IconsService,
    public images: ImageService
  ) { }

  async downloadFile() {
    console.log('downloadFile', this.invoice);
    this.store.getProfile(this.invoice.profile_id).subscribe((data: any) => {
      const profile = data;

      let imageUrl = `${this.apiUrl}/storage/${data.logo.blob.key.substring(0,2)}/${data.logo.blob.key.substring(2,4)}/${data.logo.blob.key}`;
      this.images.getBase64ImageFromUrl(imageUrl).then((result: any) => {
        data.logo = result.split(',')[1];
      

      const payload: any = {
        utilityData: data,
        invoiceData: this.invoice,
      };
      this.store.createAcuseCancel(payload).subscribe((data: any) => {
        const int8Array = new Uint8Array(data.result.data);
          const blob = new Blob([int8Array], { type: 'application/pdf' });

          var fileURL = window.URL.createObjectURL(blob);
          var link=document.createElement('a');

          link.href = fileURL;
          link.download = data.filename;
          link.click();

          const blob2 = new Blob([payload.invoiceData.acuse], { type: 'application/xml' });

          var fileURL2 = window.URL.createObjectURL(blob2);
          var link2=document.createElement('a');

          link2.href = fileURL2;
          link2.download = `ACUSE_${payload.invoiceData.serie}_${payload.invoiceData.folio}.xml`;
          link2.click();
        });
      });
    });
  }

  loadImg(img: any) {
    if (img && img.blob) {
      return `${this.apiUrl}/storage/${img.blob.key.substring(0,2)}/${img.blob.key.substring(2,4)}/${img.blob.key}`;
    } else {
      return '';
    }
  }
  
}
