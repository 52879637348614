import { Component, ViewChild } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IconsService } from 'src/app/shared/services/icons.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as bs from 'bootstrap';

@Component({
  selector: 'app-refuels',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
        <div class="row mb-4">
          <div class="col-md-7">
            <h1>Suministro de combustible</h1>
            <!-- p>Configura los suministros asociados al edificio.</!-->
          </div>
          <div class="col-md-5 mt-4 text-end">
            <!-- button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" routerLink="create">
              <fa-icon [icon]="icons.faPlus" class="me-2"></fa-icon>
              Agregar suministro
            </!-->
            <button type="button" class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" (click)="setPrice()">Agregar precio</button>
          </div>
        </div>
            <div class="row mb-4">
              <div class="col-md-7">
                <!-- h2 class="mb-0">Habitacionales</!-->
                
              </div>
              <div class="col-md-5">
                <form [formGroup]="searchBox" (ngSubmit)="searchRefuels($event)">
                  <input class="form-control form-control-lg bg-transparent rounded-5" placeholder="Buscar..." formControlName="search">
                </form>
              </div>
              <div class="col-md-12 mt-5 pb-3">
                <div class="list-group rounded-5 shadow overflow-hidden" *ngIf="refuels.length>0">
                  <div class="list-group-item p-0" *ngFor="let refuel of refuels | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
                    <app-item-refuel [refuel]="refuel" [profile]="profile" [permissions]="permissions" (refuelSelected)="updateList($event)"></app-item-refuel>                    
                  </div>
                </div>
                <div class="list-group rounded-5 shadow" *ngIf="refuels.length==0">
                  <div class="list-group-item text-center py-5" *ngIf="loading">
                    <fa-icon [icon]="icons.faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                    <p class="text-muted">Cargando...</p>
                  </div>
                  <div class="list-group-item text-center py-5" *ngIf="!loading">
                    <img src="/assets/images/empty-state.png" width="240px">
                    <h5 class="text-muted">No se encontraron registros</h5>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-end mt-4" *ngIf="refuels.length>0">
                <pagination-controls
                  (pageChange)="p = $event"
                  (pageChange)="pageChanged($event)"
                  previousLabel="Prev."
                  nextLabel="Sig."
                  screenReaderPaginationLabel="Pagination"
                  screenReaderPageLabel="page"                  
                ></pagination-controls>
              </div>
            </div>
          
      </div>
  </div>
  <app-modal-set-price [refuelList]="refuelList" (priceData)="savePrice($event)"></app-modal-set-price>
  `,
  styleUrls: []
})
export class RefuelsComponent {
  @ViewChild('modalSetPrice') modalSetPrice: any;

  loading: boolean = false;
  refuels: any[] = [];
  meta: any = {};
  searchBox: FormGroup = new FormGroup({});
  refuelList: any[] = [];
  profile: any = {};
  permissions: any = {};

  payload: any = {
    page: 1,
    per_page: 50,
    created_at_since: '',
    created_at_to: '',
    sort: 'created_at',
    order: 'DESC'
  };

  p: number = 1;

  a = new Date();
  fromDate; // : any = "2023/12/01"; // Date = new Date();
  toDate; // : any = "2024/02/02"; // Date = new Date();

  constructor(
    private store : SettlementsService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public icons: IconsService,
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile_id = this.profile ? this.profile.id : 0;

    this.fromDate = new NgbDate(this.a.getFullYear(), this.a.getMonth(), 1);
    this.toDate = new NgbDate(this.a.getFullYear(), this.a.getMonth()+1, 30);
    //
    this.payload.created_at_since = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
    this.payload.created_at_to = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;
  }

  ngOnInit(): void {
    this.loadRefuels(this.payload);
    this.initForm();
  }

  loadRefuels(payload: any) {
    this.loading = true;
    this.store.queryRefuels(payload).subscribe({
      next: (refuels: any) => {
        this.refuels = refuels.data;
        this.meta = refuels.meta;
        this.loading = false;
      },
      error: (err: any) => {
        console.log(err.status);
        this.loading = false;
        this.refuels = [];
        if (err.status === 500) {
          this.toastr.error('Ocurrió un error al obtener las amenidades.', 'Error');
        }
      }
    });
  }

  initForm() {
    this.searchBox = this.fb.group({
      search: ['']
    });
  }

  searchRefuels(event: any) {
    this.payload.page = 1;
    this.payload.name = this.searchBox.value.search;
    this.loadRefuels(this.payload);
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadRefuels(this.payload);
  }

  updateList(event: any) {
    console.log("event: ", event);

    if (event.checked) {
      this.refuelList.push(event.refuel.id);
    } else {
      this.refuelList = this.refuelList.filter((id: any) => id !== event.refuel.id);    
    }
  }

  setPrice() {
    console.log("set price", this.refuelList);
    new bs.Modal(<HTMLInputElement>document.getElementById('modalSetPrice')).show();
  }

  savePrice(event: any) {
    console.log("event: ", event);
    
    this.store.setPrice({ list: this.refuelList, price: event.price }).subscribe({
      next: (res: any) => {
        console.log(res);
        this.toastr.success('Precio actualizado.');
        window.location.reload();
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al actualizar el precio.', 'Error');
      }
    });
  }
} 
