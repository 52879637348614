import { map } from 'rxjs';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import * as bs from 'bootstrap';

import { Tenant } from 'src/app/models/tenant.model';
import { Unit } from 'src/app/models/unit.model';
import { Building } from 'src/app/models/building.model';
import { Settlement } from 'src/app/models/settlement.model';
import { Floor } from 'src/app/models/floor.model';

import { faCircleNotch, faChevronLeft, faEllipsisVertical, faTrashAlt, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tenants',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-4">
        <div class="row mb-4">
          <xng-breadcrumb ></xng-breadcrumb>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-8">
                <h5 class="mb-0">Condominio</h5>
                <h1>{{settlement.name}}</h1>
              </div>
              <div class="col-md-4 text-end">
              <button type="button" class="btn btn-link" routerLink="/condominios/edit/{{settlement.id}}">Editar</button>
              </div>
              <div class="col-md-12">
                <p class="mb-2"><small>{{settlement.address}}, {{settlement.city}}, {{settlement.country}}</small></p>
                <p class="mb-0" style="font-size: 17px;"><small>{{settlement.email}}</small></p>
                <p class="mb-" style="font-size: 17px;"><small>{{settlement.phone}}</small></p>                
                <hr>
              </div>
              <div class="col-md-5">
                <button type="button" class="btn btn-link px-2 mt-2" (click)="addBuilding()">
                  + Agregar edificio
                </button>
              </div>
              <div class="col-md-7">
                <select class="form-select form-select-lg rounded-5 bg-transparent border border-2" (change)="selectBuilding($event)">
                  <option [value]="null" disabled >Selecciona un edificio</option>
                  <option *ngFor="let building of buildings" [value]="building.id" [selected]="building.id==selectedBuilding.id">{{building.name}}</option>
                </select>            
              </div>
              <div class="col-md-12">
                <hr>
                <div class="card rounded-5 shadow">
                  <div class="card-body py-4 px-4">
                    <h4>Información del Edificio</h4>
                    <form [formGroup]="buildingForm" (ngSubmit)="updateBuilding()">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group required mb-4 mt-3">
                            <label for="name" class="form-label ms-2">Nombre del edificio</label>
                            <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="" formControlName="name">
                            <div *ngIf="bfc['name'].touched && bfc['name'].invalid">
                              <div *ngIf="bfc['name'].errors?.['required']" class="text-danger text-sm">El nombre del edificio es requerido.</div>
                            </div>
                          </div>
                          <div class="form-group required mb-4 mt-3">
                            <label for="clave" class="form-label ms-2">Clave del edificio</label>
                            <input type="text" class="form-control form-control-lg rounded-3" id="clave" placeholder="" formControlName="clave">
                            <div *ngIf="bfc['clave'].touched && bfc['clave'].invalid">
                              <div *ngIf="bfc['clave'].errors?.['required']" class="text-danger text-sm">La clave del edificio es requerida.</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 mt-4">
                          <h5>Tanque(s)</h5>
                        </div>
                        <div class="col-md-6 mt-3 text-end" *ngIf="selectedBuilding.tanques">
                          <!-- button type="button" class="btn btn-sm btn-outline-info" *ngIf="selectedBuilding.tanques.length==0" (click)="addTank(building)"><fa-icon [icon]="faPlus"></fa-icon></!-->
                        </div>
                        <div class="col-12">
                          <hr class="mt-1">
                        </div>
                        <div class="col-md-12 mt-2">
                          <div class="list-group rounded-3">
                            <div class="list-group-item p-2" *ngFor="let tanque of selectedBuilding.tanques">
                              <ngx-emoji emoji="alembic" [style]="{ bottom: '10px', right: '2px' }"></ngx-emoji> <b class="mt-0 ms-2">{{tanque.name}}</b>
                              <button type="button" class="btn btn-sm btn-outline-danger pt-1 pb-1 float-end" (click)="removeTank(tanque)">
                                <fa-icon [icon]="faTrashAlt"></fa-icon>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-5">
                        <div class="form-group text-end mt-4 clearfix">
                          <p class="float-start text-sm text-muted w-50 text-start">Última actualización {{selectedBuilding.updated_at | date: 'dd/MM/yyyy HH:mm'}}</p>
                          <button type="submit" class="btn btn-lg btn-primary rounded-5 float-end">Actualizar</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>        
          </div>
          <div class="col-md-5 offset-md-1">
            <div class="card rounded-5 shadow mb-4">
              <div class="card-body py-4 px-4">
                <div class="row">
                  <div class="col-md-12 mb-3">
                    <h5>Resumen</h5>
                  </div>
                  <div class="col-md-4">
                    <p class="mb-0">Edificios</p>
                    <h1 class="mb-0">{{buildings.length}}</h1>                
                  </div>
                  <div class="col-md-4">
                    <p class="mb-0">Deptos.</p>
                    <h1 class="mb-0">{{countUnits}}</h1>
                  </div>
                  <div class="col-md-4">
                    <p class="mb-0">Tanques</p>
                    <h1 class="mb-0">{{countTanques}}</h1>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="col-md-12 text-end">
                  <button type="button" class="btn btn-link" routerLink="/condominios/recibos/{{settlement.id}}">Historial de pagos</button>
                </div>
              </div>
            </div>
            <div class="card rounded-5 shadow" *ngIf="building">
              <div class="card-body py-4 px-3 clearfix">
                <div class="clearfix">
                  <h4 class="float-start mt-2">{{building.name}}</h4>
                  <button type="button" class="btn btn-link text-end float-end" (click)="plusFloor()"><fa-icon [icon]="faPlus" class="me-2"></fa-icon> Agregar piso</button>              
                </div>
                <hr class="mb-4">
                <div class="bg-white border border-2 rounded-4 p-4" *ngIf="addingFloor">
                  <form [formGroup]="floorForm">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group required mb-4 mt-3">
                          <label for="name" class="form-label ms-2">Nombre del piso</label>
                          <input type="text" class="form-control form-control-lg rounded-3" id="name" placeholder="Nombre del piso" formControlName="name">
                          <div *ngIf="ffc['name'].touched && ffc['name'].invalid">
                            <div *ngIf="ffc['name'].errors?.['required']" class="text-danger text-sm">El nombre del piso es requerido.</div>
                          </div>
                        </div>
                        <div class="form-group required mb-4 mt-3 d-none">
                          <label for="building_id" class="form-label ms-2">Edificio</label>
                          <input type="text" class="form-control form-control-lg rounded-3" id="building_id" placeholder="Nombre del piso" formControlName="building_id">
                          <div *ngIf="ffc['building_id'].touched && ffc['building_id'].invalid">
                            <div *ngIf="ffc['building_id'].errors?.['required']" class="text-danger text-sm">El nombre del piso es requerido.</div>
                          </div>
                        </div>
                        <div class="form-group required mb-2 mt-3 text-end">
                          <button class="btn btn-link" (click)="cancelFloor()">Cancelar</button>
                          <button class="btn btn-primary rounded-5" (click)="addFloor()">Guardar</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div *ngIf="!addingUnit">
                  <div *ngIf="!loading">
                    <div class="mt-4 clearfix" *ngFor="let floor of building.floors">
                      <app-building-floors
                        [floor]="floor"
                        [buildings]="buildings"
                        [selectedBuilding]="selectedBuilding"
                        [routing]="routing"
                        [settlementId]="settlementId"
                        (addUnit)="plusUnit($event)"
                        (refreshBuilding)="reloadBuilding()"
                      ></app-building-floors>
                        <!-- div class="card rounded-4 mt-2 mb-4 p-4 text-center" *ngIf="floor.units.length==0">
                          <p class="text-muted mt-3">No hay unidades registradas. <br><button type="button" class="btn btn-sm btn-link" (click)="plusUnit(floor)">+ Agregar</button></p>
                        </!-->
                    </div>
                  </div>
                  <div *ngIf="loading" class="row text-center py-5">
                    <fa-icon [icon]="faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                    <p class="text-muted">Cargando...</p>
                  </div>
                </div>            
                <div class="" *ngIf="addingUnit">
                  <app-form-unit [floor_id]="floor_id" (cancelUnit)="cancelUnit()" (updateUnit)="createUnit($event)"></app-form-unit>
                </div>        
              </div>
            </div>
            <div class="card rounded-5 bg-light" *ngIf="!building">
              <div *ngIf="loading">
                <fa-icon [icon]="faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                <p class="text-muted">Cargando...</p>
              </div>
              <p class="text-muted" *ngIf="!loading">No se encontraron resultados.</p>
            </div>
          </div>      
        </div>
      </div>  
    </div>
    <app-modal-add-building (onBuildingCreated)="createBuilding($event)"></app-modal-add-building>
  `,
  styleUrls: []
})
export class TenantsComponent {
  @ViewChild("btnAddBuilding", { read: ElementRef, static: false }) btnOptionsPay !: ElementRef;

  apiUrl: string = environment.AWS_REST_WSPREFIX;
  settlementId: number = 0;
  settlement: any = {};
  buildings: Building[] = [];
  building: any = null;
  utilities: any = [];
  routing: string = 'condominios';

  loading: boolean = false;
  loadingUtilities: boolean = false;
  selectedBuilding: any = {};

  buildingForm: FormGroup = new FormGroup({});
  floorForm: FormGroup = new FormGroup({});
  // unitForm: FormGroup = new FormGroup({});

  addingUtility: boolean = false;
  addingFloor: boolean = false;
  addingUnit: boolean = false;

  faCircleNotch = faCircleNotch;
  faEllipsisVertical = faEllipsisVertical;
  faChevronLeft = faChevronLeft;
  faTrashAlt = faTrashAlt;
  faPlus = faPlus;
  faEdit = faEdit;

  floor_id: number = 0;

  countUnits: number = 0;
  countTanques: number = 0;

  title = 'angular-text-search-highlight';
  searchText = '';

  constructor(
    private store : SettlementsService,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private breadcrumbService: BreadcrumbService,
  ) {
    this.activateRoute.params.subscribe(params => {
      this.settlementId = params['id'];
      this.store.getSettlement(this.settlementId).subscribe((settlement: any) => {
        this.settlement = settlement;
      });
    });
  }
  
  ngOnInit(): void {
    this.buildBuildingForm();
    this.loadBuilding(this.settlementId);
  }

  reloadBuilding() {
    this.loadBuilding(this.settlementId);
  }

  async loadBuilding(settlementId: number) {
    this.buildings = [];
    this.building = {};
    this.store.getBuildingsBySettlement(settlementId).subscribe({
      next: (data: any) => {
        this.buildings = data.sort((a: any, b: any) => a.id -b.id);
        this.loading = true;
        this.selectedBuilding = this.buildings[0];
        this.breadcrumbService.set('@settlementName', `${this.settlement.name} / ${this.selectedBuilding.name}`); 
        let count = 0;
        this.buildings.map((building: any) => {
          building.floors.map((floor: any) => {
            this.countUnits += floor.units.length
          });
          count += building.tanques.length;
        });
        this.setBuilding(this.buildings[0].id);
        this.countTanques = count;
      },
      error: (e: any) => {
        this.toastr.error('Edificios no pudieron ser cargados.', 'Error');
        this.loading = false;
      }
    });
  }

  searchChanges(e: any) {
    console.log(e.target.value);
    // this.description = this.getDescription();
  }

  buildBuildingForm() {
    this.buildingForm = this.fb.group({
      name: ['', Validators.required],
      clave: ['', Validators.required],
    });
  }

  buildFloorForm() {
    this.floorForm = this.fb.group({
      name: ['', Validators.required],
      building_id: ['', Validators.required],
    });
  }

  /*buildUnitForm() {
    this.unitForm = this.fb.group({
      name: ['', Validators.required],
      number: [''],
      inquilinos: [''],
      bedrooms: [''],
      bathrooms: [''],
      parking: [''],
      area: [''],
      unit_type_id: [''],
      floor_id: ['', Validators.required],
      factor: [''],
      admin_price: [''],
      interests: [''],
      reconexion_fee: [''],
      discount: [''],
    });
  }*/

  selectBuilding(e: any) {
    this.setBuilding(e.target.value);
  }

  async setBuilding(id: any)  {
    this.building = {};
    this.loading = true;
    this.store.getBuilding(id).subscribe({
      next: (data: any) => {
        try {
          this.buildingForm.patchValue({
            name: data.name,
            clave: data.clave,
          });

          this.building.floors = data.floors.sort((a: any, b: any) => b.id -a.id);          
          this.selectedBuilding = this.buildings.filter((x: any) => x.id == id)[0];
          this.breadcrumbService.set('@buildingName', `${this.settlement.name} / ${this.selectedBuilding.name}`);
          this.building = data;
          this.loading = false;
        } catch (error) {
          this.loading = false;
        }      
      },
      error: (error: any) => {
        this.toastr.error('Edificio no pudo ser cargado.', 'Error');
        this.loading = false;
      }
    });
  }

  getTenantAvatar(tenant: any) {
    if (tenant.avatar) {
      return `${this.apiUrl}/storage/${tenant.avatar.blob.key.substring(0,2)}/${tenant.avatar.blob.key.substring(2,4)}/${tenant.avatar.blob.key}`;
    } else {
      return 'assets/images/avatars/default-avatar.png';
    }
  }

  get bfc() {
    return this.buildingForm.controls;
  }

  get ffc() {
    return this.floorForm.controls;
  }

  // get ufc() {
    // return this.unitForm.controls;
  // }

  addFloor() {
    if (this.floorForm.invalid) {
      this.floorForm.markAllAsTouched();
      this.toastr.error('Formulario inválido.', 'Error');
      return;
    }

    this.store.createFloor(this.floorForm.value).subscribe({
      next: (data: any) =>  {
        this.toastr.success('Piso creado con correctamente.', '¡Éxito!');
        this.loadBuilding(this.settlementId);
        this.addingFloor = false;
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Piso no pudo ser creado.', 'Error');
        this.addingFloor = false;
      }
    });
  }

  plusFloor() {
    console.log(this.selectedBuilding.id);
    this.buildFloorForm();
    this.addingFloor = true;
    this.floorForm.patchValue({
      building_id: this.selectedBuilding.id,
    })
  }

  cancelFloor() {
    this.addingFloor = false;
    this.floorForm.reset();
  }

  plusUtility() {
    this.addingUtility = true;
    if (this.utilities.length == 0) {
      this.loadingUtilities = true;

      const stockUtilites = [
        { id: 1, name: 'Gas', status: 'ok' }
      ];

      stockUtilites.map((utility: any, index: number) => {
        console.log(this.selectedBuilding);
        if (this.selectedBuilding.utilities.length > 0) {
          this.selectedBuilding.utilities.map((su: any) => {
            if (utility.name != su.name) this.utilities.push(utility);
          });
        } else {
          this.utilities = stockUtilites;
        }
      });

      this.loadingUtilities = false;
    }    
  }

  addUtility(utility: any) {
    // TODO updateUtilitiy to building
    // 
    const payload: any = {
      name: utility.name,
      status: utility.status,
      building_id: this.selectedBuilding.id
    }
    console.log("=====>");
    // console.log();
    console.log(payload);
    this.store.createUtility(payload).subscribe({
      next: (data: any) => {
        this.loadBuilding(this.settlementId);
        this.addingUtility = false;
      },
      error: (err: any) => {
        console.log('error => ', err);
        this.toastr.error('Unidad no pudo ser creada.', 'Error');
      }
    })
  }

  removeUtility(utility: any) {
    this.store.destroyUtility(utility.id).subscribe({
      next: (data: any) => {
        this.toastr.success('Servicio eliminado con correctamente.', '¡Éxito!');
        this.loadBuilding(this.settlementId);
      },
      error: (err: any) => {
        this.toastr.error('Servicio no pudo ser eliminado.', 'Error');
      }
    })
  }

  cancelUtility() {
    this.addingUtility = false;
    this.loadingUtilities = false;
  }

  addTank(building: Building) {}

  removeTank(tanque: Building) {
    this.store.destroyTanque(tanque.id).subscribe({
      next: (data: any) => {
        this.toastr.success('Tanque eliminado con correctamente.', '¡Éxito!');
        this.loadBuilding(this.settlementId);
      },
      error: (err: any) => {
        this.toastr.error('Tanque no pudo ser eliminado porque está asociado a uno o más departamentos.', 'Error');
      }
    });
  }

  // selectUnitType(e: any) {
    // this.unitForm.patchValue({
      // unit_type_id: e.target.value
    // });
  // }

  async plusUnit(floor: any) {
    this.addingUnit = true;
    // this.buildUnitForm();
    // console.log('floor => ', floor);
    // this.unitForm.patchValue({
      // floor_id: floor.id
    // });
    this.floor_id = floor.id;
  }

  cancelUnit() {
    this.addingUnit = false;
    // this.unitForm.reset();
  }

  createUnit(form: any) {
    this.store.createUnit(form).subscribe({
      next: (data: any) =>  {
        this.toastr.success('Unidad se creó correctamente.', 'Éxito');
        this.addingUnit = false;
        this.setBuilding(this.selectedBuilding.id);
        window.location.reload();
      },
      error: (error: any) => {
        this.toastr.error('Unidad no pudo ser creada.', 'Error');
      }
    });
  }

  updateBuilding() {
    this.loading = true;

    if (this.buildingForm.invalid) {
      this.buildingForm.markAllAsTouched();
      this.toastr.error('Formulario inválido.', 'Error');
      this.loading = false;
      return;
    }

    this.store.updateBuilding(this.building.id, this.buildingForm.value).subscribe({
      next: (data: any) =>  {
        this.toastr.success('Edificio se actualizó correctamente.', 'Éxito');
        this.loading = false;
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Edificio no pudo ser actualizado.', 'Error');
        this.loading = false;
      }
    })
  }

  addBuilding() {
    new bs.Modal(<HTMLInputElement>document.getElementById('modalBuilding')).show();
  }

  createBuilding(event: any) {
    const payload = {
      name: event.name,
      clave: event.clave,
      settlement_id: this.settlementId,
    };

    this.store.createBuilding(payload).subscribe({
      next: (data: any) => {
        this.toastr.success('Edificio se creó correctamente.', 'Éxito');
        window.location.reload();
      },
      error: (err: any) => {
        this.toastr.error('Edificio no pudo ser creado.', 'Error');
      }
    });
  }
}
