import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core'; // useful for typechecking
import { SettlementsService } from 'src/app/services/settlements.service';
import { ToastrService } from 'ngx-toastr';
import { BreadcrumbService } from 'xng-breadcrumb';
import { TasksService } from 'src/app/services/tasks.service';
import { DateService } from 'src/app/shared/services/date.service';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { TextService } from 'src/app/shared/services/text.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { CatalogsService } from 'src/app/shared/services/catalogs.service';
//
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import esLocale from '@fullcalendar/core/locales/es';

@Component({
  selector: 'app-maintenances',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-3">
        <!-- xng-breadcrumb ></!-->
        <div class="row mb-4 mt-4">
          <div class="col-md-5">
            <h1 class="mt-2">Servicio técnico</h1>
          </div>
          <div class="col-md-7 mt-2 text-end">
            <!-- button class="btn btn-outline-info btn-lg rounded-5 shadow border border-3 border-info" routerLink="tasks/dashboard">Dashboard</!-->
            <button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" *ngIf="permissions?.modules.operation.service.calendario.write" routerLink="tasks/create">
              <fa-icon [icon]="icons.faPlus" class="me-2"></fa-icon>
              Agregar tarea
            </button>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-12">
            <form>
              <div class="row">
                <div class="col-md-8">
                  <!-- h3>Filtros</!-->
                </div>
                <div class="col-md-4 text-end mb-2">
                  <button class="btn btn-link text-danger mt-0 pt-0" *ngIf="searchActive" (click)="clearFilter()">
                    <fa-icon [icon]="icons.faTimes" size="lg" [spin]="loading" class="me-3"></fa-icon>
                    Limpiar filtros
                  </button>
                </div>
                <div class="col-md-3">
                  <div class="form-group mb-0">
                    <label for="description" class="form-label ms-2 sr-only">Condominio</label>
                    <select class="form-select rounded-5 bg-transparent" (change)="selectSettlement($event)">
                      <option selected [selected]="true" disabled>Condominio</option>
                      <option *ngFor="let settlement of settlements" [value]="settlement.id" >{{settlement.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group mb-0">
                    <label for="description" class="form-label ms-2 sr-only">Actividad</label>
                    <select class="form-select rounded-5 bg-transparent" (change)="selectCategory($event)">
                      <option selected [selected]="true" disabled>Actividad</option>
                      <option *ngFor="let category of taskCategories" [value]="category.id" >{{category.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group mb-0">
                    <label for="description" class="form-label ms-2 sr-only">Técnico</label>
                    <select class="form-select rounded-5 bg-transparent" (change)="selectTecnico($event)">
                      <option selected [selected]="true" disabled>Técnico</option>
                      <option *ngFor="let technician of technicians" [value]="technician.id" >{{technician.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group mb-0">
                    <label for="description" class="form-label ms-2 sr-only">Status</label>
                    <select class="form-select rounded-5 bg-transparent" (change)="selectStatus($event)">
                      <option selected [selected]="true" disabled>Estatus</option>
                      <option *ngFor="let stat of catalog.status" [value]="stat.id" >{{stat.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
            <div class="card border-0 rounded-4 shadow mt-5 mb-5">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <full-calendar [options]="calendarOptions"></full-calendar>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- div class="col-md-4">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <app-form-task
                  (taskData)="updateTask($event)"
                  [task]="task"
                  [profile]="profile"
                  [loading]="loading"
                ></app-form-task>
              </div>
            </div>
          </!-->
        </div>
      </div>
    </div>
    <app-calendar-event
      [top]="top"
      [left]="left"
      [isVisible]="isVisible"
      [selectedTask]="selectedTask"
      [taskCategories]="taskCategories"
      [settlements]="settlements"
      [technicians]="technicians"
      [priority]="catalog.priority"
      [status]="catalog.status"
      (isVisibleChange)="toggleVisibility($event)"
    ></app-calendar-event>
  `,
  styleUrls: []
})
export class MaintenancesComponent implements OnInit {
  profile: any = {};
  permissions: any = {};

  top: number = 0; // Default top position
  left: number = 0; // Default left position
  isVisible: boolean = false; // Default visibility
  
  @ViewChild('calendar') calendarComponent: FullCalendarComponent | undefined;
  calendarObj: any = {};
  calendarOptions: CalendarOptions = {
    themeSystem: 'bootstrap5',
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin, timeGridPlugin], // interactionPlugin
    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay' // user can switch between the two
    },
    locale: esLocale,
    selectable: true,
    nowIndicator: true,
    displayEventTime: true,
    eventDisplay: 'block',
    eventTimeFormat: { // like '14:30:00'
      hour: 'numeric',
      minute: '2-digit',
      meridiem: 'short'
    },
    businessHours: {
      daysOfWeek: [ 1, 2, 3, 4, 5, 6 ], // Monday - Thursday
      startTime: '9:00', // a start time (10am in this example)
      endTime: '18:00', // an end time (6pm in this example)
    },
    eventClick: this.handleDateClick.bind(this),
    datesSet: this.handleDateChange.bind(this)
  };

  loading: boolean = false;

  payload: any = {
    page: 1,
    per_page: 1000,
    status: [1,2,3]
  }

  fullTasks: any[] = [];
  taskCategories: any[] = [];
  settlements: any = [];
  buildings: any = [];
  technicians: any = [];

  searchActive: boolean = false;
  task: any = {};
  selectedTask: any = {};
  

  constructor(
    private store: SettlementsService,
    private taskService: TasksService,
    private toastr: ToastrService,
    private breadcrumbService: BreadcrumbService,
    private dateService: DateService,
    private textService: TextService,
    private eRef: ElementRef,
    public catalog: CatalogsService,
    public icons: IconsService,
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile_id = this.profile.id;
  }

  ngOnInit(): void {
    const d = new Date();

    let periodos = this.dateService.getStartAndEndDateOfMonth(
      parseInt(d.getFullYear().toString()), parseInt(`${d.getMonth()+1}`));

    this.payload.due_date_since = periodos.start_date;
    this.payload.due_date_to = periodos.end_date;
    
    this.loadTechnicians();
    this.loadSettlements();
    this.loadTaskCategories();
    
    this.breadcrumbService.set('@index', 'Servicio técnico');
  }

  handleDateClick(arg: any) {
    this.top = arg.jsEvent.pageY;
    this.left = arg.jsEvent.pageX;
    this.isVisible = true;
    this.selectedTask = this.fullTasks.find((task: any) => task.id === Number(arg.event.id));  
  }

  handleDateChange(arg: any) {
    this.payload.due_date_since = arg.startStr.split('T')[0];
    this.payload.due_date_to = arg.endStr.split('T')[0];
    this.loadTasks(this.payload);
  }

  async loadTaskCategories() {
    this.taskService.getBuilders({ page: 1, per_page: 100, profile_id: 1 }).subscribe((cat: any) => {
      this.taskCategories = cat.data;
      this.loadTasks(this.payload);
    });    
  }

  loadSettlements() {
    let settlementPayload: any = {
      page: 1,
      per_page: 300,
      utility_id: this.profile.utility_id
    };
    this.store.getSettlements(settlementPayload).subscribe((data: any) => {
      this.settlements = data.data;
    });
  }

  loadTechnicians() {
    this.store.getUsers({ page: 1, per_page: 100, role_id: 3, profile_id: this.profile.id }).subscribe((data: any) => {
      this.technicians = data.data;
    });
  }

  loadTasks(payload: any) {
    this.taskService.getTasks(payload).subscribe({
      next: async (resp: any) => {
        let tasks: any[] = [];
        this.fullTasks = resp.data;
        await resp.data.map((task: any) => {

          let t: any = {
            id: task.id,
            title: this.textService.truncateTextAtWord(this.taskCategories.find((c: any) => c.id === task.builder_id).name, 15),
            start: `${task.due_date.split('.')[0]}`,
            end: `${this.dateService.addMinutesToDate(task.due_date, task.duration).split('.')[0]}`,
            display: 'block',
            startEditable: true,
            backgroundColor: this.catalog.status.find((p: any) => p.id === task.status).color,
            borderColor: this.catalog.status.find((p: any) => p.id === task.status).color,
            textColor: this.catalog.status.find((p: any) => p.id === task.status).textColor,
          };

          tasks.push(t);
        });

        console.log("TASKS ==========> ", tasks);
        //
        this.calendarOptions.events = tasks;
      },
      error: (err: any) => {
        this.toastr.error('No se ha podido cargar las tareas.', 'Error');
      }
    });
  }

  selectSettlement(event: any) {
    let buildingPayload: any = {
      page: 1,
      per_page: 50,
      settlement_id: event.target.value
    };
    
    this.store.getBuildings(buildingPayload).subscribe((data: any) => {
      this.buildings = data.data;
      this.searchActive = true;
    });

    this.payload.settlement_id = event.target.value;
    this.loadTasks(this.payload);
  }

  selectBuilding(event: any) {

    this.payload.building_id = event.target.value;
    this.loadTasks(this.payload);
    this.searchActive = true;
  }

  selectCategory(event: any) {
    this.payload.builder_id = event.target.value;
    this.loadTasks(this.payload);
    this.searchActive = true;
  }

  selectTecnico(event: any) {
    this.payload.assignee_id = event.target.value;
    this.loadTasks(this.payload);
    this.searchActive = true;
  }

  selectStatus(event: any) {
    this.payload.status = event.target.value;
    this.loadTasks(this.payload);
    this.searchActive = true;
  }

  clearFilter() {
    this.payload = {
      page: 1,
      per_page: 1000,
      profile_id: this.profile.id
    }
    this.settlements = [];
    this.loadSettlements();
    this.buildings = [];
    this.technicians = [];
    this.loadTechnicians();
    this.taskCategories = [];
    this.loadTaskCategories();
    this.searchActive = false;
  }

  /*updateTask(event: any) {
    this.store.updateTask(event.id, event).subscribe({
      next: (resp: any) => {
        this.loading = false;
        this.toastr.success('Se actualizó la tarea correctamente.', 'Exito');
        this.loadTasks(this.payload);
      },
      error: (err: any) => {
        this.loading = false;
        this.toastr.error('No se ha podido actualizar la tarea.', 'Error');
      }
    });
  }*/

  toggleVisibility(event: any) {
    this.isVisible = event;
  }

}
