import { Component, OnInit } from '@angular/core';
import { TasksService } from 'src/app/services/tasks.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-activity-type-edit',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
      <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-9">
            <h1>Tipos Actividades</h1>
            <p>Configura los tipos de actividades que están disponibles para los servicios técnicos.</p>
          </div>
          <div class="col-md-3 text-end mt-4">
            
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                    <h4>Agregar tipo de actividad</h4>
                    <p>Configura el tipo de actividad con los siguientes campos.</p>
                    <app-form-activity-type (activityTypeData)="updateCategory($event)" [activity_type]="activity_type" [profile]="profile" [loading]="loading"></app-form-activity-type>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ActivityTypeEditComponent implements OnInit {
  loading: boolean = false;
  activity_type: any = null;
  profile: any = null;

  constructor(
    private tasks: TasksService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private toastr: ToastrService,
    private breadcrumbService: BreadcrumbService,
  ) { }

  ngOnInit(): void {
    this.profile = JSON.parse(localStorage.getItem('profile')!);
    this.activateRoute.params.subscribe(params => {
      this.tasks.getActivityType(params['id']).subscribe({
        next: (res: any) => {
          this.loading = false;
          this.activity_type = res;
        },
        error: (err: any) => {
          this.loading = false;
        }
      });
    });

    this.breadcrumbService.set('@index', 'Mantenimientos');
    this.breadcrumbService.set('@categories', 'Tipo de actividades');
    this.breadcrumbService.set('@categoryEdit', 'Editar tipo de actividad');
  }

  updateCategory(event: any) {
    this.tasks.updateActivityType(event.id, event).subscribe({
      next: (resp: any) => {
        this.loading = false;
        this.toastr.success('Se ha actualizado el tipo de actividad correctamente.', 'Tipo de actividad actualizada');
        this.router.navigateByUrl('/maintenances/activity-types');
      },
      error: (err: any) => {
        this.loading = false;
        this.toastr.error('No se ha podido actualizar el tipo de actividad.', 'Error');
      }
    });
  }

}
