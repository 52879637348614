import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';

@Component({
  selector: 'app-modal-invoice-cancel',
  template: `
    <div #modalDeleteInvoice id="modalDeleteInvoice" class="modal fade" tabindex="-1"  aria-labelledby="modalDeleteInvoice" aria-hidden="true">
      <div class="modal-dialog modal-dialog-top">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-title">Cancelación de factura</div>
          </div>
          <div class="modal-body p-4">
            <div class="row">
              <div class="col-md-12">
                <form [formGroup]="invoiceDeleteForm" (ngSubmit)="deleteInovice()">
                  <div class="form-group">
                    <label for="payment_date">Motivo de cancelación</label>
                    <select class="form-select form-select-lg" formControlName="reason">
                      <option value="">Selecciona un motivo</option>
                      <option *ngFor="let reason of reasons" [value]="reason.id">{{ reason.name }}</option>
                    </select>
                  </div>
                  <div class="form-group text-end mt-4 mb-3">
                    <button type="submit" class="btn btn-danger btn-lg rounded-5" [disabled]="loading">
                      Cancelar factura
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ModalInvoiceCancelComponent implements OnInit {
  @Input() invoice: any;
  // @Input() bill: any;
  @Output() invoiceData = new EventEmitter<any>();
  @ViewChild('modalDeleteInvoice', { read: ElementRef, static: false }) modalDeleteInvoice !: ElementRef;

  profile: any = {};
  loading: boolean = false;
  invoiceDeleteForm: FormGroup = new FormGroup({});

  // reasons: any = [
    // { id: 1, name: "Errores o correcciones en las fechas de pago" },
    // { id: 2, name: "Errores atribuibles al cliente por datos fiscales incorrectos." },
    // { id: 3, name: "Errores o discrepancias en la lectura del consumo" },
    // { id: 4, name: "Correcciones en las facturas de público en general" },
    // { id: 5, name: "Si la factura tiene relacionado un complemento de pago, este debe ser cancelado también" },
  // ];

  reasons: any = [
    { id: 1, name: "Comprobante emitido con errores con relación" },
    { id: 2, name: "Comprobante emitido con errores sin relación" },
    { id: 3, name: "No se llevó a cabo la operación" },
    { id: 4, name: "Operación nominativa relacionada en una factura global" },
  ];

  constructor(
    private store: SettlementsService,
    private toastr: ToastrService,
    private fb: FormBuilder,
  ) {
    this.profile = JSON.parse(localStorage.getItem('profile')!);
  }

  ngOnInit(): void {
    this.buildInvoiceDeleteForm();
  }

  buildInvoiceDeleteForm() {
    this.invoiceDeleteForm = this.fb.group({
      // name: ['', [Validators.required]],
      // clave: ['', [Validators.required]],
      reason: ['', [Validators.required]],
    });
  }

  deleteInovice() {
    this.invoiceData.emit(this.invoiceDeleteForm.value);
  }
}
