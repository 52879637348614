import { map } from 'rxjs';
import { TasksService } from 'src/app/services/tasks.service';
import { Component, OnInit } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ActivatedRoute } from '@angular/router';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateService } from 'src/app/shared/services/date.service';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { BreadcrumbService } from 'xng-breadcrumb';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-technicians',
  template: `
    <div class="row">
      <div class="col-sm-11 offset-sm-1 col-md-11 offset-md-1 col-lg-8 offset-lg-4 col-xl-8 offset-xl-3 mt-3">
        <xng-breadcrumb ></xng-breadcrumb>
        <div class="row">
          
          <div class="col-md-6 mt-2">
            <div class="row mt-3 mb-3">
              <div class="col-md-3">
                <div class="avatar-container text-center" style="left: 0 !important;" *ngIf="technician.id">
                  <div class="image-container border border-4" style="width: 100px; height: 100px;">
                    <!-- img [src]="loadImg(technician?.avatar)" width="100%" -->
                  </div>
                </div>
              </div>
              <div class="col-md-9">
                <h1>{{technician.name}} {{technician.last_name}}</h1>              
                <p class="mb-0">Tel. {{technician.phone}}</p>
                <p class="mb-0">Correo {{technician.email}}</p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card rounded-4">
              <div class="card-body">
                <div class="row">
                <div class="col-md-4 text-center">
                    <h1 [countUp]="avg" [duration]="2000" style="font-size: 50px;"></h1>
                    <h5>Calificación</h5>
                  </div>
                  <div class="col-md-4 text-center">
                    <h1 [countUp]="complete" [duration]="2000" style="font-size: 50px;"></h1>
                    <h5>Tareas completas</h5>
                  </div>
                  <div class="col-md-4 text-center">
                    <h1 [countUp]="pending" [duration]="2000" style="font-size: 50px;"></h1>
                    <h5>Tareas pendientes</h5>
                  </div>                  
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 mt-3">
            <div class="card rounded-4 shadow mb-5">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12"><h3>Resumen por actividad</h3></div>
                  <div class="col-md-6">
                    <app-filter-advanced [toDate]="toDate" [fromDate]="fromDate" (dateRange)="updateDate($event)"></app-filter-advanced>                  
                  </div>
                  <div class="col-md-3 text-start">
                    <button class="btn btn-outline-primary shadow-sm" (click)="search()">Buscar</button>
                  </div>
                  <div class="col-md-3 text-end">
                    <button class="btn btn-outline-primary shadow-sm">Generar reporte</button>
                  </div>
                  <div class="col-md-12 mt-3 mb-5" *ngIf="!loading">
                    <table class="table table-light table-striped-columns">
                      <tr class="border-bottom">
                        <th>Calificación</th>
                        <th>Fecha</th>
                        <th>Condominio</th>
                        <th>Departamento</th>
                      </tr>
                      <tr *ngFor="let grade of grades">
                        <td>{{grade.grade | number : '1.2-2'}}</td>
                        <td>{{grade.updated_at | date: 'dd/MM/YY'}}</td>
                        <td>{{grade.settlement?.name}}</td>
                        <td>{{grade.unit?.name}}</td>
                        <td>{{grade.builder?.name}}</td>
                        <!-- td>{{grade.gas_meter_read_id}}</!-->
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-12 mt-3 mb-3" *ngIf="grades.length==0">
                    <div class="list-group rounded-4 shadow" >
                      <div class="list-group-item text-center py-5" *ngIf="loading">
                        <fa-icon [icon]="faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                        <p class="text-muted">Cargando...</p>
                      </div>
                      <div class="list-group-item text-center py-5" *ngIf="!loading">
                        <img src="/assets/images/empty-state.png" width="240px">
                        <h5 class="text-muted">No se encontraron registros</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class DashboardTechniciansComponent implements OnInit {
  apiUrl: string = environment.AWS_REST_WSPREFIX;
  grades: any = [];
  complete: number = 0;
  pending: number = 0;
  avg: number = 0;
  profile: any = {};
  technicianId: number = 0;
  technician: any = {};

  
  a = new Date();
  fromDate; // : any = "2024-02-01"; // Date = new Date();
  toDate; // : any = "2024-02-29";

  faCircleNotch = faCircleNotch;
  

  loading: boolean = false;

  constructor(
    private store: SettlementsService,
    private tasksService: TasksService,
    private activateRoute: ActivatedRoute,
    private calendar: NgbCalendar,
    private dateService: DateService,
    private breadcrumbService: BreadcrumbService,
  ) {
    this.fromDate = new NgbDate(this.a.getFullYear(), this.a.getMonth()+1, 1); // new Date(this.a.getFullYear(), this.a.getMonth()+1, 0);
    this.toDate = new NgbDate(this.a.getFullYear(), this.a.getMonth()+1, 29);
    console.log('fromDate ==> ', this.fromDate);
    console.log('toDate ==> ', this.toDate);
    // console.log('FORMAT fromDate ==> ', this.fromDate.toISOString().split('T')[0]);
    this.profile = JSON.parse(localStorage.getItem('profile')!);
    this.activateRoute.params.subscribe(params => {
      this.technicianId = params['id'];
      this.loadUser(this.technicianId);      
    });
  }

  ngOnInit(): void {
    this.loading = true;
    let total: number = 0;
    let gradx = 0;
      let avg = 0;
    this.loadTechStats();
    this.loadTechSummary(this.technicianId);
    this.loadTasks(this.technicianId);
  }

  loadTasks(id: number) {
    const d = new Date();
    let periodos = this.dateService.getStartAndEndDateOfMonth(parseInt(d.getFullYear().toString()), parseInt(`${d.getMonth()+1}`));
    this.tasksService.getTasks({
      page: 1,
      per_page: 100,
      assignee_id: id,
      due_date_since: periodos.start_date,
      due_date_to: periodos.end_date
    }).subscribe((tasks: any) => {
      console.log('tasks => ', tasks);
      this.complete = tasks.data.filter((task: any) => task.status == 3).length;
      this.pending = tasks.data.filter((task: any) => task.status == 1).length;
      // this.tasks = tasks.data;
    });
  }

  loadTechStats(){
  this.tasksService.getTechStatsSummary(this.technicianId, {
      start_date: `${this.fromDate['year']}-${this.fromDate['month']}-${this.fromDate['day']}`,
      end_date: `${this.toDate['year']}-${this.toDate['month']}-${this.toDate['day']}`
    }).subscribe(async (data: any) => {
      console.log('data ==> ', data);
      // let calificaciones: any = [];
      // total = data.length;
      
      // await data.data.map((item: any) => {
        // this.store.getSettlement(item.settlement_id).subscribe((stl: any) => {
          // this.tasksService.getActivityType(item.activity_type_id).subscribe((activity: any) => {
            // // console.log('activity ==> ', activity);
            // item.settlement = stl;
            // item.activity = activity;
            // //
            // // console.log('grade ==> ', item.grade);
            // // gradx += parseInt(item.grade);
            // // console.log('gradx ==> ', gradx);
            // // calificaciones.push(item);
          // });
        // });
      // });
      
      this.grades = data;
      this.loading = false;
    });
  }

  loadTechSummary(id: number) {
    console.log('PERIODO ===> ', this.dateService.getPeriodo(new Date()))
    this.tasksService.getTechStatSummaries({
      assignee_id: id,
      period: 2, // this.dateService.getPeriodo(new Date())
    }).subscribe((data: any) => {
      console.log('SUMMARY data ==> ', data);
      let local_avg = 0;
      data.data.map((item: any) => {
        local_avg += item.avg_grade;
      });
      if (data.data.length > 0) {
        this.avg = local_avg / data.data.length;
      }
      // this.avg = data.avg_grade;
    });
  }

  search() {
    this.loading = true;
    this.loadTechStats();
  }

  loadUser(id: number) {
    this.store.getUser(id).subscribe({
      next: (data: any) => {
        this.technician = data;
      },
      error: (error: any) => {}
    });
  }

  loadImg(img: any) {
    return `${this.apiUrl}/storage/${img.blob.key.substring(0,2)}/${img.blob.key.substring(2,4)}/${img.blob.key}`;
  }

  updateDate(event: any) {
    this.fromDate = event.from;
    this.toDate = event.to;
  }

}
