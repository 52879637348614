import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettlementsService } from 'src/app/services/settlements.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ValidateConfirmpass } from '../../../../components/confirmpass.validator';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-profile',
  template: `
    <div class="row">
      <div class="col-sm-10 offset-sm-1 col-md-9 offset-md-2 col-lg-7 offset-lg-4 col-xl-7 offset-xl-4 col-xxl-8 offset-xxl-3 mt-3">
        <div class="row mb-4">
          <div class="col-md-5">
            <h1>Perfil</h1>
            <p>Configura la información de tu cuenta.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <!-- button class="btn btn-primary btn-lg rounded-5" routerLink="/services/service">Agregar usuario</!-->
          </div>
        </div>
        <div class="row">
          <div class="col-md-7">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                  <app-form-user [user]="user" [roles]="roles" (updateUser)="saveUser($event)"></app-form-user>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-12">
                    <form [formGroup]="passwordForm" (ngSubmit)="updatePassword()">
                      <div class="form-group required mt-4">
                        <label for="password" class="form-label ms-2">Contraseña</label>
                        <div class="input-group">
                          <input
                            id="password"
                            [type]="showPassword ? 'text' : 'password'"
                            formControlName="password"
                            class="form-control form-control-lg rounded-3 rounded-end-0 pt-3 pb-3 border-end-0"
                            placeholder="Contraseña"
                          >
                          <span class="input-group-append bg-white border-start-0">
                            <span class="input-group-text bg-transparent rounded-3 rounded-start-0 pt-3 pb-4 border-start-0 ">
                              <!-- i class="icon ion-ios-search"></!-->
                              <fa-icon (click)="togglePassword()" *ngIf="showPassword" [icon]="faEye" class="text-dark me-2"></fa-icon>
                              <fa-icon (click)="togglePassword()" *ngIf="!showPassword" [icon]="faEyeSlash" class="text-dark me-2"></fa-icon>
                            </span>
                          </span>
                        </div>
                      </div>
                      <div class="form-group required mt-4">
                        <label for="password_confirmation" class="form-label ms-2">Confirmar contraseña</label>
                        <input type="text" formControlName="password_confirmation" class="form-control form-control-lg" id="password_confirmation" placeholder="">
                      </div>

                    <div class="form-group mt-4">
                        <password-strength-meter [password]="passwordForm.value.password"></password-strength-meter>
                      </div>
                      <small id="passwordHelpBlock" class="form-text text-muted text-start text-sm">
                        Su contraseña debe tener entre 8 y 20 caracteres, contener letras, números, caracteres especiales, y no debe contener espacios, ni emojis.
                      </small>

                      <div *ngIf="lf['password'].touched && lf['password'].invalid" class="text-start">
                        <div *ngIf="lf['password'].errors?.['required']" class="text-danger text-sm">La contraseña es requerido.</div>
                        <small *ngIf="lf['password'].errors?.['minlength']" class="text-danger text-sm">La contraseña contener un mínimo 8 caracteres.</small>
                        <small *ngIf="lf['password'].errors?.['maxlength']" class="text-danger text-sm">La contraseña contener un máximo 20 caracteres.</small>
                        <small *ngIf="!lf['password'].errors?.['minlength'] && lf['password'].errors?.['pattern']" class="text-danger text-sm">La contraseña debe contener al menos un número (0-9), al menos una letra minúscula (a-z), al menos una letra mayúscula (A-Z), y al menos un caracter especial ($@#!%*?&)</small>
                      </div>
                      <div *ngIf="lf['password_confirmation'].touched && lf['password_confirmation'].invalid" class="text-start">
                        <div *ngIf="lf['password_confirmation'].errors?.['required']" class="text-danger text-sm">La confirmación de la contraseña es requerida.</div>
                        <small *ngIf="lf['password_confirmation'].errors?.['noMatch']" class="text-danger text-sm">Las contraseñas deben coincidir.</small>
                      </div>

                      <div class="form-group mt-3 text-end">
                        <button type="submit" class="btn btn-outline-warning btn-lg rounded-5">Actualizar</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  `,
  styleUrls: []
})
export class ProfileComponent implements OnInit {
  userId: number = 0;
  user: any = {};
  roles: any = [];
  showPassword: boolean = false;
  passwordForm: FormGroup = new FormGroup({});
  profile: any = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;

  faEye = faEye;
  faEyeSlash = faEyeSlash;

  constructor(
    private store: SettlementsService,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) { 
    this.activateRoute.params.subscribe(params => {
      this.userId = params['id'];
      this.loadUser(this.userId);
    });

    this.store.getRoles().subscribe({
      next: (data: any) => {
        this.roles = data;
      },
      error: (error: any) => {}
    });
  }
  
  ngOnInit(): void {
    this.initForm();
  }

  get lf() {
    return this.passwordForm.controls;
  }

  initForm() {
    this.passwordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      password_confirmation: ['', [Validators.required, ValidateConfirmpass]]
    });
  }

  loadUser(id: number) {
    this.store.getUser(id).subscribe({
      next: (data: any) => {
        console.log("data => ", data);
        this.user = data;
        // this.buildForm();
      },
      error: (error: any) => {}
    });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  saveUser(user: any) {
    console.log('user!!!! => ', user);
    this.store.updateUser(this.userId, user).subscribe({
      next: (data: any) => {
        console.log('data => ', data);
        this.toastr.success('Éxito', 'Usuario actualizado correctamente.');
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Error', 'Ocurrió un error al actualizar el usuario');
      }
    });
  }

  updatePassword() {
    this.store.updateUser(this.userId, this.passwordForm.value).subscribe({
      next: (data: any) => {
        console.log('data => ', data);
        this.toastr.success('Éxito', 'Contraseña actualizada correctamente.');
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Error', 'Ocurrió un error al actualizar la contraseña');
      }
    });

  }
  
}
