import { Component } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { faEdit, faPlus, faTrashAlt, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BreadcrumbService } from 'xng-breadcrumb';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-profile-index',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
        <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-5">
            <h1>Pefiles</h1>
            <p>Configura las perfiles.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" routerLink="create" *ngIf="permissions?.modules.config.profiles.write">
              <fa-icon [icon]="icons.faPlus" class="me-2"></fa-icon>
              Agregar perfil
            </button>
          </div>
        </div>
        
        <div class="row mb-4">
          <div class="col-md-7">
            <!-- h2 class="mb-0">Habitacionales</!-->
          </div>
          <div class="col-md-5">
            <form [formGroup]="searchBox" (ngSubmit)="searchProfiles($event)">
              <input class="form-control form-control-lg bg-transparent rounded-5" placeholder="Buscar..." formControlName="search">
            </form>
          </div>
          <div class="col-md-12 mt-5 pb-3">
            <div class="list-group rounded-4 shadow overflow-hidden" *ngIf="profiles.length>0">
              <div class="list-group-item p-0" *ngFor="let profile of profiles | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
                <app-item-profile [profile]="profile" [permissions]="permissions"></app-item-profile>
              </div>
            </div>
            <div class="list-group rounded-4 shadow" *ngIf="profiles.length==0">
              <div class="list-group-item text-center py-5" *ngIf="loading">
                <fa-icon [icon]="icons.faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                <p class="text-muted">Cargando...</p>
              </div>
              <div class="list-group-item text-center py-5" *ngIf="!loading">
                <img src="/assets/images/empty-state.png" width="240px">
                <h5 class="text-muted">No se encontraron registros</h5>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-end mt-4" *ngIf="profiles.length>0">
            <pagination-controls
              (pageChange)="p = $event"
              (pageChange)="pageChanged($event)"
              previousLabel="Prev."
              nextLabel="Sig."
              screenReaderPaginationLabel="Pagination"
              screenReaderPageLabel="page"                  
            ></pagination-controls>
          </div>
        </div>
          
      </div>
    </div>
  `,
  styleUrls: []
})
export class ProfileIndexComponent {
  loading: boolean = false;
  profiles: any[] = [];
  meta: any = {};
  searchBox: FormGroup = new FormGroup({});
  profile: any = {};
  permissions: any = {};

  payload: any = {
    page: 1,
    per_page: 10,
  };

  p: number = 1;

  constructor(
    private store : SettlementsService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private breadcrumbService: BreadcrumbService,
    public icons: IconsService
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
  }
  

  ngOnInit(): void {
    this.loadProfiles(this.payload);
    this.initForm();
    this.breadcrumbService.set('@amenidad', 'Amenidades');
  }

  loadProfiles(payload: any) {
    this.loading = true;
    this.store.getProfiles(payload).subscribe({
      next: (profiles: any) => {
        this.profiles = profiles.data;
        this.meta = profiles.meta;
        this.loading = false;
      },
      error: (err: any) => {
        console.log(err.status);
        this.loading = false;
        this.profiles = [];
        if (err.status === 500) {
          this.toastr.error('Ocurrió un error al obtener las amenidades.', 'Error');
        }
      }
    });
  }

  initForm() {
    this.searchBox = this.fb.group({
      search: ['']
    });
  }

  searchProfiles(event: any) {
    this.payload.page = 1;
    this.payload.name = this.searchBox.value.search;
    this.loadProfiles(this.payload);
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadProfiles(this.payload);
  }

  tag(status: any) {
    if (status === 'active') {
      return 'Activo';
    } else {
      return 'Inactivo';
    }
  }
}
