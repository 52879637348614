import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { faEdit, faCircleNotch, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-bills-settlement',
  template: `
  <div class="row">
    <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
      <div class="row mb-4">
        <div class="col-md-8">
          <h1>Historial de pagos</h1>
          <p>Consulta los pagos realizados.</p>
        </div>
        <div class="col-md-4 text-end mt-2">
          <!-- button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" >
            <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
            Agregar tanque</!-->
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <div class="card rounded-5 shadow">
            <div class="card-body py-4 px-5">
              <div class="row">
                <div class="col-md-7">
                  <!-- h2 class="mb-0">Habitacionales</!-->
                </div>
                <div class="col-md-5">
                  <form [formGroup]="searchBox" (ngSubmit)="searchBills($event)">
                    <input class="form-control form-control-lg" placeholder="Buscar..." formControlName="search">
                  </form>
                </div>
                <div class="col-md-12 mt-5">
                  <div class="list-group rounded-4 shadow" >
                    <!-- *ngIf="bills.length>0"  | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries } -->
                    <div class="list-group-item bg-light px-3 pt-3" *ngFor="let building of buildings">
                      <div class="row">
                        <div class="list-group">
                          <div class="list-group-item bg-light px-3 pt-3" *ngFor="let floor of building.floors">
                            <li *ngFor="let unit of floor.units">
                              {{unit.name}}
                            </li>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                  <div class="list-group rounded-4 shadow" *ngIf="bills.length==0">
                  <div class="list-group-item text-center py-5" *ngIf="loading">
                    <fa-icon [icon]="faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                    <p class="text-muted">Cargando...</p>
                  </div>
                  <div class="list-group-item text-center py-5" *ngIf="!loading">
                    <img src="/assets/images/empty-state.png" width="240px">
                    <h5 class="text-muted">No se encontraron registros</h5>
                  </div>
                </div>
                </div>
                <div class="col-md-12 text-end mt-4" *ngIf="bills.length>0">
                  <pagination-controls
                    (pageChange)="p = $event"
                    (pageChange)="pageChanged($event)"
                    previousLabel="Prev."
                    nextLabel="Sig."
                    screenReaderPaginationLabel="Pagination"
                    screenReaderPageLabel="page"
                  ></pagination-controls>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  `,
  styleUrls: []
})
export class BillsSettlementComponent implements OnInit {
  loading: boolean = false;
  bills: any = [];
  meta: any = {};
  searchBox: FormGroup = new FormGroup({});

  buildings: any = [];

  faEdit = faEdit;
  faPlus = faPlus;
  faTrashAlt = faTrashAlt;
  faCircleNotch = faCircleNotch;

  payload: any = {
    page: 1,
    per_page: 10,
  };

  p: number = 1;
  
  constructor(
    private store: SettlementsService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loadBills(this.payload);
  }

  loadBills(payload: any) {
    this.loading = true;
    this.store.getBuildingsBySettlement(34).subscribe({
      next: (data: any) => {
        this.buildings = data.sort((a: any, b: any) => a.id -b.id);
        this.loading = false;
        console.log("buildings => ", this.buildings);
        // this.selectedBuilding = this.buildings[0];
        // this.setBuilding(this.buildings[0].id);
      },
      error: (e: any) => {
        console.log('error => ', e);
        this.toastr.error('Edificios no pudieron ser cargados.', 'Error');
        this.loading = false;
      }
    });
  }

  initForm() {
    this.searchBox = this.fb.group({
      search: ['']
    })
  }

  searchBills(event: any) {
    this.payload.page = 1;
    this.payload.name = this.searchBox.value.search;
    this.loadBills(this.payload);
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadBills(this.payload);
  }

}
