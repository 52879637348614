import { Component } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Router } from '@angular/router';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-payment-concepts',
  template: `
    <div class="row">
    <div class="col-sm-11 offset-sm-1 col-md-11 offset-md-1 col-lg-8 offset-lg-4 col-xl-8 offset-xl-3 mt-5">        
        <div class="row mb-4 mt-4">
          <div class="col-md-7">
            <h1>Conceptos de pago</h1>
            <p>Configura los conceptos del sistema.</p>
          </div>
          <div class="col-md-5 mt-4 text-end">
            <button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" routerLink="create">
              <fa-icon [icon]="icons.faPlus" class="me-2"></fa-icon>
              Agregar concepto
            </button>
          </div>
          <xng-breadcrumb ></xng-breadcrumb>
        </div>
            <div class="row mb-4 mt-4">
              <div class="col-md-7">
                <!-- h2 class="mb-0">Habitacionales</!-->
              </div>
              <div class="col-md-5">
                <form [formGroup]="searchBox" (ngSubmit)="searchConcepts($event)">
                  <input class="form-control form-control-lg rounded-5 border border-2 bg-transparent" placeholder="Buscar..." formControlName="search">
                </form>
              </div>
              <div class="col-md-12 mt-5 pb-3">
                <div class="list-group rounded-4 shadow" *ngIf="concepts.length>0">
                  <div class="list-group-item overflow-hidden p-0" *ngFor="let concepto of concepts | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
                    <div class="row">
                      <div class="col-md-6 ps-5 py-3">
                        <h4 class="mb-0">#{{concepto.id}} {{concepto.name}}</h4>
                        <p class="mb-0">{{concepto.codigo_unidad}} - {{concepto.clave}}</p>
                        
                      </div>
                      <div class="col-md-5 text-end py-3 pe-3">
                        <h4 class="mb-0">{{concepto.amount | currency: 'USD'}}</h4>
                        <p class="mb-0 text-muted">Actualizado: {{concepto.updated_at | date: 'dd/MM/yyyy'}}</p>
                      </div>
                      <div class="col-md-1 clearfix text-center p-0" routerLink="{{concepto.id}}">
                        <div class="hover-info border-start p-0 pt-4 pe-3" style="height: 100%;">
                          <fa-icon [icon]="icons.faEdit" size="1x"></fa-icon>
                        </div>
                        
                          
                        
                        <!-- button class="btn btn-outline-danger text-end ms-2" routerLink="/concepto/{{concepto.id}}">
                          <fa-icon [icon]="icons.faTrashAlt" size="1x" class="text-danger"></fa-icon>
                        </!-->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-group rounded-4 shadow" *ngIf="concepts.length==0">
                  <div class="list-group-item text-center py-5" *ngIf="loading">
                    <fa-icon [icon]="icons.faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                    <p class="text-muted">Cargando...</p>
                  </div>
                  <div class="list-group-item text-center py-5" *ngIf="!loading">
                    <img src="/assets/images/empty-state.png" width="240px">
                    <h5 class="text-muted">No se encontraron registros</h5>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-end mt-4" *ngIf="concepts.length>0">
                <pagination-controls
                  (pageChange)="p = $event"
                  (pageChange)="pageChanged($event)"
                  previousLabel="Prev."
                  nextLabel="Sig."
                  screenReaderPaginationLabel="Pagination"
                  screenReaderPageLabel="page"
                ></pagination-controls>
              </div>
            </div>
          
      </div>
    </div>
  `,
  styleUrls: []
})
export class PaymentConceptsComponent {
  loading: boolean = false;
  concepts: any[] = [];
  meta: any = {};
  searchBox: FormGroup = new FormGroup({});

  payload: any = {
    page: 1,
    per_page: 10,
  };

  p: number = 1;

  constructor(
    private store : SettlementsService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    public icons: IconsService
  ) {}

  ngOnInit(): void {
    this.loadConcepts(this.payload);
    this.initForm();
    this.breadcrumbService.set('@concept', 'Conceptos');
  }

  loadConcepts(payload: any) {
    this.loading = true;
    this.store.getPaymentConcepts(payload).subscribe({
      next: (concepts: any) => {
        this.concepts = concepts.data;
        this.meta = concepts.meta;
        this.loading = false;
        this.router.navigate(['/pagos/conceptos']);
      },
      error: (err: any) => {
        console.log(err.status);
        this.loading = false;
        this.concepts = [];
        if (err.status === 500) {
          this.toastr.error('Ocurrió un error al obtener las concepto.', 'Error');
        }
      }
    });
  }

  initForm() {
    this.searchBox = this.fb.group({
      search: ['']
    });
  }

  searchConcepts(event: any) {
    this.payload.page = 1;
    this.payload.name = this.searchBox.value.search;
    this.loadConcepts(this.payload);
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadConcepts(this.payload);
  }

}
