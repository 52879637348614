import { Component } from '@angular/core';
import { SettlementsService } from 'src/app/services/settlements.service';
import { faEdit, faPlus, faTrashAlt, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BreadcrumbService } from 'xng-breadcrumb';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-orders',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
      <xng-breadcrumb ></xng-breadcrumb>
        <div class="row mb-4 mt-4">
          <div class="col-md-5">
            <h1>Pedidos</h1>
            <p>Agrega pedidos asociados al condominio.</p>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <button class="btn btn-outline-primary btn-lg rounded-5 shadow border border-3 border-primary" routerLink="create">
              <fa-icon [icon]="icons.faPlus" class="me-2"></fa-icon>
              Agregar pedido
            </button>
          </div>
        </div>
        
            <div class="row mb-4">
              <div class="col-md-7">
                <!-- h2 class="mb-0">Habitacionales</!-->
              </div>
              <div class="col-md-5">
                <form [formGroup]="searchBox" (ngSubmit)="searchOrders($event)">
                  <input class="form-control form-control-lg bg-transparent rounded-5" placeholder="Buscar..." formControlName="search">
                </form>
              </div>
              <div class="col-md-12 mt-5 pb-3">
                <div class="list-group rounded-4 shadow overflow-hidden" *ngIf="orders.length>0">
                  <div class="list-group-item p-0" *ngFor="let order of orders | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
                    <app-item-order [order]="order" [profile]="profile" [permissions]="permissions" (refreshOrders)="loadOrders()"></app-item-order>
                  </div>
                </div>
                <div class="list-group rounded-4 shadow" *ngIf="orders.length==0">
                  <div class="list-group-item text-center py-5" *ngIf="loading">
                    <fa-icon [icon]="icons.faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon>
                    <p class="text-muted">Cargando...</p>
                  </div>
                  <div class="list-group-item text-center py-5" *ngIf="!loading">
                    <img src="/assets/images/empty-state.png" width="240px">
                    <h5 class="text-muted">No se encontraron registros</h5>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-end mt-4" *ngIf="orders.length>0">
                <pagination-controls
                  (pageChange)="p = $event"
                  (pageChange)="pageChanged($event)"
                  previousLabel="Prev."
                  nextLabel="Sig."
                  screenReaderPaginationLabel="Pagination"
                  screenReaderPageLabel="page"                  
                ></pagination-controls>
              </div>
            </div>
          
      </div>
    </div>
  `,
  styleUrls: []
})
export class OrdersComponent {
  loading: boolean = false;
  orders: any[] = [];
  meta: any = {};
  profile: any = {};
  permissions: any = {};
  searchBox: FormGroup = new FormGroup({});

  payload: any = {
    page: 1,
    per_page: 10,
  };

  p: number = 1;

  constructor(
    private store : SettlementsService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    public icons: IconsService,
  ) {
    this.profile = localStorage.getItem('profile') != null ? JSON.parse(localStorage.getItem('profile')!).profile : null;
    this.permissions = localStorage.getItem('permissions') != null ? JSON.parse(localStorage.getItem('permissions')!) : null;
    this.payload.profile_id = this.profile ? this.profile.id : 0;
  }
  

  ngOnInit(): void {
    this.loadOrders();
    this.initForm();
  }

  loadOrders() {
    this.loading = true;
    this.store.getPurchaseOrders(this.payload).subscribe({
      next: (orders: any) => {
        this.orders = orders.data;
        this.meta = orders.meta;
        this.loading = false;
      },
      error: (err: any) => {
        console.log(err.status);
        this.loading = false;
        this.orders = [];
        if (err.status === 500) {
          this.toastr.error('Ocurrió un error al obtener las amenidades.', 'Error');
        }
      }
    });
  }

  initForm() {
    this.searchBox = this.fb.group({
      search: ['']
    });
  }

  searchOrders(event: any) {
    this.payload.page = 1;
    this.payload.name = this.searchBox.value.search;
    this.loadOrders();
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadOrders();
  }
}
