import { CountUpDirective } from 'src/app/count-up.directive';
import { faEye, faEyeSlash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettlementsService } from 'src/app/services/settlements.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TasksService } from 'src/app/services/tasks.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ValidateConfirmpass } from '../../../../components/confirmpass.validator';

import * as bs from 'bootstrap';
import { DateService } from 'src/app/shared/services/date.service';

@Component({
  selector: 'app-technician-edit',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-4">
        <div class="row mb-4">
          <div class="col-md-5">            
            <h1>Técnico {{technician.name}}</h1>
            <p>Configura la información de la cuenta.</p>
            <xng-breadcrumb ></xng-breadcrumb>
          </div>
          <div class="col-md-7 mt-4 text-end">
            <div class="card rounded-4 shadow">
              <div class="card-body">
                <div class="row">
                  <div class="col-4 text-center">
                    <h1 [countUp]="avg" [duration]="2000" style="font-size: 50px;"></h1>
                    <a routerLink="/technicians/dashboard/{{technicianId}}" class="mb-0">Calificación</a>
                  </div>
                  <div class="col-4 text-center">
                    <h1 [countUp]="completed" [duration]="2000" style="font-size: 50px;"></h1>
                    <p class="mb-0">Tareas completadas</p>
                  </div>
                  <div class="col-4 text-center">
                    <h1 [countUp]="pending" [duration]="2000" style="font-size: 50px;"></h1>
                    <p class="mb-0">Tareas pendientes</p>
                  </div>                  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5">
            <div class="card border-0 rounded-4 shadow mb-5">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                  <app-form-technician [technician]="technician" [roles]="roles" (updateTechnician)="saveUser($event)"></app-form-technician>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-7">            
            <div class="card border-0 rounded-4 shadow mb-3">
              <div class="card-body p-4 pb-2">
                <div class="row">
                  <div class="col-md-6">
                    <h5 class="mt-2">Horario laboral</h5>
                  </div>
                  <div class="col-md-6">
                    <button type="button" class="btn btn-outline-primary btn-sm float-end" (click)="editSchedule()">Editar</button>
                  </div>
                  <div class="col-md-12 mt-3">
                    <table class="table table-striped">
                      <tr class="border-bottom">
                        <th colspan="4">Día</th>
                        <th colspan="1" class="text-end">Inicio</th>
                        <th colspan="1" class="text-end">Fin</th>
                      </tr>
                      <tr class="border-bottom">
                        <td colspan="4">Lunes</td>
                        <td colspan="1" class="text-end">{{work_schedule?.monday_start ? work_schedule?.monday_start + ':00' : '-'}}</td>
                        <td colspan="1" class="text-end">{{work_schedule?.monday_end ? work_schedule?.monday_end + ':00' : '-'}}</td>
                      </tr>
                      <tr class="border-bottom">
                        <td colspan="4">Mares</td>
                        <td colspan="1" class="text-end">{{work_schedule?.tuesday_start ? work_schedule?.tuesday_start + ':00' : '-'}}</td>
                        <td colspan="1" class="text-end">{{work_schedule?.tuesday_end ? work_schedule?.tuesday_end + ':00' : '-'}}</td>
                      </tr>
                      <tr class="border-bottom">
                        <td colspan="4">Miércoles</td>
                        <td colspan="1" class="text-end">{{work_schedule?.wednesday_start ? work_schedule?.wednesday_start + ':00' : '-'}}</td>
                        <td colspan="1" class="text-end">{{work_schedule?.wednesday_end ? work_schedule?.wednesday_end + ':00' : '-'}}</td>
                      </tr>
                      <tr class="border-bottom">
                        <td colspan="4">Jueves</td>
                        <td colspan="1" class="text-end">{{work_schedule?.thursday_start ? work_schedule?.thursday_start + ':00' : '-'}}</td>
                        <td colspan="1" class="text-end">{{work_schedule?.thursday_end ? work_schedule?.thursday_end + ':00' : '-'}}</td>
                      </tr>
                      <tr class="border-bottom">
                        <td colspan="4">Viernes</td>
                        <td colspan="1" class="text-end">{{work_schedule?.friday_start ? work_schedule?.friday_start + ':00' : '-'}}</td>
                        <td colspan="1" class="text-end">{{work_schedule?.friday_end ? work_schedule?.friday_end + ':00' : '-'}}</td>
                      </tr>
                      <tr class="border-bottom">
                        <td colspan="4">Sábado</td>
                        <td colspan="1" class="text-end">{{work_schedule?.saturday_start ? work_schedule?.saturday_start + ':00' : '-'}}</td>
                        <td colspan="1" class="text-end">{{work_schedule?.saturday_end ? work_schedule?.saturday_end + ':00' : '-'}}</td>
                      </tr>
                      <tr class="border-bottom">
                        <td colspan="4">Domingo</td>
                        <td colspan="1" class="text-end">{{work_schedule?.sunday_start ? work_schedule?.sunday_start + ':00' : '-'}}</td>
                        <td colspan="1" class="text-end">{{work_schedule?.sunday_end ? work_schedule?.sunday_end + ':00' : '-'}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="card border-0 rounded-4 shadow mb-3">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-6">
                    <h5 class="mt-2">Vehículo asignado</h5>
                  </div>
                  <div class="col-md-6">
                    <button type="button" class="btn btn-outline-primary btn-sm float-end" [disabled]="vehicle?.id" (click)="addVehicle()">Agregar</button>
                  </div>
                  <div class="col-md-12">
                    <div class="list-group rounded-4 shadow-sm mt-4" *ngIf="vehicle?.id">
                    <div class="list-group-item">
                      <div class="row">
                        <div class="col-md-4">
                          <h4>{{vehicle?.name}}</h4></div>
                        <div class="col-md-4">
                          <p class="mb-0">Marca: {{vehicle?.marca}}</p>
                          <p class="mb-0">Placas: {{vehicle?.plates}}</p>
                        </div>
                        <div class="col-md-4 text-end">
                          <button class="btn btn-outline-danger" (click)="removeVehicle(vehicle)">
                            <fa-icon [icon]="faTrashAlt" size="1x"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div class="list-group rounded-4 shadow-sm mt-4" *ngIf="!vehicle?.id">
                      <div class="list-group-item">
                        <p class="mb-0 text-center">Sin vehículo asignado</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card border-0 rounded-4 shadow mb-3">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-6">
                    <h5 class="mt-2">Habilidades</h5>
                  </div>
                  <div class="col-md-6">
                    <button type="button" class="btn btn-outline-primary btn-sm float-end" (click)="addSkills()">Editar</button>
                  </div>
                  <div class="col-md-12">
                    <div class="list-group rounded-4 shadow-sm mt-4" *ngIf="asignedSkills.length>0">
                      <div class="list-group-item" *ngFor="let skill of asignedSkills">
                        {{skill.skill.name}}
                      </div>
                    </div>
                    <div class="list-group rounded-4 shadow-sm mt-4" *ngIf="asignedSkills.length==0">
                      <div class="list-group-item">
                        <p class="mb-0 text-center">Sin habilidades asignadas</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card border-0 rounded-4 shadow mb-3">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-6">
                    <h5 class="mt-2">Herramientas asignadas</h5>
                  </div>
                  <div class="col-md-6">
                    <button type="button" class="btn btn-outline-primary btn-sm float-end" (click)="addTools()">Agregar</button>
                  </div>
                  <div class="col-md-12">
                    <div class="list-group rounded-4 shadow-sm mt-4" *ngIf="tools.length>0">
                      <div class="list-group-item" *ngFor="let tool of tools">
                        <div class="row">
                          <div class="col-md-8">{{tool.name}}</div>
                          <div class="col-md-4 text-end"><button type="button" class="btn btn-outline-danger btn-sm" (click)="removeTool(tool)"><fa-icon [icon]="faTrashAlt"></fa-icon></button></div>
                        </div>
                      </div>
                    </div>
                    <div class="list-group rounded-4 shadow-sm mt-4" *ngIf="tools.length==0">
                      <div class="list-group-item">
                        <p class="mb-0 text-center">Sin herramientas asignadas</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card border-0 rounded-4 shadow mb-5">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-12">
                  <form [formGroup]="passwordForm" (ngSubmit)="updatePassword()">
                      <div class="form-group required mt-4">
                        <label for="password" class="form-label ms-2">Contraseña</label>
                        <div class="input-group">
                          <input
                            id="password"
                            [type]="showPassword ? 'text' : 'password'"
                            formControlName="password"
                            class="form-control form-control-lg rounded-3 rounded-end-0 pt-3 pb-3 border-end-0"
                            placeholder="Contraseña"
                          >
                          <span class="input-group-append bg-white border-start-0">
                            <span class="input-group-text bg-transparent rounded-3 rounded-start-0 pt-3 pb-4 border-start-0 ">
                              <!-- i class="icon ion-ios-search"></!-->
                              <fa-icon (click)="togglePassword()" *ngIf="showPassword" [icon]="faEye" class="text-dark me-2"></fa-icon>
                              <fa-icon (click)="togglePassword()" *ngIf="!showPassword" [icon]="faEyeSlash" class="text-dark me-2"></fa-icon>
                            </span>
                          </span>
                        </div>
                      </div>
                      <div class="form-group required mt-4">
                        <label for="password_confirmation" class="form-label ms-2">Confirmar contraseña</label>
                        <input type="text" formControlName="password_confirmation" class="form-control form-control-lg" id="password_confirmation" placeholder="">
                      </div>

                    <div class="form-group mt-4">
                        <password-strength-meter [password]="passwordForm.value.password"></password-strength-meter>
                      </div>
                      <small id="passwordHelpBlock" class="form-text text-muted text-start text-sm">
                        Su contraseña debe tener entre 8 y 20 caracteres, contener letras, números, caracteres especiales, y no debe contener espacios, ni emojis.
                      </small>

                      <div *ngIf="lf['password'].touched && lf['password'].invalid" class="text-start">
                        <div *ngIf="lf['password'].errors?.['required']" class="text-danger text-sm">La contraseña es requerido.</div>
                        <small *ngIf="lf['password'].errors?.['minlength']" class="text-danger text-sm">La contraseña contener un mínimo 8 caracteres.</small>
                        <small *ngIf="lf['password'].errors?.['maxlength']" class="text-danger text-sm">La contraseña contener un máximo 20 caracteres.</small>
                        <small *ngIf="!lf['password'].errors?.['minlength'] && lf['password'].errors?.['pattern']" class="text-danger text-sm">La contraseña debe contener al menos un número (0-9), al menos una letra minúscula (a-z), al menos una letra mayúscula (A-Z), y al menos un caracter especial ($@#!%*?&)</small>
                      </div>
                      <div *ngIf="lf['password_confirmation'].touched && lf['password_confirmation'].invalid" class="text-start">
                        <div *ngIf="lf['password_confirmation'].errors?.['required']" class="text-danger text-sm">La confirmación de la contraseña es requerida.</div>
                        <small *ngIf="lf['password_confirmation'].errors?.['noMatch']" class="text-danger text-sm">Las contraseñas deben coincidir.</small>
                      </div>

                      <div class="form-group mt-3 text-end">
                        <button type="submit" class="btn btn-outline-warning btn-lg rounded-5">Actualizar</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <!-- div class="card border-0 rounded-4 shadow">
              <div class="card-body p-4">
                <h5>Tareas asignadas</h5>
                <div class="list-group rounded-4 shadow-sm mt-4" *ngIf="tasks.length>0">
                  <div class="list-group-item" *ngFor="let task of tasks">
                    <app-item-tiny-task [task]="task"></app-item-tiny-task>
                  </div>
                </div>
              </div>
            </! -->
          </div>
        </div>
      </div> 
    </div>
    <app-modal-work-schedule [technician]="technician" [work_schedule]="work_schedule" [profile]="profile"></app-modal-work-schedule>
    <app-modal-skills [technician]="technician" [profile]="profile"></app-modal-skills>
    <app-modal-vehicles (vehicleData)="updateVehicle($event)" [technician]="technician" [profile]="profile"></app-modal-vehicles>
    <app-modal-tools [technician]="technician" [profile]="profile"></app-modal-tools>
  `,
  styleUrls: []
})
export class TechnicianEditComponent implements OnInit {
  @ViewChild('modalWorkSchedule') modalWorkSchedule: any;
  @ViewChild('modalSkills') modalSkills: any;
  @ViewChild('modalVehicles') modalVehicles: any;
  @ViewChild('modalTools') modalTools: any;

  technicianId: number = 0;
  technician: any = {};
  roles: any = [];
  tasks: any = [];
  tools: any = [];
  skills: any = [];
  vehicle: any = {};
  work_schedule: any = {};
  profile: any = {};
  asignedSkills: any = [];
  asignedTools: any = [];

  passwordForm: FormGroup = new FormGroup({});
  showPassword: boolean = false;

  faTrashAlt = faTrashAlt;
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  avg: number = 0;
  pending: number = 0;
  completed: number = 0;

  constructor(
    private store: SettlementsService,
    private taskStore: TasksService,
    private route: Router,
    private activateRoute: ActivatedRoute,
    private fb: FormBuilder,
    private breadcrumbService: BreadcrumbService,
    private toastr: ToastrService,
    private dateService: DateService
  ) { 
    this.profile = JSON.parse(localStorage.getItem('profile')!);
    this.activateRoute.params.subscribe(params => {
      this.technicianId = params['id'];
      this.loadUser(this.technicianId);      
    });

    this.store.getRoles().subscribe({
      next: (data: any) => {
        this.roles = data;
      },
      error: (error: any) => {}
    });
  }
  
  ngOnInit(): void {
    this.breadcrumbService.set('@technicianEdit', 'Editar técnico');
    this.loadTasks(this.technicianId);
    this.loadAsignedVehicle(this.technicianId);
    this.loadSkills(this.technicianId);
    this.loadTools(this.technicianId);
    this.loadWorkSchedule(this.technicianId);
    this.loadTechSummary(this.technicianId);
    this.initForm();
  }

  get lf() {
    return this.passwordForm.controls;
  }

  initForm() {
    this.passwordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@#!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      password_confirmation: ['', [Validators.required, ValidateConfirmpass]]
    });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  updatePassword() {
    if (this.passwordForm.invalid) {
      this.toastr.error('No está completa la información.', 'Error');
      return;
    }

    this.store.updateUser(this.technicianId, this.passwordForm.value).subscribe({
      next: (data: any) => {
        this.toastr.success('Éxito', 'Contraseña actualizada correctamente.');
        this.passwordForm.reset();
      },
      error: (error: any) => {
        this.toastr.error('Error', 'Ocurrió un error al actualizar la contraseña');
      }
    });
  }

  private loadUser(id: number): void {
    this.store.getUser(id).subscribe({
      next: (data: any) => {
        this.technician = data;
      },
      error: (error: any) => {
        this.toastr.error('Error', 'Ocurrió un error al cargar la información del técnico');
      }
    });
  }

  loadTasks(id: number) {
    const d = new Date();
    let periodos = this.dateService.getStartAndEndDateOfMonth(parseInt(d.getFullYear().toString()), parseInt(`${d.getMonth()+1}`));
    this.taskStore.getTasks({
      page: 1,
      per_page: 100,
      assignee_id: id,
      due_date_since: periodos.start_date,
      due_date_to: periodos.end_date
    }).subscribe((tasks: any) => {
      console.log('tasks => ', tasks);
      this.completed = tasks.data.filter((task: any) => task.status == 3).length;
      this.pending = tasks.data.filter((task: any) => task.status == 1).length;
      this.tasks = tasks.data;
    });
  }

  loadAsignedVehicle(id: number) {
    this.taskStore.getVehicles({ page: 1, per_page: 100, assignee_id: id }).subscribe({
      next: (data: any) => {
        console.log('vehicle => ', data);
        this.vehicle = data.data[0];
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.vehicle = {};
      }
    });
  }

  loadSkills(id: number) {
    this.taskStore.getSkills({ user_id: this.technician.id }).subscribe({
      next: (data: any) => {
        this.skills = data;
      },
      error: (error: any) => {}
    });

    this.taskStore.getUserSkills(id).subscribe({
      next: (data: any) => {
        this.asignedSkills = data;
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.asignedSkills = [];
      }    
    });
  }

  loadTools(id: number) {
    this.taskStore.getTools({ assignee_id: id }).subscribe({
      next: (data: any) => {
        console.log('tools => ', data.data);
        this.tools = data.data;
      },
      error: (error: any) => {}
    });
  }

  loadWorkSchedule(id: number) {
    this.taskStore.getWorkHour(id).subscribe({
      next: (data: any) => {
        console.log('work_schedule => ', data);
        this.work_schedule = data;
      },
      error: (error: any) => {}
    });
  }

  loadTechSummary(id: number) {
    this.taskStore.getTechStatSummaries({
      assignee_id: id,
      period: 2
    }).subscribe((data: any) => {
      console.log('SUMMARY data ==> ', data);
      let local_avg = 0;
      data.data.map((item: any) => {
        local_avg += item.avg_grade;
      });
      this.avg = local_avg / data.data.length;
      // this.avg = data.avg_grade;
    });
  }

  editSchedule() {
    new bs.Modal(<HTMLInputElement>document.getElementById('modalWorkSchedule')).show();
  }

  addTools() {
    new bs.Modal(<HTMLInputElement>document.getElementById('modalTools')).show();
  }

  addVehicle() {
    new bs.Modal(<HTMLInputElement>document.getElementById('modalVehicle')).show();
  }

  addSkills() {
    new bs.Modal(<HTMLInputElement>document.getElementById('modalSkills')).show();
  }

  updateVehicle(vehicle: any) {
    console.log('vehicle => ', vehicle);
    
    this.taskStore.updateVehicle(vehicle.vehicle_id, vehicle).subscribe({
      next: (data: any) => {
        console.log('data => ', data);
        this.toastr.success('Éxito', 'Vehículo actualizado correctamente.');
        this.loadAsignedVehicle(this.technicianId);
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Error', 'Ocurrió un error al actualizar el vehículo');
      }
    });
  }

  removeVehicle(vehicle: any) {
    this.taskStore.removeUserVehicle(vehicle.id).subscribe({
      next: (data: any) => {
        console.log('data => ', data);
        this.toastr.success('Éxito', 'Vehículo removido correctamente.');
        this.loadAsignedVehicle(this.technicianId);
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Error', 'Ocurrió un error al remover el vehículo');
      }
    });
  }

  removeTool(tool: any) {
    this.taskStore.removeUserTool(tool.id).subscribe({
      next: (data: any) => {
        console.log('data => ', data);
        this.toastr.success('Éxito', 'Herramienta removida correctamente.');
        this.loadTools(this.technicianId);
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Error', 'Ocurrió un error al remover la herramienta');
      }
    });
  }

  saveUser(technician: any) {
    console.log('technician!!!! => ', technician);
    this.store.updateUser(this.technicianId, technician).subscribe({
      next: (data: any) => {
        console.log('data => ', data);
        this.toastr.success('Éxito', 'Usuario actualizado correctamente.');
        this.route.navigateByUrl('/technicians');
      },
      error: (error: any) => {
        console.log('error => ', error);
        this.toastr.error('Error', 'Ocurrió un error al actualizar el usuario');
      }
    });
  }
}
