import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { CatalogsService } from 'src/app/shared/services/catalogs.service';
import { IconsService } from 'src/app/shared/services/icons.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invoice-acceptance',
  template: `
    <div class="row">
      <div class="col-sm-12 offset-sm-0 col-lg-8 offset-lg-3 mt-5">
        <div class="row mb-4">
          <div class="col-md-5">
            <h1>Facturas en proceso de aceptación</h1>
          </div>        
          <div class="col-md-12" *ngIf="invoicesList.length>0">
            <div class="card rounded-4 shadow mb-3" *ngFor="let invoice of invoicesList | paginate: { itemsPerPage: payload.per_page, currentPage: p, totalItems: meta.total_entries }">
              <div class="card-body text-dark mb-3 pt-4 px-4 pb-0">
                <app-item-tenant-invoice [invoice]="invoice"></app-item-tenant-invoice>
              </div>
          </div>
          <div class="col-md-12" *ngIf="invoicesList?.length==0">
            <div class="list-group rounded-5 shadow">
              <div class="list-group-item text-center py-5" *ngIf="loading">
              <!-- <fa-icon [icon]="faCircleNotch" size="2x" [spin]="true" class="text-secondary"></fa-icon> -->
                <p class="text-muted">Cargando...</p>
              </div>
              <div class="list-group-item text-center py-5" *ngIf="!loading">
                <img src="/assets/images/empty-state.png" width="240px">
                <h5 class="text-muted">No se encontraron registros</h5>
              </div>
            </div>
          </div>
          <div class="col-md-12 text-end mt-4" *ngIf="invoicesList.length>0">
            <pagination-controls
              (pageChange)="p = $event"
              (pageChange)="pageChanged($event)"
              previousLabel="Prev."
              nextLabel="Sig."
              screenReaderPaginationLabel="Pagination"
              screenReaderPageLabel="page"                  
            ></pagination-controls>
          </div>
          <div class="col-md-12" *ngIf="invoicesList?.length==0">
            <div class="list-group-item text-center py-5" *ngIf="invoicesList.length==0">
              <img src="/assets/images/empty-state.png" width="240px">
              <h5 class="text-muted">No se encontraron registros</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class InvoiceAcceptanceComponent {
  ux:any = localStorage.getItem('user')
  user: any = this.ux != null ? JSON.parse(this.ux) : null;
  today: Date = new Date();
  invoicesList:any;
  meta: any = {};
  payload: any = {
    page: 1,
    per_page: 20,
  };
  tenantId: any;
  p: number = 1;
  receiptUrl: string = environment.RECIBOS_REST_WSPREFIX;
  apiUrl: string = environment.AWS_REST_WSPREFIX;

  loading: boolean = false;

  

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
    private store: SettlementsService,
    private activatedRoute: ActivatedRoute,
    public icons: IconsService,
    public catalog: CatalogsService,
  ) { }

  ngOnInit(): void {
    this.loadInvoices()
  }

  loadInvoices() {
    this.activatedRoute.params.subscribe((params: any) => {
      //this.payload = {...this.payload, id: 2};
      this.tenantId = params['id']
      // this.payload = {...this.payload, id: params['id']};
      this.store.getTenantInvoices({}).subscribe((il: any) => {
        console.log('List => aquí');
        this.invoicesList = il.data;
        this.meta = il.meta;
        this.loading = false;
        console.log('List => ', this.invoicesList);
      });
    });
  }

  pageChanged(event: any) {
    this.payload.page = event;
    this.loadInvoices();
  }
}
