import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettlementsService } from 'src/app/services/settlements.service';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
  selector: 'app-concept-edit',
  template: `
    <div class="row">
    <div class="col-sm-11 offset-sm-1 col-md-11 offset-md-1 col-lg-8 offset-lg-4 col-xl-8 offset-xl-3 mt-5">
    
      <div class="row mb-4 mt-4">
        <div class="col-md-8">
          <h1>Concepto</h1>
          <p>Configura los conceptos para los recibos de pago.</p>
        </div>
        <xng-breadcrumb ></xng-breadcrumb>
      </div>
      <div class="row">
        <div class="col-md-7">
          <div class="card border-0 rounded-4 shadow">
            <div class="card-body p-4">
              <div class="row">
                <div class="col-md-12">
                  <h4>Editar concepto</h4>
                  <p>Configura el concepto con los siguientes campos.</p>
                  <app-form-payment-concept (conceptData)="updateConcept($event)" [concept]="concept" [loading]="loading"></app-form-payment-concept>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  `,
  styleUrls: []
})
export class ConceptEditComponent {
  concept: any = null;
  loading: boolean = false;

  constructor(
    private store: SettlementsService,
      private toastr: ToastrService,
      private router: Router,
      private activateRoute: ActivatedRoute,
      private breadcrumbService: BreadcrumbService,
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.set('@concepto', 'Conceptos');
    this.loading = true;
    this.activateRoute.params.subscribe(params => {
      this.store.getPaymentConcept(params['id']).subscribe({
        next: (res: any) => {
          this.loading = false;
          this.concept = res;
          this.breadcrumbService.set('@editarConcepto', this.concept.name);
        },
        error: (err: any) => {
          console.log(err);
          this.loading = false;
          this.toastr.error('Ocurrió un error al obtener el concepto.', 'Error');
        }
      });
    });
  }

  updateConcept(event: any) {
    this.store.updatePaymentConcept(this.concept.id, event).subscribe({
      next: (res: any) => {
        this.toastr.success('El concepto se actualizó correctamente.', 'Actualizado');
        this.router.navigate(['/pagos/conceptos']);
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error('Ocurrió un error al actualizar el concepto.', 'Error');
      }
    });
  }
}
