import { Component, Input, OnInit } from '@angular/core';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { SettlementsService } from 'src/app/services/settlements.service';
import { CatalogsService } from 'src/app/shared/services/catalogs.service';
import { IconsService } from 'src/app/shared/services/icons.service';

@Component({
  selector: 'app-item-invoice',
  template: `
    <div class="row">
      <div class="col-md-8 ps-4 pe-0 py-3">
        <h4 class="mb-0">Id #:  {{invoice.folio}}</h4>
        <p class="mb-0">Folio fiscal: {{invoice.uuid}}</p>
        <p class="mb-0">{{invoice.settlement_name}}, {{invoice.building_name}}, {{invoice.unit_name}}</p>
      </div>
      <div class="col-md-3 text-end ps-0 pe-3 py-3">
        <p class="mb-0" class="mb-0 text-sm text-muted">Facturado: {{invoice.created_at | date: 'dd/MM/yyyy - HH:mm'}}</p>
        <div class="badge badge-pill bg-secondary rounded-5 mb-0 px-3 py-1">{{ invoiceType.name }}</div>
      </div>
      <div class="col-md-1 text-center p-0">
          <!-- div class="badge rounded-pill me-3 px-3 py-2 border border-3" [ngClass]="invoice.status == 'active' ? 'border-success text-success' : 'border-warning text-warning'">
            <p class="mb-0 text-capitalize">{{invoice.status}}</p>
          </!-->
          <div class="hover-info border-start pt-3 pe-4" style="height: 100%; " routerLink="/invoices/{{invoice.id}}">
            <fa-icon [icon]="icons.faEye" size="1x" class="center-icon"></fa-icon>
          </div>
      </div>
    </div>
  `,
  styleUrls: []
})
export class ItemInvoiceComponent implements OnInit {
  @Input() invoice: any;

  constructor(
    private store: SettlementsService,
    public catalogs: CatalogsService,
    public icons: IconsService
  ) { }

  ngOnInit(): void {
    // this.store.getBill(this.invoice.bill_id).subscribe((bill: any) => {
      // this.bill = bill;
    // });
  }

  get invoiceType() {
    return this.catalogs.tipoFactura.find((x: any) => this.invoice.tipo == x.id);
  }
}
